import React from "react";
import styles from "./ValidationItem.module.css";

import ProductInfo from "../components/ProductInfo";
import ImageSetInfo from "../components/ImageSetInfo";

import NoTasks from "../components/NoTasks";
import Button from "../../../components/generic/Button";
import RateContentForm from "../components/RateContentForm";
import FinalRating from "../components/FinalRating";

const ValidationItem = ({ data, isCompleted, goBack }) => {
  if (!data.product)
    return (
      <NoTasks
        title="No available tasks"
        subtitle=" Please refresh the page obsesively until a task appears or come back
  later!"
      />
    );

  return (
    <div className={styles.container}>
      {isCompleted && (
        <div className={styles.goBack}>
          <Button
            icon={require("../../../static/svg/arrow-left-orange.svg")}
            handleClick={() => goBack({})}
            yellowText
            text="All Completed Tasks"
          />
        </div>
      )}

      <section className={styles.columns}>
        <div className={styles.right}>
          <ProductInfo data={data}>
            {isCompleted ? (
              <>
                <p className={styles.title}>Content rating</p>
                <div className={styles.finalRatingsContainer}>
                  <div className={styles.myRating}>
                    <p className={styles.text}>Your rating</p>
                    <FinalRating score={data.rating} />
                  </div>
                  <div>
                    <p className={styles.text}>Final rating</p>
                    <FinalRating score={data.imageSet.rating} />
                  </div>
                </div>
                <p className={styles.text}>Your comment</p>
                <p className={styles.submitedComment}>{data.comment}</p>
              </>
            ) : (
              <RateContentForm data={data} />
            )}
          </ProductInfo>
        </div>
        <div className={styles.left}>
          <ImageSetInfo imageSet={data.imageSet} />
        </div>
      </section>
    </div>
  );
};

export default ValidationItem;
