import React from "react";
import styles from "./VerifyAccount.module.css";
import emailIcon from "../static/svg/emailSent.svg";
import axios from "axios";
import Input from "../components/generic/Input";
import { useFormik } from "formik";

const VerifyAccount = () => {
  const formik = useFormik({
    initialValues: {
      user: JSON.parse(localStorage.getItem("user")),
      email: ""
    },

    validate: values => {
      const errors = {};
      if (!values.email) {
        errors.email = "This field is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      return errors;
    },

    onSubmit: (values, { setErrors, setSubmitting }) => {
      const { email } = values;
      const payload = { ...values.user, email };

      axios
        .post(`/backend/resend-email`, payload)
        .then(() => {
          localStorage.setItem(
            "user",
            JSON.stringify({ ...values.user, email: values.email })
          );
          document.location = "/verify-account";
        })
        .catch(err => {
          console.log(err);
        });
    }
  });

  const { values, isSubmitting, handleChange, handleSubmit } = formik;

  return (
    <div className={`box-wide ${styles.container}`}>
      <div className="column-grid">
        <div className="box-half">
          <h1 className={styles.title}>Verify your email</h1>
          <p className={styles.subtitle}>
            We have sent a confirmation email to{" "}
            <span className={styles.highlight}>{values.user.email}</span>. To
            complete the registration please click the link in the mail.
          </p>
          <h3 className={styles.label}>Didn’t receive the mail?</h3>

          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.inputsContainer}>
              <div className={styles.inputWrapper}>
                <Input
                  name="email"
                  type="email"
                  value={values.email}
                  handleChange={handleChange}
                  placeholder={values.user.email}
                />
              </div>
              <span className={styles.space} />
              <Input
                isDisabled={isSubmitting}
                isSubmitBtn={true}
                type="submit"
                value="Resend mail"
              />
            </div>
          </form>
        </div>
        <div className="box-half">
          <div className={styles.iconContainer}>
            <div className={styles.icon}>
              <img className="responsive-image" src={emailIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyAccount;
