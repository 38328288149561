import {
  SET_VALIDATION_ITEM_SUCCESS,
  SET_RATING,
  RATING_SUBMITTED_SUCCESS,
} from "../actions/actionTypes";

export default (
  state = {
    rating: 0,
    isSubmitted: false,
    validationItem: null,
    validatedTaskList: null,
  },
  { payload, type }
) => {
  switch (type) {
    case SET_VALIDATION_ITEM_SUCCESS: {
      return {
        ...state,
        validationItem: payload,
        isSubmitted: false,
        rating: 0,
      };
    }

    case SET_RATING: {
      return {
        ...state,
        rating: payload,
      };
    }

    case RATING_SUBMITTED_SUCCESS: {
      return {
        ...state,
        isSubmitted: true,
        validationItem: { ...state.validationItem, ...payload },
      };
    }

    case "GET_VALIDATED_TASK_LIST": {
      return { ...state, validatedTaskList: payload };
    }

    default:
      return state;
  }
};
