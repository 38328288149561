import React from "react";
import styles from "./ImageSetState.module.css";

import classNames from "classnames/bind";

let cx = classNames.bind(styles);

const ImageSetState = props => {
  let iconSrc = cx({
    [props.icon]: true,
  });
  return (
    <div className={styles.container}>
      <div className={`${iconSrc} ${styles.icon}`} />
      <div className={styles.stateContainer}>
        <p className={styles.state}>{props.state}</p>
      </div>
    </div>
  );
};

export default ImageSetState;
