import React from "react";
import styles from "./UploadLoader.module.css";
import closeIcon from "../static/svg/close.svg";
import spinnerGif from "../static/gifs/spinner.gif";

const UploadLoader = props => {
  const { hideModal, cancelUploadRequest } = props;
  const handleCloseModal = () => {
    hideModal();
    cancelUploadRequest();
  };

  return (
    <React.Fragment>
      <div onClick={handleCloseModal} className={styles.background}></div>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div onClick={handleCloseModal} className={styles.closeIcon}>
            <img src={closeIcon} alt="closeButton" />
          </div>
          <h3 className={styles.title}>Your files are uploading! </h3>
          <p className={styles.subtitle}>
            Please do not close this window until the upload is complete.
          </p>
          <div className={styles.gifContainer}>
            <img src={spinnerGif} alt="loading" />
          </div>
          <button onClick={handleCloseModal} className={styles.cancelBtn}>
            Cancel
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadLoader;
