import React from "react";
import styles from "./Image.module.css";
import { connect } from "react-redux";
import {
  selectImageSetImage,
  updateImageSet,
} from "../../../redux/actions/productsActions";

import checkedIconMedium from "../../../static/svg/checkedCircleMedium.svg";
import checkedIconSmall from "../../../static/svg/checkedCircleSmall.svg";

const Image = props => {
  const {
    data,
    selectedImages,
    imageSet,
    selectImageSetImage,
    updateImageSet,
    isDraft,
  } = props;

  const handleToggleSelectImage = () => {
    selectImageSetImage(data.id);
  };

  const handleMakeMainPhoto = () => {
    updateImageSet({
      key: "coverImageId",
      value: data.id,
      notificationMessage: "Main photo successfully updated",
    });
  };

  return (
    <div className={styles.container}>
      <img className={styles.image} src={data.url} alt="" />
      {isDraft && (
        <>
          <div
            onClick={handleToggleSelectImage}
            className={styles.uncheckedCircle}
          ></div>
          <img
            onClick={handleToggleSelectImage}
            className={
              selectedImages[data.id] === true
                ? styles.checkedCircle
                : styles.checkedCircleHidden
            }
            src={checkedIconMedium}
            alt={data.id}
          />
        </>
      )}

      {imageSet.coverImageId === data.id ? (
        <div className={styles.coverImageBtn}>
          <img
            className={styles.checkedCoverImageIcon}
            src={checkedIconSmall}
            alt=""
          />
          <p>Main photo</p>
        </div>
      ) : (
        <>
          {isDraft && (
            <div className={`${styles.coverImageBtn} ${styles.makeMainPhoto}`}>
              <div
                onClick={handleMakeMainPhoto}
                className={styles.uncheckedCoverImageIcon}
              ></div>
              <p>Make main photo</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect(null, {
  selectImageSetImage,
  updateImageSet,
})(Image);
