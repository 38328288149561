import React from "react";
import styles from "./AccountInfo.module.css";
import Input from "../generic/Input";
import Error from "../generic/Error";
const AccountInfo = props => {
  const { handleChange, errors, touched } = props;
  const {
    email,
    password,
    rePassword,
    businessName,
    firstName,
    lastName,
    phone,
    website
  } = props.userInput;

  const handleInputChange = (e, objectName, componentName) => {
    handleChange(e, objectName, componentName);
  };

  return (
    <div className={`box-full`}>
      <div className="column-grid">
        <div className={`box-third ${styles.box}`}>
          <h3 className={styles.title}>Login Info</h3>
          <p className={styles.subtitle}>(All fields are mandatory)</p>
          <div>
            <div className={styles.inputWrapper}>
              <Input
                name="email"
                type="email"
                value={email}
                placeholder="Your email"
                handleChange={handleChange}
              />
              {errors.email && touched.email && (
                <Error message={errors.email} />
              )}
            </div>
            <div className={styles.inputWrapper}>
              <Input
                name="password"
                type="password"
                value={password}
                placeholder="Choose a password"
                handleChange={handleInputChange}
              />
              {errors.password && touched.password && (
                <Error message={errors.password} />
              )}
            </div>
            <div className={styles.inputWrapper}>
              <Input
                name="rePassword"
                type="password"
                value={rePassword}
                placeholder="Re-type your password"
                handleChange={handleInputChange}
              />
              {errors.rePassword && touched.rePassword && (
                <Error message={errors.rePassword} />
              )}
              {errors.equalPasswords && touched.rePassword && (
                <Error message={errors.equalPasswords} />
              )}
            </div>
          </div>
        </div>

        <div className={`box-third ${styles.box}`}>
          <h3 className={styles.title}>Name</h3>
          <p className={styles.subtitle}>(All fields are mandatory)</p>
          <div>
            <div className={styles.inputWrapper}>
              <Input
                name="businessName"
                type="text"
                value={businessName}
                placeholder="Business name"
                handleChange={handleInputChange}
              />
              {errors.businessName && touched.businessName && (
                <Error message={errors.businessName} />
              )}
            </div>
            <div className={styles.inputWrapper}>
              <Input
                name="firstName"
                type="text"
                value={firstName}
                placeholder="First name"
                handleChange={handleInputChange}
              />
              {errors.firstName && touched.firstName && (
                <Error message={errors.firstName} />
              )}
            </div>
            <div className={styles.inputWrapper}>
              <Input
                name="lastName"
                type="text"
                value={lastName}
                placeholder="Last name"
                handleChange={handleInputChange}
              />
              {errors.lastName && touched.lastName && (
                <Error message={errors.lastName} />
              )}
            </div>
          </div>
        </div>

        <div className={`box-third ${styles.box}`}>
          <h3 className={styles.title}>Additional contact info</h3>
          <p className={styles.subtitle}>(Optional)</p>
          <div>
            <div className={styles.inputWrapper}>
              <Input
                name="phone"
                type="text"
                value={phone}
                placeholder="Phone number"
                handleChange={handleInputChange}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Input
                name="website"
                type="text"
                value={website}
                placeholder="Your website"
                handleChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
