import React from "react";
import Page from "../../layouts/Page";
import styles from "./index.module.css";
import userCheck from "../../static/svg/userCheck.svg";

const Dashboard = () => {
  return (
    <Page>
      <div className={styles.background}>
        <div className={styles.container}>
          <div className={styles.iconContainer}>
            <img src={userCheck} alt="" />
          </div>
          <h1 className={styles.title}>Account activation pending</h1>
          <p className={styles.subtitle}>
            Our team is verifying your account. We will send you and email once
            the verification is completed and you can start using your account.
          </p>
        </div>
      </div>
    </Page>
  );
};

export default Dashboard;
