import React from "react";
import { useFormik } from "formik";
import Error from "../../../components/generic/Error";
import styles from "./AccountInfo.module.css";
import { connect } from "react-redux";
import {
  updateUserProfile,
  toggleChangePasswordForm,
} from "../../../redux/actions/userProfileActions";
import InnerPage from "../layout/InnerPage";
import ChangePasswordForm from "../../../components/ChangePasswordForm";
import Save from "../../../components/Save";

const AccountInfo = props => {
  const {
    loading,
    user,
    updateUserProfile,
    isChangePasswordFormVisible,
    toggleChangePasswordForm,
  } = props;

  const format = user => {
    return {
      email: user.email,
      businessName: user.businessName,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      website: user.website,
    };
  };

  const formik = useFormik({
    initialValues: format(user),
    validate: values => {
      const errors = {};
      if (!values.email) {
        errors.email = "This field is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      if (!values.businessName) {
        errors.businessName = "This field is required";
      }
      if (!values.firstName) {
        errors.firstName = "This field is required";
      }
      if (!values.lastName) {
        errors.lastName = "This field is required";
      }
      return errors;
    },

    onSubmit: values => {
      updateUserProfile(values);
    },
  });

  const { values, handleChange, handleSubmit, errors, touched } = formik;

  return (
    <InnerPage>
      {isChangePasswordFormVisible ? (
        <div className={styles.changePasswordform}>
          <ChangePasswordForm
            toggleChangePasswordForm={toggleChangePasswordForm}
          />
        </div>
      ) : (
        <form
          className={`column-grid ${styles.container}`}
          onSubmit={handleSubmit}
        >
          <div className="box-third">
            <p className={styles.subtitle}>Login Info</p>
            <div className={styles.inputs}>
              <div className={styles.inputContainer}>
                <span className={styles.placeholder}>Your email</span>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && touched.email && (
                  <Error message={errors.email} />
                )}
                <button
                  onClick={() => toggleChangePasswordForm()}
                  className={styles.changePasswordBtn}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
          <div className="box-third">
            <p className={styles.subtitle}>Name</p>

            <div className={styles.inputs}>
              <div className={styles.inputContainer}>
                <span className={styles.placeholder}>Business name</span>
                <input
                  name="businessName"
                  type="text"
                  value={values.businessName}
                  onChange={handleChange}
                  placeholder=""
                />
                {errors.businessName && touched.businessName && (
                  <Error message={errors.businessName} />
                )}
              </div>
              <div className={styles.inputContainer}>
                <span className={styles.placeholder}>First name</span>
                <input
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                  placeholder=""
                />
                {errors.firstName && touched.firstName && (
                  <Error message={errors.firstName} />
                )}
              </div>
              <div className={styles.inputContainer}>
                <span className={styles.placeholder}>Last name</span>
                <input
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  placeholder=""
                />
                {errors.lastName && touched.lastName && (
                  <Error message={errors.lastName} />
                )}
              </div>
            </div>
          </div>
          <div className="box-third">
            <p className={styles.subtitle}>Additional contact info</p>

            <div className={styles.inputs}>
              <div className={styles.inputContainer}>
                <span className={styles.placeholder}>Phone number</span>
                <input
                  name="phone"
                  type="text"
                  value={values.phone}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
              <div className={styles.inputContainer}>
                <span className={styles.placeholder}>Website</span>
                <input
                  name="website"
                  type="text"
                  value={values.website}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className={styles.save}>
            <Save
              text={"Save Changes"}
              loading={loading}
              active={
                !(JSON.stringify(format(user)) === JSON.stringify(values))
              }
            />
          </div>
        </form>
      )}
    </InnerPage>
  );
};

const mapStateToProps = ({
  userProfile: { user, isChangePasswordFormVisible },
}) => {
  return { user, isChangePasswordFormVisible };
};

export default connect(mapStateToProps, {
  updateUserProfile,
  toggleChangePasswordForm,
})(AccountInfo);
