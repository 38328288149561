import React from "react";
import styles from "./Spinner.module.css";
import spinner from "../../static/svg/spinner.svg";

const Spinner = ({ size }) => {
  return (
    <div className={styles.container}>
      <img className={styles[size]} src={spinner} alt="loading" />
    </div>
  );
};

export default Spinner;
