import React from "react";
import styles from "./AssetsImages.module.css";

import Image from "../pages/assetsManagement/components/Image";
import Loader from "../components/generic/Loader";
import InfiniteScroll from "react-infinite-scroller";
import { fetchImages } from "../redux/actions/assetsManagementActions";
import { connect } from "react-redux";
import Spinner from "./generic/Spinner";

const AssetsImages = ({
  fetchImages,
  imagesGroupedByDate,
  selectedImages,
  total,
  count,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.imagesContainer}>
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchImages}
          hasMore={total > count}
          loader={<Spinner size={"small"} key={0}></Spinner>}
          useWindow={false}
        >
          {Object.keys(imagesGroupedByDate).map((date, groupIndex) => {
            return (
              <div key={groupIndex} className={styles.imagesGroup}>
                <p className={styles.date}>{date}</p>
                <div className={styles.images}>
                  {imagesGroupedByDate[date].map((image) => {
                    return (
                      <div key={image.id} className={styles.image}>
                        <Image
                          key={image.id}
                          data={image}
                          selectedImages={selectedImages}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    total: state.assetsManagement.total,
    count: state.assetsManagement.count,
  };
};

export default connect(mapStateToProps, { fetchImages })(AssetsImages);
