import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import UserRoutes from "./UserRoutes";

import { isLoggedIn, isVerified } from "../auth";

import NavBar from "../components/Navbar";
import NotFoundPage from "../components/generic/NotFoundPage";

const Routes = () => {
  return (
    <Router>
      <NavBar />

      <Switch>
        {/*<Route path="not-found" component={NotFoundPage} />*/}
        <>
          <PublicRoutes />
          {isLoggedIn() && isVerified() && <UserRoutes />}
        </>
      </Switch>
    </Router>
  );
};

export default Routes;
