import React from "react";
import styles from "./Modal.module.css";
import close from "../static/svg/close.svg";

const Modal = ({ closeIcon, onClose, open, children }) => {
  if (!open) return "";

  return (
    <div className={styles.container}>
      <div onClick={onClose} className={styles.overlay} />
      {closeIcon && (
        <div onClick={onClose} className={styles.closeIcon}>
          <img src={close} alt="close" />
        </div>
      )}
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default Modal;
