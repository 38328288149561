import React, { useEffect } from "react";
import styles from "./Notification.module.css";

const TYPE = {
  SUCCESS: "success",
  WARNING: "warning",
};

const TIMEOUT = 5000;

const Notification = ({ data, onRemove }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onRemove(data.id);
    }, TIMEOUT);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const type = TYPE[data.type];
  const icon = type === "success" ? "validated" : "warning";

  return (
    <div className={`${styles.notification} ${styles[type]}`}>
      <div className={styles.message}>
        <img
          className={styles.icon}
          src={require(`../../static/svg/${icon}.svg`)}
        />
        {data.message}
      </div>
      <span className={styles.dismiss} onClick={() => onRemove(data.id)}>
        <img src={require(`../../static/svg/close.svg`)} />
      </span>
    </div>
  );
};

export default Notification;
