import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styles from "./LoginForm.module.css";
import { storeLoginInfo } from "../auth";
import Input from "./generic/Input";
import Error from "./generic/Error";
import { useFormik } from "formik";

const LoginForm = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },

    validate: values => {
      const errors = {};
      if (!values.email) {
        errors.email = "This field is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      if (!values.password) {
        errors.password = "This field is required";
      }
      return errors;
    },

    onSubmit: (values, { setErrors, setSubmitting }) => {
      axios
        .post(`/backend/auth/login`, values)
        .then(res => {
          if (res.data.name === "Error") {
            setErrors({ invalidLogin: "Incorrect email or password" });
            setSubmitting(false);
          } else {
            redirect(res.data);
          }
        })
        .catch(err => {
          setErrors({ invalidLogin: "Something went wrong" });
          setSubmitting(false);
          console.log(err);
        });
    }
  });

  const {
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    errors,
    touched
  } = formik;

  const redirect = loginInfo => {
    storeLoginInfo(loginInfo);
    if (loginInfo && loginInfo.user && loginInfo.user.status === "unverified") {
      document.location = "/verify-account";
    } else {
      document.location = "/home";
    }
  };

  return (
    <div className={`box-wide ${styles.container}`}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <h1 className={styles.title}>Welcome back!</h1>
        <p className={styles.subtitle}>Please login to your account bellow!</p>
        <div className={styles.inputsContainer}>
          <div className={styles.inputWrapper}>
            <Input
              name="email"
              type="email"
              value={values.email}
              handleChange={handleChange}
              placeholder="Your email"
            />
            {errors.email && touched.email && <Error message={errors.email} />}
          </div>
          <div className={styles.inputWrapper}>
            <Input
              name="password"
              type="password"
              value={values.password}
              handleChange={handleChange}
              placeholder="Your password"
            />
            {errors.password && touched.password && (
              <Error message={errors.password} />
            )}
            {errors.invalidLogin && <Error message={errors.invalidLogin} />}
          </div>
          <Link to="/reset-password/request" className={styles.forgotPassLink}>
            Forgot your password?
          </Link>

          <Input
            isDisabled={isSubmitting}
            isSubmitBtn={true}
            type="submit"
            value="Login"
          />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
