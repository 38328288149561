export const TAB_CLICKED = "TAB_CLICKED";
export const IMAGES_FETCHED = "IMAGES_FETCHED";
export const PRODUCTS_FETCHED = "PRODUCTS_FETCHED";
export const IMAGES_SEARCHED = "IMAGES_SEARCHED";
export const IMAGE_CLICKED = "IMAGE_CLICKED";
export const TOGGLE_RIGHT_PANEL_CLICKED = "TOGGLE_RIGHT_PANEL_CLICKED";
export const DELETED_IMAGES_SUCCESS = "DELETED_IMAGES_SUCCESS";
export const UPLOADED_IMAGES_SUCCESS = "UPLOADED_IMAGES_SUCCESS";
export const UPLOADED_ZIP_SUCCESS = "UPLOADED_ZIP_SUCCESS";
export const TOGGLE_PRODUCTS = "TOGGLE_PRODUCTS";
export const TOGGLE_PRODUCT_FORM = "TOGGLE_PRODUCT_FORM";
export const NEW_PRODUCT_ADDED = "NEW_PRODUCT_ADDED";
export const TOGGLE_EXPANDED_PRODUCT = "TOGGLE_EXPANDED_PRODUCT";
export const IMAGE_SET_FORM_DISPLAYED = "IMAGE_SET_FORM_DISPLAYED";
export const IMAGE_SET_FORM_REMOVED = "IMAGE_SET_FORM_REMOVED";
export const NEW_IMAGE_SET_ADDED = "NEW_IMAGE_SET_ADDED";
export const EDIT_PRODUCT_FORM_DISPLAYED = "EDIT_PRODUCT_DISPLAYED";
export const EDIT_PRODUCT_FORM_REMOVED = "EDIT_PRODUCT_FORM_REMOVED";
export const PRODUCT_UPDATED = "PRODUCT_UPDATED";
export const INSTAGRAM_EXPORT_HELP_REMOVED = "INSTAGRAM_EXPORT_HELP_REMOVED";
export const IMAGE_SET_CHECKED = "IMAGE_SET_CHECKED";
export const NEW_SNIPPET_ADDED = "NEW_SNIPPET_ADDED";
export const SNIPPET_DELETED = "SNIPPET_DELETED";
export const SNIPPET_SELECTED = "SNIPPET_SELECTED";
export const SNIPPET_DESELECTED = "SNIPPET_DESELECTED";
export const SNIPPET_LIST_FETCHED = "SNIPPET_LIST_FETCHED";
export const ACTIVE_PAGE_SET = "ACTIVE_PAGE_SET";
export const PRODUCT_SELECTED = "PRODUCT_SELECTED";
export const IMAGE_SET_SELECTED = "IMAGE_SET_SELECTED";
export const IMAGE_SETS_DELETED = "IMAGE_SETS_DELETED";
export const IMAGE_SET_UPDATED = "IMAGE_SET_UPDATED";
export const IMAGES_REMOVED_FROM_IMAGE_SET = "IMAGES_REMOVED_FROM_IMAGE_SET";
export const IMAGE_SET_IMAGE_SELECTED = "IMAGE_SET_IMAGE_SELECTED";
export const RETURNED_TO_ALL_SETS_VIEW = "RETURNED_TO_ALL_SETS_VIEW";
export const IMAGE_SET_DELETED = "IMAGE_SET_DELETED";
export const UPLOAD_FROM_ASSETS_MODAL_DISPLAYED =
  "UPLOAD_FROM_ASSETS_MODAL_DISPLAYED";
export const UPLOAD_FROM_ASSETS_MODAL_REMOVED =
  "UPLOAD_FROM_ASSETS_MODAL_REMOVED";
export const TOGGLE_OVERLAY_VISIBILITY = "TOGGLE_OVERLAY_VISIBILITY";
export const TOGGLE_ADD_PRODUCT = "TOGGLE_ADD_PRODUCT";
export const SET_VALIDATION_ITEM_SUCCESS = "SET_VALIDATION_ITEM_SUCCESS";
export const SET_RATING = "SET_RATING";
export const RATING_SUBMITTED_SUCCESS = "RATING_SUBMITTED_SUCCESS";
export const GET_VALIDATED_TASK_LIST = "GET_VALIDATED_TASK_LIST";
export const TRIGGER_NOTIFICATION = "TRIGGER_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const PRODUCT_DELETED = "PRODUCT_DELETED";
export const TOGGLE_IS_MULTISELECT = "TOGGLE_IS_MULTISELECT";
export const MULTI_SELECT_PRODUCT = "MULTI_SELECT_PRODUCT";
