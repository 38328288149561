import React, {useState} from "react";
import styles from "./PaymentSettings.module.css";
import InnerPage from "../layout/InnerPage";
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios";
import {getAuthHeader } from "../../../auth";
import {isConsumer, isValidator, isCreator} from "../../../auth";
import connectButton from "../../../static/images/connect.png";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const PaymentSettings = props => {
  const [subscriptionPlan, setSubscriptionPlan] = useState("price_1HCJlME0hZEZAjFFK5Uxk9Xi");

  const handleChange = (event) => {
    setSubscriptionPlan(event.target.value);
  }

  const paySubscription = (e) => {
    e.preventDefault();
    axios
      .post(process.env.REACT_APP_API_URL + `/subscriptions/checkout`, {subscriptionPlan}, getAuthHeader())
      .then(async res => {
        const sessionId = res.data.id;
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  let user = JSON.parse(localStorage.getItem("user"));

  const connectWithStripe = (e) => {
    document.location = `https://connect.stripe.com/express/oauth/authorize?client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&suggested_capabilities[]=transfers&stripe_user[email]=andrei.dan.laza@gmail.com&redirect_uri=${process.env.REACT_APP_URL}/profile`
  }

  return (
    <>
      <InnerPage className={styles.paymentInnerPage}>
        {isConsumer() && <div className={`${styles.paymentContainer} column-grid`}>
          <div className={"box-half"}>
          <p className={styles.title}>Subscriptions</p>

          <form className={styles.subscriptionForm} onSubmit={paySubscription}>
            <div className={styles.formBox}>
              <input className={styles.subscriptionPlans} type={"radio"} name={"subscription-plan"}
                     checked={subscriptionPlan === "price_1HCJlME0hZEZAjFFK5Uxk9Xi"}
                     value={"price_1HCJlME0hZEZAjFFK5Uxk9Xi"} id={"standard-plan"} onChange={handleChange}/>
              <label htmlFor={"standard-plan"}>Standard plan - $99.99</label>
            </div>

            <div className={styles.formBox}>
              <input className={styles.subscriptionPlans} type={"radio"} name={"subscription-plan"}
                     value={"price_1HCJllE0hZEZAjFFi1blu1RY"} id={"premium-plan"} onChange={handleChange}/>
              <label htmlFor={"premium-plan"}>Premium plan - $249.99</label>
            </div>

            <input type={"submit"} value={"Pay now"} className={styles.payNow}/>
          </form>
          </div>

          {isCreator()&& <div className={"box-half"}>
            <p className={styles.title}>Connect with Bleexy on Stripe</p>
            {user.stripeCode ? <p className={styles.connected}>You are connected with Stripe! If you wish to disconnect, please do it from your Stripe dashboard: <a href={"https://dashboard.stripe.com"}>https://dashboard.stripe.com</a></p> : <button className={styles.connectButton} onClick={connectWithStripe}>
              <img src={connectButton} className={styles.connectImage}/>
            </button>}
          </div>}
        </div>}


      </InnerPage>
    </>
  );
};

export default PaymentSettings;