import React from "react";
import styles from "./Page.module.css";
import Notifications from "../components/Notifications";

const Page = props => {
  return (
    <>
      <Notifications />
      <div className={styles.container}>{props.children}</div>
    </>
  );
};

export default Page;
