import axios from "axios";
import {
  TAB_CLICKED,
  IMAGES_FETCHED,
  IMAGE_CLICKED,
  IMAGES_SEARCHED,
  TOGGLE_RIGHT_PANEL_CLICKED,
  DELETED_IMAGES_SUCCESS,
  UPLOADED_IMAGES_SUCCESS,
  PRODUCTS_FETCHED,
  TRIGGER_NOTIFICATION,
} from "./actionTypes";
import { getAuthHeader } from "../../auth";
import { updateImageSet } from "./productsActions";
import { startLoading, stopLoading } from "./loadingActions";
import * as R from "ramda";

const getHeaders = () => {
  const authHeader = getAuthHeader();
  const headers = {
    "Content-Type": "multipart/form-data",
    ...authHeader.headers,
  };

  return headers;
};

export const assetsManagementTabs = {
  images: "IMAGES_TAB",
  prodDesc: "PROD_DESC_TAB",
};

export const tabClick = tabName => dispatch => {
  dispatch({
    type: TAB_CLICKED,
    payload: tabName,
  });
};

export const fetchImages = page => async (dispatch, getState, config) => {
  const includeTotal = page <= 1 ? "&include_total=true" : "";

  const response = await axios.get(
    `${config.apiUrl}/images?page=${page}${includeTotal}`,
    getAuthHeader()
  );
  const data = R.pipe(
    R.map(image =>
      R.assoc("url", `/bleexypedia-storage/${image.file_key}`, image)
    )
  )(Object.values(response.data.results));

  dispatch({
    type: IMAGES_FETCHED,
    payload: { ...response.data, results: data },
  });
};

export const fetchProducts = () => (dispatch, getState, config) => {
  axios.get(`${config.apiUrl}/products`, getAuthHeader()).then(res => {
    dispatch({
      type: PRODUCTS_FETCHED,
      payload: res.data,
    });
  });
};

export const searchImages = payload => dispatch => {
  dispatch({
    type: IMAGES_SEARCHED,
    payload,
  });
};

export const imageClick = id => dispatch => {
  dispatch({
    type: IMAGE_CLICKED,
    payload: id,
  });
};

export const toggleRightPanelClick = () => dispatch => {
  dispatch({
    type: TOGGLE_RIGHT_PANEL_CLICKED,
  });
};

export const deleteImagesClick = selectedImages => dispatch => {
  console.log(selectedImages);
  dispatch(startLoading("delete_images"));
  axios
    .delete("/backend/images", {
      ...getAuthHeader(),
      data: selectedImages,
    })
    .then(res => {
      dispatch({
        type: DELETED_IMAGES_SUCCESS,
        payload: res.data,
      });
      dispatch(stopLoading("delete_images"));
      dispatch({
        type: TRIGGER_NOTIFICATION,
        payload: {
          message: `${selectedImages.length} image${
            selectedImages.length > 1 ? "s" : ""
          } successfully deleted`,
          type: "SUCCESS",
        },
      });
    });
};

export const cancelUploadRequest = () => dispatch => {
  dispatch({ type: "UPLOAD_CANCELED" });
};

export const uploadFilesClick = (files, shouldUpdate) => (
  dispatch,
  getState,
  config
) => {
  dispatch({
    type: "MODAL_DISPLAYED",
    payload: "uploadLoader",
  });

  dispatch({
    type: "CANCEL_REQUEST_TOKEN_SET",
    payload: axios.CancelToken.source(),
  });

  const source = getState().assetsManagement.cancelReqToken;

  const headers = getHeaders();

  const data = new FormData();

  Array.from(files).forEach(file => {
    data.append("files", file);
  });

  data.append("importFrom", "computer");

  axios
    .post("/backend/uploadFile", data, {
      headers,
      cancelToken: source.token,
    })
    .then(res => {
      dispatch({
        type: UPLOADED_IMAGES_SUCCESS,
        payload: res.data,
      });

      return res.data;
    })
    .then(data => {
      if (shouldUpdate) {
        const imageIds = data.map(i => {
          return parseInt(i.id);
        });

        dispatch(updateImageSet({ key: "imageIds", value: imageIds }));
      }
    })
    .catch(error => {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.log(error.message);
      }
    });
};

export const uploadZipFilesClick = files => (dispatch, getState) => {
  dispatch({
    type: "MODAL_DISPLAYED",
    payload: "uploadLoader",
  });

  dispatch({
    type: "CANCEL_REQUEST_TOKEN_SET",
    payload: axios.CancelToken.source(),
  });

  const headers = getHeaders();

  const source = getState().assetsManagement.cancelReqToken;

  const data = new FormData();

  Array.from(files).forEach(file => {
    data.append("files", file);
  });

  data.append("importFrom", "instagram");

  axios
    .post("/backend/importFromInstagram", data, {
      headers,
      cancelToken: source.token,
    })
    .then(res => {
      if (res.status === 200) {
        dispatch(fetchImages());
      }
    })
    .catch(error => {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.log(error.message);
      }
    });
};

export const showModal = modalName => dispatch => {
  dispatch({
    type: "MODAL_DISPLAYED",
    payload: modalName,
  });
};

export const hideModal = modalName => dispatch => {
  dispatch({
    type: "MODAL_REMOVED",
    payload: modalName,
  });
};
