import { createSelector } from "reselect";
import * as R from "ramda";
import moment from "moment";

export const getSelectedImagesOrdered = createSelector(
  (state) => state.assetsManagement.images,
  (state) => state.assetsManagement.selectedImagesOrdered,
  (images, selectedImagesOrdered) => {
    return selectedImagesOrdered.map((id) => {
      return images[id];
    });
  }
);

export const getProductsOrdered = createSelector(
  (state) => state.products.products,

  (products) => {
    const latestProductList = R.pipe(
      R.values(),
      R.sort(R.descend(R.prop("id")))
    )(products);
    return latestProductList;
  }
);

export const getImagesGroupedByDate = createSelector(
  (state) => state.assetsManagement.images,
  (state) => state.assetsManagement.searchInput,
  (images, searchInput) => {
    if (images) {
      if (searchInput) {
        images = Object.values(images).filter((image) =>
          image.description.toLowerCase().includes(searchInput)
        );
      }
      let sortedImages = {};

      const result = R.pipe(
        R.groupBy((image) => moment(image.created_at).format("D MMM Y"))
      )(Object.values(images));
      const sortedKeys = Object.keys(result).sort((a, b) => {
        return new Date(b) - new Date(a);
      });

      sortedKeys.forEach((date) => {
        sortedImages[date] = result[date];
      });

      return sortedImages;
    } else {
      return {};
    }
  }
);

export const getCategories = createSelector(
  (state) => state.userProfile.categories,
  (state) => state.userProfile.searchInput,
  (categories, searchInput) => {
    if (searchInput) {
      categories = categories.filter((category) =>
        category.name.toLowerCase().includes(searchInput)
      );
    }
    return categories;
  }
);

export const getProductImageSets = createSelector(
  (state) => state.products.products,
  (state) => state.products.imageSets,
  (state) => state.products.selectedProduct,
  (products, imageSets, selectedProduct) => {
    const productImageSets = {};
    if (products[selectedProduct]) {
      products[selectedProduct].imageSets.forEach((imageSetId) => {
        productImageSets[imageSetId] = imageSets[imageSetId];
      });
    }

    return productImageSets;
  }
);

export const getImageSetImages = createSelector(
  (state) => R.pathOr(null, ['products', 'imageSets'], state),
  (state) => R.pathOr(null, ['products', 'selectedImageSet'], state),
  (state) => R.pathOr(null, ['products', 'images'], state),
  (imageSets, selectedImageSet, images) => {
    if (!selectedImageSet || !imageSets || !images
      || R.isEmpty(imageSets) || R.isEmpty(images)) {
      return [];
    }

    return imageSets[selectedImageSet].imageIds
      .map((imageId) => images[imageId])
      .filter((image) => !!image);
  }
);
