import React from "react";
import styles from "./ProductInfo.module.css";

const ProductInfo = props => {
  const { data, children } = props;

  return (
    <div className={styles.container}>
      <div className={styles.infoSection}>
        <div className={styles.infoItem}>
          <p className={styles.title}>Product name</p>
          <p className={styles.big}>{data.product.name}</p>
        </div>
        <div className={styles.infoItem}>
          <p className={styles.title}>UPC/EAN</p>
          <p>{data.product.upc}</p>
        </div>
        <div className={styles.infoItem}>
          <p className={styles.title}>Product category</p>
          <p>
            {data.product.categories.map(
              (category, i) =>
                category.name +
                (i === data.product.categories.length - 1 ? "" : ", ")
            )}
          </p>
        </div>
      </div>
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  );
};

export default ProductInfo;
