import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./NavbarItem.module.css";

const NavbarItem = props => {
  const { icon, text, url, setActivePage } = props;

  return (
    <NavLink
      className={styles.container}
      to={url}
      activeClassName={styles.active}
      onClick={() => setActivePage(url)}
    >
      <div className={styles.iconContainer}>
        <img
          className={`${styles.iconActive}`}
          src={require(`../static/svg/${icon}_active.svg`)}
        />
        <img
          className={`${styles.iconInactive}`}
          src={require(`../static/svg/${icon}_display.svg`)}
        />
      </div>
      <p className={styles.text}>{text}</p>
    </NavLink>
  );
};

export default NavbarItem;
