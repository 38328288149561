import {
  NEW_SNIPPET_ADDED,
  SNIPPET_DELETED,
  SNIPPET_SELECTED,
  SNIPPET_DESELECTED,
  SNIPPET_LIST_FETCHED,
  TRIGGER_NOTIFICATION,
} from "./actionTypes";
import axios from "axios";
import { getAuthHeader } from "../../auth";
import { startLoading, stopLoading } from "./loadingActions";

const bframeEndpoint = "/backend/bframes";

export const getSnippetList = () => dispatch => {
  axios
    .get(bframeEndpoint, getAuthHeader())
    .then(({ data }) => {
      dispatch({
        type: SNIPPET_LIST_FETCHED,
        payload: data,
      });
    })
    .catch(err => console.log(err));
};

export const addNewSnippet = snippet => dispatch => {
  dispatch(startLoading("add_new_snippet"));

  return axios
    .post(
      bframeEndpoint,
      {
        website: snippet.website,
      },
      getAuthHeader()
    )
    .then(({ data }) => {
      dispatch({
        type: NEW_SNIPPET_ADDED,
        payload: data,
      });
      dispatch(stopLoading("add_new_snippet"));
      dispatch({
        type: TRIGGER_NOTIFICATION,
        payload: {
          message: `Snippet successfully created`,
          type: "SUCCESS",
        },
      });
    })
    .catch(error => {
      console.error(error);
    });
};

export const deleteSnippet = id => dispatch => {
  dispatch(startLoading("delete_snippet"));
  axios
    .delete(`${bframeEndpoint}/${id}`, getAuthHeader())
    .then(() => {
      dispatch({
        type: SNIPPET_DELETED,
        payload: id,
      });
      dispatch(stopLoading("delete_snippet"));
      dispatch({
        type: TRIGGER_NOTIFICATION,
        payload: {
          message: `Snippet successfully deleted`,
          type: "SUCCESS",
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch(stopLoading("delete_snippet"));
    });
};

export const selectSnippet = id => dispatch => {
  dispatch({
    type: SNIPPET_SELECTED,
    payload: id,
  });
};

export const deselectSnippet = () => dispatch => {
  dispatch({
    type: SNIPPET_DESELECTED,
  });
};
