import React from "react";
import styles from "./Image.module.css";
import { connect } from "react-redux";
import { imageClick } from "../../../redux/actions/assetsManagementActions";
import check from "../../../static/svg/checkedCircleLarge.svg";

const Image = props => {
  const { data, imageClick, selectedImages } = props;

  const handleClick = () => {
    imageClick(data.id);
  };

  return (
    <div onClick={handleClick} className={styles.container}>
      <img className={styles.image} src={data.url} alt="" />
      <img
        className={
          selectedImages[data.id] === true ? styles.isSelected : styles.hidden
        }
        src={check}
        alt={data.id}
      />
    </div>
  );
};

export default connect(null, { imageClick })(Image);
