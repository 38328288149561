import React from "react";
import styles from "./MultiSelectInfo.module.css";
import Button from "../../../components/generic/Button";

const MultiSelectInfo = ({
  active,
  trigger,
  productsLength,
  onCancel,
  onDelete,
}) => {
  return (
    <div className={styles.multiSelectInfo}>
      {active ? (
        <>
          <div className={styles.count}>
            <strong>{productsLength}</strong> products selected
          </div>
          {!!productsLength && (
            <div className={styles.delete}>
              <Button
                handleClick={onDelete}
                round
                text={`Delete product${productsLength > 1 ? "s" : ""}`}
              />
            </div>
          )}
          <Button handleClick={onCancel} text={"Cancel"} />
        </>
      ) : (
        trigger
      )}
    </div>
  );
};

export default MultiSelectInfo;
