import React from "react";
import styles from "./EditProductForm.module.css";
import { connect } from "react-redux";
import {
  removeEditProductForm,
  updateProduct,
} from "../redux/actions/productsActions";

import { useFormik } from "formik";
import Input from "./generic/Input";

const EditProductForm = props => {
  const { product, removeEditProductForm, updateProduct } = props;
  const { name, upc, id } = product;

  const formik = useFormik({
    initialValues: {
      name,
      upc,
    },

    validate: values => {
      const errors = {};

      if (!values.name) {
        errors.name = "Product name is required";
      }

      return errors;
    },

    onSubmit: values => {
      updateProduct({ ...values, id });
    },
  });

  const {
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    errors,
    touched,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.container}>
        <div className={styles.inputsContainer}>
          <p className={styles.label}>Edit Product</p>
          <div className={styles.inputWrap}>
            <Input
              handleChange={handleChange}
              name="name"
              type="text"
              grey
              value={values.name}
              placeholder="New Product name"
            />
            {errors.name && touched.name && (
              <p className={styles.error}>{errors.name}</p>
            )}
          </div>

          <Input
            handleChange={handleChange}
            name="upc"
            type="text"
            value={values.upc}
            grey
            placeholder="UPC (you can add later)"
          />
          {errors.failedSubmit && (
            <p className={styles.error}>{errors.failedSubmit}</p>
          )}
        </div>

        <div>
          <input
            type="submit"
            disabled={isSubmitting}
            className={isSubmitting ? styles.loadingBtn : styles.saveBtn}
            value="Save"
          />
          <button
            onClick={() => removeEditProductForm()}
            className={styles.cancelBtn}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

export default connect(null, { removeEditProductForm, updateProduct })(
  EditProductForm
);
