import React from "react";
import styles from "./Save.module.css";

import classNames from "classnames/bind";

let cx = classNames.bind(styles);

const Save = ({ active, text, loading, children }) => {
  let buttonState = cx({
    active,
    loading,
  });

  return (
    <section className={styles.sectionSubmit}>
      {children}
      <input
        type="submit"
        value={text}
        disabled={loading || !active}
        className={`${buttonState} ${styles.default}`}
      />
    </section>
  );
};

export default Save;
