import { combineReducers } from "redux";
import userProfileReducer from "./userProfileReducer";
import assetsManagementReducer from "./assetsManagementReducer";
import retailersReducer from "./retailersReducer";
import validatorsReducer from "./validatorsReducer";
import productsReducer from "./productsReducer";
import navbarReducer from "./navbarReducer";
import loadingReducer from "./loadingReducer";
import notificationsReducer from "./notificationsReducer";

export default combineReducers({
  userProfile: userProfileReducer,
  assetsManagement: assetsManagementReducer,
  retailers: retailersReducer,
  validators: validatorsReducer,
  products: productsReducer,
  navbar: navbarReducer,
  loading: loadingReducer,
  notifications: notificationsReducer,
  application: () => ({ apiUrl: process.env.REACT_APP_API_URL }),
});
