import React from "react";
import styles from "./UploadFiles.module.css";
import { connect } from "react-redux";
import {
  uploadFilesClick,
  uploadZipFilesClick,
  showModal,
  hideModal,
  cancelUploadRequest,
} from "../redux/actions/assetsManagementActions";
import IconWithText from "./IconWithText";
import uploadZip from "../static/svg/upload_zip.svg";
import uploadImages from "../static/svg/upload_images.svg";
import help from "../static/svg/help.svg";

import InstagramExportHelp from "./InstagramExportHelp";
import UploadLoader from "./UploadLoader";

const UploadFiles = props => {
  const {
    uploadFilesClick,
    uploadZipFilesClick,
    cancelUploadRequest,
    showModal,
    hideModal,
    visibleModal,
  } = props;

  const handleShowInstagramExportHelp = () => {
    showModal("instagramExport");
  };

  const importFromInstagram = e => {
    const files = e.target.files;
    uploadZipFilesClick(files);
  };

  const upload = e => {
    const files = e.target.files;
    uploadFilesClick(files);
  };

  const clearInput = e => {
    e.target.value = null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.uploadTab}>
        <div className={styles.textContainer}>
          <p className={styles.text}>Import data from your Instagram account</p>
          <IconWithText
            icon={help}
            highlight
            text="How to get your instagram data"
            handleClick={handleShowInstagramExportHelp}
          />
        </div>
        <label className={styles.uploadBtn}>
          <img src={uploadZip} alt="" />
          <p className={styles.label}>Upload .zip file</p>
          <input
            type="file"
            onClick={clearInput}
            onChange={importFromInstagram}
          />
        </label>
      </div>
      <div className={styles.uploadTab}>
        <p className={styles.text}>Upload images from your computer</p>
        <label className={styles.uploadBtn}>
          <img src={uploadImages} alt="" />
          <p className={styles.label}>Browse computer</p>
          <input type="file" multiple onClick={clearInput} onChange={upload} />
        </label>
      </div>
      <div>
        {visibleModal === "instagramExport" && (
          <InstagramExportHelp hideModal={hideModal} />
        )}
        {visibleModal === "uploadLoader" && (
          <UploadLoader
            hideModal={hideModal}
            cancelUploadRequest={cancelUploadRequest}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    visibleModal: state.assetsManagement.visibleModal,
  };
};

export default connect(mapStateToProps, {
  uploadFilesClick,
  uploadZipFilesClick,
  showModal,
  hideModal,
  cancelUploadRequest,
})(UploadFiles);
