import React, { useEffect } from "react";
import { connect } from "react-redux";
import styles from "./ProductInfo.module.css";

import { useFormik } from "formik";
import { updateProduct } from "../../../redux/actions/productsActions";
import Save from "../../../components/Save";
import ProductGeneralFields from "./ProductGeneralFields";

const ProductInfo = props => {
  const { product, updateProduct, categories } = props;

  const formik = useFormik({
    initialValues: {
      id: product.id,
      name: product.name,
      upc: product.upc,
      categories: product.categories.map(category => {
        return { value: category.id, label: category.name };
      }),
    },
    validate: values => {
      const errors = {};
      if (!values.name) {
        errors.name = "This field is required";
      }

      if (!values.upc) {
        errors.upc = "This field is required";
      }

      if (!values.categories) {
        errors.categories = "This field is required";
      }

      return errors;
    },

    onSubmit: values => {
      const categoryIds = values.categories.map(category => category.value);

      updateProduct({ ...values, categories: categoryIds });
    },

    enableReinitialize: true,
  });

  const {
    values,
    initialValues,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  const onChange = e => {
    handleChange(e);
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <ProductGeneralFields
          touched={touched}
          values={values}
          errors={errors}
          onChange={onChange}
          categories={categories}
          setFieldValue={setFieldValue}
        />
        <div className={styles.save}>
          <Save
            text={"Save Changes"}
            active={
              !(JSON.stringify(initialValues) === JSON.stringify(values)) &&
              values.categories &&
              values.categories.length !== 0
            }
          />
        </div>
      </form>
    </div>
  );
};

export default connect(null, {
  updateProduct,
})(ProductInfo);
