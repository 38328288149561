import React from "react";
import styles from "./Checkbox.module.css";
import Input from "./Input";

const Checkbox = (props) => {
  const { name, id, value, label, checked, handleChange, hasText } = props;

  return (
    <div className={styles.container}>
      <span className={checked ? styles.checked : styles.unchecked}>
        <input
          className={styles.checkboxInput}
          id={id}
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          onChange={handleChange}
        />
      </span>
      <label className={styles.checkboxLabel} htmlFor={id}>
        {!hasText ? (
          label
        ) : (
          <Input
            id="other"
            name="other"
            type="text"
            value={value}
            placeholder={label}
            handleChange={handleChange}
          />
        )}
      </label>
    </div>
  );
};
export default Checkbox;
