import React, { useEffect, useRef } from "react";
import Page from "../../layouts/Page";
import styles from "./index.module.css";

import { connect } from "react-redux";
import {
  fetchProducts,
  fetchImages,
} from "../../redux/actions/assetsManagementActions";

import Header from "../../components/Header";
import ProductList from "./components/ProductList";
import Tabs from "../../components/Tabs";
import Tab from "../../components/Tab";
import {
  addProductTabs,
  productTabs,
  tabClick,
  removeUploadFromAssetsModal,
  toggleOverlayVisibility,
  toggleAddProductForm,
  deleteProduct,
  toggleIsMultiSelect,
} from "../../redux/actions/productsActions";
import { PRODUCT_INFORMATION_TAB, PRODUCT_IMAGE_SETS_TAB } from "./tabs";
import ProductInfo from "./tabs/ProductInfo";
import ProductImageSets from "./tabs/ProductImageSets";
import { getProductImageSets } from "../../selectors";
import { getCategories } from "../../redux/actions/userProfileActions";

import UploadFromAssets from "./components/UploadFromAssets";
import AddProduct from "./tabs/AddProduct";
import Button from "../../components/generic/Button";
import Overlay from "./components/Overlay";
import Loader from "../../components/generic/Loader";
import MultiSelectInfo from "./components/MultiSelectInfo";

const ProductsPage = props => {
  const {
    fetchProducts,
    fetchImages,
    products,
    selectedProduct,
    selectedImageSet,
    activeTab,
    productImageSets,
    tabClick,
    isUploadFromAssetsVisible,
    isOverlayVisible,
    removeUploadFromAssetsModal,
    toggleOverlayVisibility,
    toggleAddProductForm,
    isAddProductFormVisible,
    categories,
    getCategories,
    loading,
    deleteProduct,
    selectedProductList,
    toggleIsMultiSelect,
    isMultiSelect,
  } = props;

  const leftPanelRef = useRef(null);

  useEffect(() => {
    fetchProducts();
    getCategories();
  }, []);

  const formatCategories = categories => {
    let result = [];

    categories.map(category => {
      result.push({
        value: category.id,
        label: category.name,
      });
    });

    return result;
  };

  const renderTab = activeTab => {
    switch (activeTab) {
      case PRODUCT_INFORMATION_TAB:
        return (
          categories && (
            <ProductInfo
              product={products[selectedProduct]}
              categories={formatCategories(categories)}
            />
          )
        );
        break;

      case PRODUCT_IMAGE_SETS_TAB:
        return (
          <ProductImageSets
            products={products}
            selectedProduct={selectedProduct}
            selectedImageSet={selectedImageSet}
            imageSets={productImageSets}
          />
        );
        break;
    }
  };

  // TODO: Please refactor rightPanel into a separate component so that it handles its own open and close effects.
  // TODO: This way we it won't be necessary to write the 2 methods below.
  const toggleAddProduct = () => {
    toggleAddProductForm();
    toggleOverlayVisibility();
  };

  const onRemoveUploadFromAssets = () => {
    removeUploadFromAssetsModal();
    toggleOverlayVisibility();
  };

  return (
    <Page>
      {loading.length > 0 && <Loader />}
      <div className={styles.container}>
        {isOverlayVisible && (
          <Overlay
            onClose={
              isAddProductFormVisible
                ? toggleAddProduct
                : onRemoveUploadFromAssets
            }
          />
        )}
        <div ref={leftPanelRef} className={styles.leftPanel}>
          <div className={styles.productList}>
            <div className={styles.panelHeader}>
              <h2 className={styles.title}>My Products</h2>
              <Button
                round
                backgroundYellow
                handleClick={toggleAddProduct}
                text={"+ Add new product"}
              />
            </div>
            {/* this is ugly but we need to rebuild the layout for the productList in order not to make this */}
            <div
              className={`${styles.productListContainer} ${
                isMultiSelect ? styles.isMultiSelect : ""
              }`}
            >
              <ProductList />
            </div>
          </div>

          {!!Object.keys(products).length && (
            <div
              style={{
                width: `${
                  leftPanelRef.current && leftPanelRef.current.clientWidth
                }px`,
              }}
              className={styles.multiSelectContainer}
            >
              <MultiSelectInfo
                trigger={
                  <Button
                    handleClick={toggleIsMultiSelect}
                    round
                    text={"Delete multiple products"}
                  ></Button>
                }
                active={isMultiSelect}
                onCancel={toggleIsMultiSelect}
                onDelete={() => {
                  toggleIsMultiSelect();
                  deleteProduct(selectedProductList);
                }}
                productsLength={selectedProductList.length}
              />
            </div>
          )}
        </div>
        {isUploadFromAssetsVisible ? (
          <UploadFromAssets
            removeUploadFromAssetsModal={removeUploadFromAssetsModal}
          />
        ) : (
          <div className={styles.rightPanel}>
            {isAddProductFormVisible ? (
              <>
                <Header title={"Add new product"}>
                  <Tabs>
                    <Tab
                      text="Product Information"
                      name={addProductTabs.productInfo}
                      isActive={activeTab === productTabs.productInfo}
                      tabClick={tabClick}
                    />
                  </Tabs>
                </Header>
                {categories && (
                  <AddProduct
                    categories={formatCategories(categories)}
                    toggleAddProduct={toggleAddProduct}
                  />
                )}
              </>
            ) : (
              <>
                {selectedProduct && (
                  <Header
                    title={products[selectedProduct].name}
                    withActionButton
                    isMultiSelect={isMultiSelect}
                    handleClick={() => {
                      if (window.confirm("Are you sure?")) {
                        deleteProduct([products[selectedProduct].id]);
                      }
                    }}
                  >
                    <Tabs>
                      <Tab
                        text="Product Information"
                        name={productTabs.productInfo}
                        isActive={activeTab === productTabs.productInfo}
                        tabClick={tabClick}
                      />
                      <Tab
                        text="Product Image Sets"
                        name={productTabs.productImageSets}
                        isActive={activeTab === productTabs.productImageSets}
                        tabClick={tabClick}
                      />
                    </Tabs>
                  </Header>
                )}

                {selectedProduct && renderTab(activeTab)}
              </>
            )}
          </div>
        )}
      </div>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    activeTab: state.products.activeTab,
    products: state.products.products,
    selectedProduct: state.products.selectedProduct,
    selectedImageSet: state.products.selectedImageSet,
    isUploadFromAssetsVisible: state.products.isUploadFromAssetsVisible,
    isOverlayVisible: state.products.isOverlayVisible,
    productImageSets: getProductImageSets(state),
    isAddProductFormVisible: state.products.isAddProductFormVisible,
    categories: state.userProfile.categories,
    loading: state.loading.loading,
    selectedProductList: state.products.selectedProductList,
    isMultiSelect: state.products.isMultiSelect,
  };
};

export default connect(mapStateToProps, {
  fetchProducts,
  tabClick,
  fetchImages,
  removeUploadFromAssetsModal,
  toggleOverlayVisibility,
  toggleAddProductForm,
  getCategories,
  deleteProduct,
  toggleIsMultiSelect,
})(ProductsPage);
