import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./ActivateAccount.module.css";
import emailIcon from "../static/svg/emailSuccess.svg";
import { storeLoginInfo } from "../auth";
import axios from "axios";
import Input from "./generic/Input";
import Error from "./generic/Error";
import { useFormik } from "formik";

const ActivateAccount = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const activationToken = params.get("activationToken");

  const [renderPage, setRenderPage] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    axios
      .post(`/backend/activate`, { activationToken })
      .then(res => {
        if (res.data === "success") {
          setRenderPage(true);
        }

        if (res.data === "expired") {
          setRenderPage(false);
          setMessage("This activation token has expired.");
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },

    validate: values => {
      const errors = {};
      if (!values.email) {
        errors.email = "This field is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      if (!values.password) {
        errors.password = "This field is required";
      }
      return errors;
    },

    onSubmit: (values, { setErrors, setSubmitting }) => {
      axios
        .post(`/backend/auth/login`, values)
        .then(res => {
          if (res.data.name === "Error") {
            setErrors({ invalidLogin: "Incorrect email or password" });
            setSubmitting(false);
          } else {
            redirect(res.data);
          }
        })
        .catch(err => {
          setErrors({ invalidLogin: "Something went wrong" });
          setSubmitting(false);
          console.log(err);
        });
    }
  });

  const redirect = loginInfo => {
    storeLoginInfo(loginInfo);
    document.location = "/home";
  };

  const {
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    errors,
    touched
  } = formik;

  return (
    <React.Fragment>
      {renderPage ? (
        <div className={`box-wide`}>
          <div className="column-grid">
            <div className="box-half">
              <form className={styles.form} onSubmit={handleSubmit}>
                <h1 className={styles.title}>
                  Thanks for verifying your email!
                </h1>
                <p className={styles.subtitle}>
                  Please login to your account bellow!
                </p>

                <div className={styles.inputsContainer}>
                  <div className={styles.inputWrapper}>
                    <Input
                      name="email"
                      type="email"
                      value={values.email}
                      handleChange={handleChange}
                      placeholder="Your email"
                    />
                    {errors.email && touched.email && (
                      <Error message={errors.email} />
                    )}
                  </div>
                  <div className={styles.inputWrapper}>
                    <Input
                      type="password"
                      name="password"
                      value={values.password}
                      handleChange={handleChange}
                      placeholder="Your password"
                    />
                    {errors.password && touched.password && (
                      <Error message={errors.password} />
                    )}
                    {errors.invalidLogin && (
                      <Error message={errors.invalidLogin} />
                    )}
                  </div>
                  <Link
                    to="/reset-password/request"
                    className={styles.forgotPassLink}
                  >
                    Forgot your password?
                  </Link>
                  <div className={styles.submitBtn}>
                    <Input
                      isDisabled={isSubmitting}
                      isSubmitBtn={true}
                      type="submit"
                      value="Login"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="box-half">
              <div className={styles.iconContainer}>
                <div className={styles.icon}>
                  <img className="responsive-image" src={emailIcon} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`box-wide ${styles.container}`}>
          <h1 dangerouslySetInnerHTML={{ __html: message }}></h1>
        </div>
      )}
    </React.Fragment>
  );
};

export default ActivateAccount;
