import React from "react";
import styles from "./AddImageToSetForm.module.css";

import { connect } from "react-redux";
import {
  updateProduct,
  updateImageSetAndUncheck,
} from "../redux/actions/productsActions";

import Button from "../components/generic/Button";
import { startLoading, stopLoading } from "../redux/actions/loadingActions";


const AddImageToSetForm = ({
  selectedImages,
  selectedImageSets,
  updateImageSetAndUncheck,
  hideProductList,
  loading,
}) => {
  const handleSubmit = e => {
    e.preventDefault();
    updateImageSetAndUncheck({
      key: "imageIds",
      value: selectedImages,
    });
  };

  const selectedImageSetsCount =
    selectedImageSets && Object.keys(selectedImageSets).length;

  return (
    <form onSubmit={handleSubmit}>
      <div className={`${styles.btns}`}>
        <Button
          type="submit"
          backgroundYellow={
            selectedImageSetsCount > 0 && !loading.includes("update_image_set")
          }
          loading={loading.includes("update_image_set")}
          disabled={selectedImageSetsCount < 1}
          round
          text="Save"
        />

        <Button
          handleClick={() => hideProductList()}
          greyText
          text={`Cancel`}
        />
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  return {
    selectedImages: state.assetsManagement.selectedImagesOrdered,
    selectedProduct: state.products.selectedProduct,
    loading: state.loading.loading,
  };
};

export default connect(mapStateToProps, {
  updateProduct,
  updateImageSetAndUncheck,
  startLoading,
  stopLoading,
})(AddImageToSetForm);
