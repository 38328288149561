import React from "react";
import styles from "./FinalRating.module.css";

const FinalRating = ({ score }) => {
  const _renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      const starIcon = getStarIcon(i, score);
      stars.push(
        <img
          key={i}
          className={styles.star}
          src={require(`../../../static/svg/${starIcon}.svg`)}
        />
      );
    }
    return stars;
  };

  const getStarIcon = (i, score) => {
    if (i < Math.trunc(score)) return "fullStar";
    if (i === Math.trunc(score)) {
      const decimal = (score - Math.trunc(score)).toFixed(1);
      if (decimal < 0.3) return "emptyStar";
      if (decimal >= 0.3 && decimal <= 0.7) return "halfStar";
      if (decimal > 0.7) return "fullStar";
    }
    return "emptyStar";
  };

  if (score === null)
    return (
      <div className={styles.noScore}>
        <img
          className={styles.noScoreStar}
          src={require(`../../../static/svg/emptyStar.svg`)}
        />
        <span className={styles.noScoreText}>Pending</span>
      </div>
    );

  return (
    <>
      <div className={styles.item}>
        {_renderStars()}
        <span className={styles.number}>{score}</span>
      </div>
    </>
  );
};

export default FinalRating;
