import {
  ACTIVE_PAGE_SET,
  EDIT_PRODUCT_FORM_DISPLAYED,
  EDIT_PRODUCT_FORM_REMOVED,
  IMAGE_SET_CHECKED,
  IMAGE_SET_DELETED,
  IMAGE_SET_FORM_DISPLAYED,
  IMAGE_SET_FORM_REMOVED,
  IMAGE_SET_IMAGE_SELECTED,
  IMAGE_SET_SELECTED,
  IMAGE_SET_UPDATED,
  IMAGE_SETS_DELETED,
  IMAGES_REMOVED_FROM_IMAGE_SET,
  MULTI_SELECT_PRODUCT,
  NEW_IMAGE_SET_ADDED,
  NEW_PRODUCT_ADDED,
  PRODUCT_DELETED,
  PRODUCT_SELECTED,
  PRODUCT_UPDATED,
  PRODUCTS_FETCHED,
  RETURNED_TO_ALL_SETS_VIEW,
  TAB_CLICKED,
  TOGGLE_ADD_PRODUCT,
  TOGGLE_EXPANDED_PRODUCT,
  TOGGLE_IS_MULTISELECT,
  TOGGLE_OVERLAY_VISIBILITY,
  TOGGLE_PRODUCT_FORM,
  TOGGLE_PRODUCTS,
  UPLOAD_FROM_ASSETS_MODAL_DISPLAYED,
  UPLOAD_FROM_ASSETS_MODAL_REMOVED,
} from "../actions/actionTypes";

import { getProductsOrdered } from "../../selectors";

import * as R from "ramda";

function onProductsFetched(state, productAndImageSetsList) {
  if (!productAndImageSetsList || !productAndImageSetsList.length) {
    return state;
  }

  const products = {};
  const imageSets = {};
  const images = {};
  productAndImageSetsList.forEach((productWithImageSets) => {
    const product = {
      id: productWithImageSets.id,
      name: productWithImageSets.name,
      upc: productWithImageSets.upc,
      categories: productWithImageSets.categories,
      imageSets: [],
    };

    productWithImageSets.imageSets.forEach((imageSet) => {
      product.imageSets.push(imageSet.id);

      imageSets[imageSet.id] = {
        id: imageSet.id,
        name: imageSet.name,
        imageIds: imageSet.imageIds.map((id) => id),
        state: imageSet.state,
        coverImageId: imageSet.coverImageId,
        coverImageUrl: imageSet.coverImageUrl,
        personalUse: imageSet.personalUse,
      };
      (imageSet.images || []).forEach(
        (image) => (images[image.id] = { id: image.id, url: image.url })
      );
    });

    products[product.id] = product;
  });

  const selectedProduct = getProductsOrdered({ products: { products } })[0].id;
  return {
    ...state,
    products,
    imageSets,
    images,
    selectedProduct,
    expandedProducts: { [selectedProduct]: true },
  };
}

export const defaultProductsState = {
  products: {},
  activeTab: "PRODUCT_INFORMATION_TAB",
  expandedProducts: {},
  selectedProduct: "",
  selectedProductList: [],
  imageSets: {},
  selectedImageSet: "",
  selectedImageSets: {},
  imageSetSelectedImages: {},
  imageSetForm: "",
  editProductForm: "",
  isProductListVisible: false,
  isProductFormVisible: false,
  isGrayedOut: false,
  imageSetStates: {
    draft: "Draft",
    submitted: "Submitted",
    validated: "Validated",
    ready_to_use: "Ready to use",
    rejected: "Rejected",
    distributed: "Distributed",
    disabled: "Disabled",
    archived: "Archived",
    disposed: "Disposed",
    revoked: "Revoked",
  },
  isUploadFromAssetsVisible: false,
  isOverlayVisible: false,
  isAddProductFormVisible: false,
  isMultiSelect: false,
}

export default (
  state = {...defaultProductsState},
  { payload, type }
) => {
  switch (type) {
    case TAB_CLICKED:
      return {
        ...state,
        activeTab: payload,
      };
    case PRODUCTS_FETCHED:
      return onProductsFetched(state, payload);

    case ACTIVE_PAGE_SET: {
      if (!state.products || R.isEmpty(state.products)) {
        return state;
      }

      const selectedProduct = getProductsOrdered({
        products: { products: state["products"] },
      })[0].id;
      return {
        ...state,
        selectedProduct,
        expandedProducts: { [selectedProduct]: true },
        selectedImageSets: {},
        selectedImageSet: "",
      };
    }

    case TOGGLE_PRODUCTS: {
      return {
        ...state,
        isProductListVisible: !state["isProductListVisible"],
        expandedProducts: {},
        selectedImageSets: {},
      };
    }

    case TOGGLE_PRODUCT_FORM: {
      return {
        ...state,
        isProductFormVisible: !state["isProductFormVisible"],
        expandedProducts: {},
        selectedImageSets: {},
        isGrayedOut: !state["isGrayedOut"],
      };
    }

    case TOGGLE_ADD_PRODUCT: {
      return {
        ...state,
        isAddProductFormVisible: !state.isAddProductFormVisible,
      };
    }

    case NEW_PRODUCT_ADDED: {
      const products = {
        ...state["products"],
        [payload.id]: {
          id: payload.id,
          name: payload.name,
          upc: payload.upc,
          imageSets: payload.imageSets.length ? [payload.imageSets[0]?.id] : [],
          categories: payload.categories,
        },
      };

      const imageSets = payload.imageSets.length
        ? {
            ...state["imageSets"],
            [payload.imageSets[0]?.id]: payload.imageSets[0],
          }
        : { ...state["imageSets"] };

      return {
        ...state,
        products,
        imageSets,
        isProductFormVisible: false,
        isGrayedOut: false,
        isAddProductFormVisible: false,
        isOverlayVisible: false,
      };
    }

    case TOGGLE_EXPANDED_PRODUCT: {
      const expandedProducts = {
        ...state["expandedProducts"],
        [payload]: !state["expandedProducts"][payload],
      };

      return {
        ...state,
        expandedProducts,
      };
    }

    case IMAGE_SET_FORM_DISPLAYED: {
      return {
        ...state,
        expandedProducts: { [payload]: true },
        selectedImageSets: {},
        imageSetForm: payload,
        isGrayedOut: true,
      };
    }

    case IMAGE_SET_FORM_REMOVED: {
      return {
        ...state,
        imageSetForm: "",
        isGrayedOut: false,
      };
    }

    case NEW_IMAGE_SET_ADDED: {
      const newProducts = {
        ...state["products"],
      };

      const newImageSets = {
        ...state["imageSets"],
        [payload.imageSet.id]: { ...payload.imageSet, state: "draft" },
      };

      newProducts[payload.productId].imageSets = [
        ...newProducts[payload.productId].imageSets,
        payload.imageSet.id,
      ];

      return {
        ...state,
        products: newProducts,
        imageSets: newImageSets,
        imageSetForm: "",
        isGrayedOut: false,
        selectedImageSet: payload.imageSet.id,
      };
    }

    case EDIT_PRODUCT_FORM_DISPLAYED: {
      return {
        ...state,
        expandedProducts: {},
        selectedImageSets: {},
        editProductForm: payload,
        isGrayedOut: true,
      };
    }

    case EDIT_PRODUCT_FORM_REMOVED: {
      return {
        ...state,
        editProductForm: "",
        isGrayedOut: false,
      };
    }

    case PRODUCT_UPDATED: {
      return {
        ...state,
        products: {
          ...state.products,
          [payload.id]: {
            id: payload.id,
            name: payload.name,
            upc: payload.upc,
            imageSets: state.products[payload.id].imageSets,
            categories: payload.categories,
          },
        },
      };
    }

    case PRODUCT_DELETED: {
      const products = { ...state.products };
      delete products[payload];

      if (!products || R.isEmpty(products)) {
        return { ...state, products, selectedProduct: "" };
      }

      const latestProductList = R.pipe(
        R.values(),
        R.sort(R.descend(R.prop("id")))
      )(products);

      const selectedProduct = latestProductList[0].id;

      return {
        ...state,
        products,
        selectedProduct,
        expandedProducts: { [selectedProduct]: true },
        selectedImageSets: {},
        selectedImageSet: "",
      };
    }

    case IMAGE_SET_CHECKED: {
      const selectedImageSets = {
        ...state["selectedImageSets"],
      };

      if (selectedImageSets[payload]) {
        delete selectedImageSets[payload];
      } else {
        selectedImageSets[payload] = true;
      }

      return {
        ...state,
        selectedImageSets,
      };
    }

    case PRODUCT_SELECTED: {
      return {
        ...state,
        selectedProduct: payload,
        selectedImageSet: "",
        selectedImageSets: {},
        imageSetSelectedImages: {},
        activeTab: "PRODUCT_INFORMATION_TAB",
        expandedProducts: {
          ...state["expandedProducts"],
          [payload]: true,
        },
      };
    }

    case IMAGE_SET_SELECTED: {
      return {
        ...state,
        selectedImageSet: payload.imageSetId,
        selectedProduct: payload.productId,
        selectedImageSets: {},
        imageSetSelectedImages: {},
        activeTab: "PRODUCT_IMAGE_SETS_TAB",
      };
    }

    case IMAGE_SETS_DELETED: {
      const prevProductImageSets = [
        ...state["products"][payload.selectedProduct].imageSets,
      ];

      const nextProductImageSets = prevProductImageSets.filter(
        (imageSetId) => !payload.deletedImageSets.includes(imageSetId)
      );

      const products = {
        ...state["products"],
        [payload.selectedProduct]: {
          ...state["products"][payload.selectedProduct],
          imageSets: nextProductImageSets,
        },
      };

      const prevImageSets = { ...state["imageSets"] };

      payload.deletedImageSets.forEach((id) => {
        delete prevImageSets[id];
      });

      const imageSets = { ...prevImageSets };

      return {
        ...state,
        imageSets,
        products,
        selectedImageSets: {},
      };
    }

    case IMAGE_SET_DELETED: {
      const imageSets = { ...state["imageSets"] };
      delete imageSets[payload.selectedImageSet];

      const oldProducts = { ...state["products"] };

      const productImageSets = oldProducts[
        payload.selectedProduct
      ].imageSets.filter(
        (imageSetId) => imageSetId !== payload.selectedImageSet
      );

      const products = {
        ...oldProducts,
        [payload.selectedProduct]: {
          ...oldProducts[payload.selectedProduct],
          imageSets: productImageSets,
        },
      };

      return {
        ...state,
        products,
        imageSets,
        selectedImageSet: "",
      };
    }

    case IMAGE_SET_UPDATED: {
      const images = { ...state.images };
      (payload.images || []).forEach(
        (image) => (images[image.id] = { id: image.id, url: image.url })
      );

      return {
        ...state,
        imageSets: {
          ...state.imageSets,
          [payload.id]: payload,
        },
        isUploadFromAssetsVisible: false,
        images: images,
      };
    }

    case IMAGES_REMOVED_FROM_IMAGE_SET: {
      const selectedImageSetId = state.selectedImageSet;
      const selectedImageSet = state.imageSets[selectedImageSetId];

      const newImageIds = selectedImageSet.imageIds.filter((imageId) => {
        return !payload.includes(imageId);
      });
      const imageSets = { ...state.imageSets };
      imageSets[selectedImageSetId].imageIds = newImageIds;

      return {
        ...state,
        imageSets,
        imageSetSelectedImages: {},
      };
    }

    case IMAGE_SET_IMAGE_SELECTED: {
      const imageSetSelectedImages = { ...state["imageSetSelectedImages"] };

      if (!imageSetSelectedImages[payload]) {
        imageSetSelectedImages[payload] = true;
      } else {
        imageSetSelectedImages[payload] = !state["imageSetSelectedImages"][
          payload
        ];
      }

      return {
        ...state,
        imageSetSelectedImages,
      };
    }

    case RETURNED_TO_ALL_SETS_VIEW: {
      return {
        ...state,
        selectedImageSet: "",
      };
    }

    case UPLOAD_FROM_ASSETS_MODAL_DISPLAYED: {
      return {
        ...state,
        isUploadFromAssetsVisible: true,
      };
    }

    case UPLOAD_FROM_ASSETS_MODAL_REMOVED: {
      return {
        ...state,
        isUploadFromAssetsVisible: false,
      };
    }

    case TOGGLE_OVERLAY_VISIBILITY:
      return {
        ...state,
        isOverlayVisible: !state.isOverlayVisible,
      };

    case TOGGLE_IS_MULTISELECT:
      return {
        ...state,
        isMultiSelect: !state.isMultiSelect,
        selectedProductList: [],
      };

    case MULTI_SELECT_PRODUCT:
      const productList = [...state.selectedProductList];
      const index = productList.indexOf(payload);
      index === -1 ? productList.push(payload) : productList.splice(index, 1);

      return {
        ...state,
        selectedProductList: productList,
      };

    default:
      return state;
  }
};
