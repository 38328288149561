import {
  IMAGES_FETCHED,
  TAB_CLICKED,
  IMAGE_CLICKED,
  TOGGLE_RIGHT_PANEL_CLICKED,
  DELETED_IMAGES_SUCCESS,
  UPLOADED_IMAGES_SUCCESS,
  IMAGES_SEARCHED,
} from "../actions/actionTypes";

import * as R from "ramda";

export default (
  state = {
    activeTab: "IMAGES_TAB",
    images: {},
    selectedImages: {},
    selectedImagesOrdered: [],
    searchInput: "",
    isRightPanel: true,
    visibleModal: "",
    cancelReqToken: "",
    page: 1,
    total: 1,
    count: 0,
  },
  { payload, type }
) => {
  switch (type) {
    case TAB_CLICKED:
      return {
        ...state,
        activeTab: payload,
      };

    case IMAGES_FETCHED: {
      const data = R.pipe(R.indexBy(R.prop("id")))(payload.results);

      return {
        ...state,
        images: { ...state.images, ...data },
        visibleModal: "",
        page: state.page + 1,
        total: payload.total || state.total,
        count: state.count + payload.results.length,
      };
    }

    case IMAGES_SEARCHED: {
      const { input } = payload;

      return {
        ...state,
        searchInput: input,
      };
    }

    case IMAGE_CLICKED: {
      const selectedImages = resolveSelectedImages(
        state.selectedImages,
        payload
      );

      const selectedImagesOrdered = resolveSelectedImagesOrdered(
        state.selectedImagesOrdered,
        payload
      );

      return {
        ...state,
        selectedImages: selectedImages,
        selectedImagesOrdered: selectedImagesOrdered,
      };
    }

    case TOGGLE_RIGHT_PANEL_CLICKED: {
      return {
        ...state,
        isRightPanel: !state.isRightPanel,
      };
    }

    case DELETED_IMAGES_SUCCESS: {
      const deletedImageIds = payload
        .filter((image) => image.status === 200)
        .map((image) => image.id);
      const newSelectedImagesOrdered = R.difference(
        state["selectedImagesOrdered"],
        deletedImageIds
      );

      const newImages = R.omit(deletedImageIds, state["images"]);
      const newSelectedImages = R.omit(
        deletedImageIds,
        state["selectedImages"]
      );

      return {
        ...state,
        images: newImages,
        selectedImages: newSelectedImages,
        selectedImagesOrdered: newSelectedImagesOrdered,
      };
    }

    case UPLOADED_IMAGES_SUCCESS: {
      const newImages = { ...state["images"] };
      payload.forEach((image) => {
        newImages[image.id] = image;
      });

      return {
        ...state,
        images: newImages,
        visibleModal: "",
      };
    }

    case "CANCEL_REQUEST_TOKEN_SET": {
      return {
        ...state,
        cancelReqToken: payload,
      };
    }

    case "UPLOAD_CANCELED": {
      const cancelToken = state.cancelReqToken;
      cancelToken.cancel("Request canceled!");
      return {
        ...state,
        cancelReqToken: "",
      };
    }

    case "MODAL_DISPLAYED": {
      return {
        ...state,
        visibleModal: payload,
      };
    }

    case "MODAL_REMOVED": {
      return {
        ...state,
        visibleModal: payload,
      };
    }

    case "UPLOAD_FROM_ASSETS_MODAL_REMOVED": {
      return {
        ...state,
        selectedImages: {},
        selectedImagesOrdered: [],
      };
    }

    case "ACTIVE_PAGE_SET": {
      return {
        ...state,
        selectedImages: {},
        selectedImagesOrdered: [],
      };
    }

    case "UPLOAD_FROM_ASSETS_MODAL_REMOVED": {
      return {
        ...state,
        selectedImages: {},
        selectedImagesOrdered: [],
      };
    }

    case "UPLOAD_FROM_ASSETS_MODAL_DISPLAYED": {
      return {
        ...state,
        selectedImages: {},
        selectedImagesOrdered: [],
      };
    }

    default:
      return state;
  }
};

const resolveSelectedImages = (oldSelectedImages, id) => {
  const selectedImages = { ...oldSelectedImages };
  const isImageSelected = !selectedImages[id];

  return {
    ...selectedImages,
    [id]: isImageSelected,
  };
};

const resolveSelectedImagesOrdered = (oldSelectedImagesOrdered, id) => {
  const newSelectedImagesOrdered = [...oldSelectedImagesOrdered];

  if (!newSelectedImagesOrdered.includes(id)) {
    return [id, ...newSelectedImagesOrdered];
  } else {
    return newSelectedImagesOrdered.filter((i) => {
      return i !== id;
    });
  }
};
