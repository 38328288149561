import React, { useState } from "react";
import styles from "./Rating.module.css";

const Rating = ({ rating, setRating, readOnly }) => {
  const [hover, setHover] = useState({ state: false, starId: "" });

  const handleOnStarClick = value => {
    if (!readOnly) {
      setRating(value);
      setHover({ state: false, starId: "" });
    }
  };

  const getStarState = star => {
    if (hover.state === true) {
      if (hover.starId >= star) {
        return "hover";
      } else return "empty";
    } else {
      if (rating >= star) {
        return "filled";
      } else return "empty";
    }
  };

  const stars = [1, 2, 3, 4, 5];

  return (
    <div className={styles.container}>
      {stars.map((star, i) => {
        return (
          <div
            key={i}
            onClick={() => handleOnStarClick(star)}
            onMouseEnter={() =>
              !readOnly && setHover({ state: true, starId: star })
            }
            onMouseLeave={() =>
              !readOnly && setHover({ state: false, starId: "" })
            }
            className={`${readOnly && styles.readOnly} ${styles.star}`}
          >
            <img
              src={require(`../../../static/svg/star_${getStarState(
                star
              )}.svg`)}
              alt=""
            />
          </div>
        );
      })}
    </div>
  );
};

export default Rating;
