import React from "react";
import styles from "./ImageSetForm.module.css";
import { useFormik } from "formik";

import Error from "../../../components/generic/Error";
import Button from "../../../components/generic/Button";

const ImageSetForm = props => {
  const {
    selectedProduct,
    update,
    setIsFormVisible,
    addNewImageSet,
    updateImageSet,
  } = props;

  const formik = useFormik({
    initialValues: {
      name: "",
    },

    validate: values => {
      const errors = {};

      if (!values.name) {
        errors.name = "Set name is required";
      }

      return errors;
    },

    onSubmit: ({ name }) => {
      if (update) {
        updateImageSet(
          {
            key: "name",
            value: name,
            notificationMessage: `${name} successfully updated`,
          },
          setIsFormVisible
        );
      } else {
        addNewImageSet({
          name,
          productId: selectedProduct,
          selectedImages: [],
        });
      }
    },
  });

  const {
    handleChange,
    handleSubmit,
    isSubmitting,
    values,
    touched,
    errors,
  } = formik;
  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div style={{ position: "relative" }}>
        <input
          onChange={handleChange}
          name="name"
          className={styles.input}
          type="submit"
          type="text"
          value={values.name}
          placeholder="Enter set name"
        />
        {errors.name && touched.name && <Error message={errors.name} />}
      </div>

      <div className={styles.btn}>
        <Button
          round
          loading={isSubmitting}
          backgroundYellow
          text="Save Set Name"
          type="submit"
        />
      </div>
      <Button
        greyText
        handleClick={() => setIsFormVisible(false)}
        text={`Cancel`}
      />
    </form>
  );
};

export default ImageSetForm;
