import * as R from "ramda";

const UserRoles = {
  CREATOR: 'Creator',
  CONSUMER: 'Consumer',
  VALIDATOR: 'Validator',
}

export const isLoggedIn = () => {
  return localStorage.getItem("token") != null;
};

export const isVerified = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return !(!user || (user.status === 'unverified'));
};

export const hasRoleAssigned = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return (user && user.roles && Array.isArray(user.roles) && user.roles.length);
};

export const isCreator = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return R.find(R.propEq('name', UserRoles.CREATOR), user.roles);
}


export const isConsumer = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return R.find(R.propEq('name', UserRoles.CONSUMER), user.roles);
}

export const isValidator = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return R.find(R.propEq('name', UserRoles.VALIDATOR), user.roles);
}

export const storeLoginInfo = (loginInfo) => {
  const { token, user } = loginInfo;
  // const cookieValue = pim.cookies[0].value;
  // const [setCookie] = useCookies();
  // setCookie("BAPID", `${cookieValue}`);
  localStorage.setItem("token", `${token}`);
  localStorage.setItem("user", JSON.stringify(user));
};

export const getAuthHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    }
  };
};
