import React, { useRef } from "react";
import styles from "./UploadFromAssets.module.css";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { getImagesGroupedByDate } from "../../../selectors";
import { searchImages } from "../../../redux/actions/assetsManagementActions";
import {
  updateImageSet,
  toggleOverlayVisibility,
} from "../../../redux/actions/productsActions";

import AssetsImages from "../../../components/AssetsImages";
import Search from "../../../components/Search";
import Button from "../../../components/generic/Button";

import closeIcon from "../../../static/svg/close.svg";

const UploadFromAssets = props => {
  const {
    images,
    products,
    selectedProduct,
    imageSets,
    selectedImageSet,
    selectedImages,
    imagesGroupedByDate,
    removeUploadFromAssetsModal,
    toggleOverlayVisibility,
    searchImages,
    updateImageSet,
  } = props;

  const containerRef = useRef(null);

  const formik = useFormik({
    initialValues: {},

    onSubmit: () => {
      const imageIds = Object.keys(selectedImages).filter(
        i => selectedImages[i] === true
      );

      updateImageSet(
        {
          key: "imageIds",
          value: imageIds,
          notificationMessage: `${imageIds.length} image${
            imageIds.length > 1 ? "s" : ""
          } successfully added`,
        },
        toggleOverlayVisibility
      );
    },
  });

  const search = e => {
    if (Object.keys(images).length === 0) {
      return;
    }
    const input = e.target.value.toLowerCase();
    searchImages({ input });
  };

  const selectedImagesCount = Object.values(selectedImages).filter(
    i => i === true
  ).length;

  const { isSubmitting, handleSubmit } = formik;

  const onUploadCancel = () => {
    toggleOverlayVisibility();
    removeUploadFromAssetsModal();
  };

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <h2>
            <span className={styles.title}>Select images for </span>
            {`${products[selectedProduct].name}/${imageSets[selectedImageSet].name}`}
          </h2>
          <div onClick={onUploadCancel} className={styles.closeIcon}>
            <img src={closeIcon} alt="closeButton" />
          </div>
        </div>

        <Search
          search={search}
          placeholder={
            "Search by keyword or select a label bellow  to filter images"
          }
        />
      </div>
      <AssetsImages
        products={products}
        selectedProduct={selectedProduct}
        imagesGroupedByDate={imagesGroupedByDate}
        selectedImages={selectedImages}
        removeUploadFromAssetsModal={removeUploadFromAssetsModal}
      />
      {selectedImagesCount > 0 && (
        <form
          style={{
            width: `${
              containerRef.current && containerRef.current.clientWidth
            }px`,
          }}
          onSubmit={handleSubmit}
          className={styles.footerBar}
        >
          <div className={styles.btns}>
            <Button
              type="submit"
              round
              loading={isSubmitting}
              backgroundYellow
              text={`Add ${selectedImagesCount} Image${
                selectedImagesCount ? "s" : ""
              }`}
            />
            <Button
              handleClick={() => onUploadCancel()}
              greyText
              text={`Cancel`}
            />
          </div>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    images: state.assetsManagement.images,
    selectedImages: state.assetsManagement.selectedImages,
    products: state.products.products,
    selectedProduct: state.products.selectedProduct,
    imageSets: state.products.imageSets,
    selectedImageSet: state.products.selectedImageSet,
    imagesGroupedByDate: getImagesGroupedByDate(state),
    config: state.application,
  };
};

export default connect(mapStateToProps, {
  searchImages,
  updateImageSet,
  toggleOverlayVisibility,
})(UploadFromAssets);
