import React, { useState } from "react";
import styles from "./RegisterForm.module.css";
import AccountInfo from "./AccountInfo";
import BusinessInfo from "./BusinessInfo";
import axios from "axios";
import { storeLoginInfo } from "../../auth";
import { useFormik } from "formik";

const RegisterForm = () => {
  const formik = useFormik({
    initialValues: {
      businessName: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      rePassword: "",
      phone: "",
      website: "",
      typeOfBusinessOptions: {
        retail: false,
        seller: false,
        wholesale: false,
        manufacturer: false,
        brand: false,
        influencer: false,
        contentCreator: false
      },
      usageOptions: {
        buyContent: false,
        sellContent: false,
        distributeContent: false,
        bleexyConnect: false,
        curateContent: false,
        becomeBKeeper: false,
        startBGuild: false
      },
      hearAboutOptions: {
        google: false,
        facebook: false,
        linkedin: false,
        bleexyWebsite: false,
        events: false
      },
      other: ""
    },

    validate: values => {
      const errors = {};
      if (activeTab.next) {
        if (!values.email) {
          errors.email = "This field is required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }
        if (!values.password) {
          errors.password = "This field is required";
        }
        if (!values.rePassword) {
          errors.rePassword = "This field is required";
        } else if (
          values.password !== values.rePassword &&
          values.password !== ""
        ) {
          errors.equalPasswords = "Passwords do not match";
        }
        if (!values.businessName) {
          errors.businessName = "This field is required";
        }
        if (!values.firstName) {
          errors.firstName = "This field is required";
        }
        if (!values.lastName) {
          errors.lastName = "This field is required";
        }
      }

      if (activeTab.previous) {
        const { typeOfBusinessOptions, usageOptions } = values;
        const typeOfBusinessOptionsError = Object.keys(
          typeOfBusinessOptions
        ).map(key => {
          return typeOfBusinessOptions[key];
        });

        if (!typeOfBusinessOptionsError.includes(true)) {
          errors.typeOfBusinessOptionsError =
            "Please check at least one option";
        }

        const usageOptionsError = Object.keys(usageOptions).map(key => {
          return usageOptions[key];
        });

        if (!usageOptionsError.includes(true)) {
          errors.usageOptionsError = "Please check at least one option";
        }
      }

      return errors;
    },

    onSubmit: (values, { setErrors, setSubmitting }) => {
      axios
        .post(`/backend/register`, values)
        .then(res => {
          storeLoginInfo(res.data);
          document.location = "/verify-account";
        })
        .catch(err => {
          console.log(err);
        });
    }
  });

  const [activeTab, setActiveTab] = useState({
    next: true,
    previous: false
  });

  const handleActiveTab = e => {
    const name = e.target.name;

    if (name === "next") {
      (async () => {
        await formik.setTouched({
          email: true,
          password: true,
          rePassword: true,
          businessName: true,
          firstName: true,
          lastName: true
        });
        const errors = await formik.validateForm();
        if (Object.keys(errors).length === 0) {
          setActiveTab({ ...activeTab, next: false, previous: true });
        }
      })();
    } else if (name === "previous") {
      setActiveTab({ ...activeTab, next: true, previous: false });
    }
  };

  const handleCheckbox = (e, objectName) => {
    const { setValues } = formik;
    const name = e.target.name;

    setValues({
      ...values,
      [objectName]: {
        ...values[objectName],
        [name]: !values[objectName][name]
      }
    });
  };

  const {
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    errors,
    touched
  } = formik;

  return (
    <div className="box-wide">
      <div className={styles.container}>
        <div>
          <h1 className={styles.title}>Create your account</h1>
          <p className={styles.subtitle}>
            In order to create your account we need some information about your
            business.
          </p>
        </div>
        <div className={styles.tabs}>
          <div className={styles.activeTab}>
            <button className={styles.activeBtn}>1.Account information</button>
          </div>
          <div
            className={
              activeTab.previous ? styles.activeTab : styles.notActiveTab
            }
          >
            <button
              className={
                activeTab.previous ? styles.activeBtn : styles.notActiveBtn
              }
            >
              2.Business information
            </button>
          </div>
        </div>
        <div className={styles.tabContentContainer}>
          {activeTab.next ? (
            <AccountInfo
              errors={errors}
              touched={touched}
              userInput={values}
              handleChange={handleChange}
            />
          ) : (
            <BusinessInfo
              errors={errors}
              touched={touched}
              userInput={values}
              handleCheckbox={handleCheckbox}
              handleChange={handleChange}
            />
          )}
        </div>
        <div className={styles.navBtnsContainer}>
          {activeTab.next ? (
            <button
              className={styles.nextBtn}
              name="next"
              onClick={handleActiveTab}
            >
              Next
            </button>
          ) : (
            <div className={styles.btnsWrapper}>
              <button
                className={styles.prevBtn}
                name="previous"
                onClick={handleActiveTab}
              >
                Previous
              </button>

              <button
                className={isSubmitting ? styles.loadingBtn : styles.submitBtn}
                name="create"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Create Account
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default RegisterForm;
