import React from "react";
import styles from "./AddProduct.module.css";
import { connect } from "react-redux";

import { useFormik } from "formik";
import { addNewProduct } from "../../../redux/actions/productsActions";
import Save from "../../../components/Save";
import ProductGeneralFields from "./ProductGeneralFields";
import Button from "../../../components/generic/Button";

const AddProduct = ({ toggleAddProduct, addNewProduct, categories }) => {
  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      upc: "",
      categories: [],
    },
    validate: values => {
      const errors = {};
      if (!values.name) {
        errors.name = "This field is required";
      }

      if (!values.upc) {
        errors.upc = "This field is required";
      }

      if (!values.categories) {
        errors.categories = "This field is required";
      }

      return errors;
    },

    onSubmit: values => {
      const categoryIds = values.categories.map(category => category.value);
      addNewProduct({ ...values, categories: categoryIds });
    },
    enableReinitialize: true,
  });

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
  } = formik;

  const onChange = e => {
    handleChange(e);
  };
  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <ProductGeneralFields
          touched={touched}
          values={values}
          errors={errors}
          categories={categories}
          setFieldValue={setFieldValue}
          onChange={onChange}
        />
        <div className={styles.save}>
          <Save
            text={"Save product"}
            loading={isSubmitting}
            active={
              values.name &&
              values.upc &&
              values.categories &&
              values.categories.length !== 0
            }
          >
            <Button handleClick={toggleAddProduct} greyText text="Cancel">
              Cancel
            </Button>
          </Save>
        </div>
      </form>
    </div>
  );
};

export default connect(null, {
  addNewProduct,
})(AddProduct);
