import React from "react";
import styles from "./ImageDescription.module.css";

const ImageDescription = props => {
  return (
    <div>
      <h3 className={styles.title}>Image Description</h3>
      <p className={styles.description}>{props.description}</p>
    </div>
  );
};

export default ImageDescription;
