import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { getAuthHeader } from "../../../auth";
import Input from "../../../components/generic/Input";
import Error from "../../../components/generic/Error";
import CheckboxInput from "../../../components/generic/Checkbox";
import styles from "./TypesOfMedia.module.css";
import { connect } from "react-redux";

import { getTypesOfMedia, updateTypesOfMedia } from "../../../redux/actions/userProfileActions";

const TypesOfMedia = (props) => {
  const { typesOfMedia, getTypesOfMedia, updateTypesOfMedia } = props;

  const formik = useFormik({
    initialValues: {
      essential: typesOfMedia.essential ? true : false,
      taxonomy: typesOfMedia.taxonomy ? true : false,
      supplier: typesOfMedia.supplier ? true : false,
      digitalAssets: typesOfMedia.digitalAssets ? true : false,
      images: typesOfMedia.images ? true : false,
      video: typesOfMedia.video ? true : false,
      docs: typesOfMedia.docs ? true : false,
      localizedInformation: typesOfMedia.localizedInformation ? true : false,
      translations: typesOfMedia.translations ? true : false,
      trends: typesOfMedia.trends ? true : false,
      salesInformation: typesOfMedia.salesInformation ? true : false,
      consumer: typesOfMedia.consumer ? true : false,
      reviews: typesOfMedia.reviews ? true : false,
      testimonials: typesOfMedia.testimonials ? true : false,
      marketingData: typesOfMedia.marketingData ? true : false,
      keywords: typesOfMedia.keywords ? true : false,
      seo: typesOfMedia.seo ? true : false,
      designSpecifications: typesOfMedia.designSpecifications ? true : false,
      styleSheets: typesOfMedia.styleSheets ? true : false,
      designNotes: typesOfMedia.designNotes ? true : false,
      assemblyInstructions: typesOfMedia.assemblyInstructions ? true : false,
      technicalSpecifications: typesOfMedia.technicalSpecifications ? true : false
    },

    onSubmit: values => {
      const payload = {
        'typeOfmedia' : values
      }
      updateTypesOfMedia(payload);
      getTypesOfMedia();
    }
  });

  const {
    values,
    initialValues,
    isSubmitting,
    handleChange,
    handleSubmit,
    errors,
    touched
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <section className={styles.sectionGrey}>
        <div className={`${styles.businessInfoFormInnerContainer}`}>
            <p className={styles.subtitle}>Options for types of media</p>
            <span className={styles.subtitleWrapper}>
              <p className={styles.mandatory}>Select the  types of media you will be using</p>
            </span>
          <div className={`${styles.topBox} column-grid`}>
            <div className="box-quarter">
              <div className={styles.inputContainer}>
                <CheckboxInput
                  id="essential"
                  name="essential"
                  label={<strong>Essential product data</strong>}
                  value={values.essential}
                  checked={values.essential}
                  handleChange={handleChange}
                />
                <p className={styles.essentials}>( SKU, UPC, Title, Manufacturer, Brand, Short description, Description, Weight, Color, etc )</p>
              </div>
            </div>
            <div className="box-quarter">
              <CheckboxInput
                id="digitalAssets"
                name="digitalAssets"
                label={<strong>Digital assets</strong>}
                value={values.digitalAssets}
                checked={values.digitalAssets}
                handleChange={handleChange}
              />
              <div className={styles.innerCheckboxContainer}>
                <CheckboxInput
                  id="images"
                  name="images"
                  label={"Images"}
                  value={values.images}
                  checked={values.digitalAssets ? true : (values.images)}
                  handleChange={handleChange}
                />
                <CheckboxInput
                  id="video"
                  name="video"
                  label={"Video"}
                  value={values.digitalAssets ? true : (values.video)}
                  checked={values.digitalAssets ? true : (values.video)}
                  handleChange={handleChange}
                />
                <CheckboxInput
                  id="docs"
                  name="docs"
                  label={"Docs"}
                  value={values.digitalAssets ? true : (values.docs)}
                  checked={values.digitalAssets ? true : (values.docs)}
                  handleChange={handleChange}
                />
              </div>
            </div>

            <div className="box-quarter">
              <CheckboxInput
                id="salesInformation"
                name="salesInformation"
                label={<strong>Sales Information</strong>}
                value={values.salesInformation}
                checked={values.salesInformation}
                handleChange={handleChange}
              />
              <div className={styles.innerCheckboxContainer}>
                <CheckboxInput
                  id="consumer"
                  name="consumer"
                  label={"Consumer"}
                  value={values.consumer}
                  checked={values.salesInformation ? true : (values.consumer)}
                  handleChange={handleChange}
                />
                <CheckboxInput
                  id="reviews"
                  name="reviews"
                  label={"Reviews"}
                  value={values.salesInformation ? true : (values.reviews)}
                  checked={values.salesInformation ? true : (values.reviews)}
                  handleChange={handleChange}
                />
                <CheckboxInput
                  id="testimonials"
                  name="testimonials"
                  label={"Testimonials"}
                  value={values.salesInformation ? true : (values.testimonials)}
                  checked={values.salesInformation ? true : (values.testimonials)}
                  handleChange={handleChange}
                />
              </div>
            </div>

            <div className="box-quarter">
              <CheckboxInput
                id="designSpecifications"
                name="designSpecifications"
                label={<strong>Design Specifications</strong>}
                value={values.designSpecifications}
                checked={values.designSpecifications}
                handleChange={handleChange}
              />
              <div className={styles.innerCheckboxContainer}>
                <CheckboxInput
                  id="styleSheets"
                  name="styleSheets"
                  label={"Style sheets"}
                  value={values.styleSheets}
                  checked={values.designSpecifications ? true : (values.styleSheets)}
                  handleChange={handleChange}
                />
                <CheckboxInput
                  id="designNotes"
                  name="designNotes"
                  label={"Design Notes"}
                  value={values.designSpecifications ? true : (values.designNotes)}
                  checked={values.designSpecifications ? true : (values.designNotes)}
                  handleChange={handleChange}
                />
                <CheckboxInput
                  id="assemblyInstructions"
                  name="assemblyInstructions"
                  label={"Assembly Instructions"}
                  value={values.designSpecifications ? true : (values.assemblyInstructions)}
                  checked={values.designSpecifications ? true : (values.assemblyInstructions)}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.bottomBox} column-grid`}>
            <div className="box-quarter">
              <div className={styles.taxonomySupplier}>
                <CheckboxInput
                  id="taxonomy"
                  name="taxonomy"
                  label={<strong>Taxonomy and relationships</strong>}
                  value={values.taxonomy}
                  checked={values.taxonomy}
                  handleChange={handleChange}
                />
                <CheckboxInput
                  id="supplier"
                  name="supplier"
                  label={"Supplier and manufacturer data"}
                  value={values.supplier}
                  checked={values.supplier}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className="box-quarter">
              <CheckboxInput
                id="localizedInformation"
                name="localizedInformation"
                label={<strong>Localized Information</strong>}
                value={values.localizedInformation}
                checked={values.localizedInformation}
                handleChange={handleChange}
              />
              <div className={styles.innerCheckboxContainer}>
                <CheckboxInput
                  id="translations"
                  name="translations"
                  label={"Translations"}
                  value={values.localizedInformation ? true : (values.translations)}
                  checked={values.localizedInformation ? true : (values.translations)}
                  handleChange={handleChange}
                />
                <CheckboxInput
                  id="trends"
                  name="trends"
                  label={"Trends"}
                  value={values.localizedInformation ? true : (values.trends)}
                  checked={values.localizedInformation ? true : (values.trends)}
                  handleChange={handleChange}
                />
              </div>
            </div>

            <div className="box-quarter">
              <CheckboxInput
                id="marketingData"
                name="marketingData"
                label={<strong>Marketing Data</strong>}
                value={values.marketingData}
                checked={values.marketingData}
                handleChange={handleChange}
              />
              <div className={styles.innerCheckboxContainer}>
                <CheckboxInput
                  id="keywords"
                  name="keywords"
                  label={"Keywords"}
                  value={values.marketingData ? true : (values.keywords)}
                  checked={values.marketingData ? true : (values.keywords)}
                  handleChange={handleChange}
                />
                <CheckboxInput
                  id="seo"
                  name="seo"
                  label={"SEO elements"}
                  value={values.marketingData ? true : (values.seo)}
                  checked={values.marketingData ? true : (values.seo)}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className="box-quarter">
              <CheckboxInput
                id="technicalSpecifications"
                name="technicalSpecifications"
                label={<strong>Technical specifications</strong>}
                value={values.technicalSpecifications}
                checked={values.technicalSpecifications}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.sectionSubmit}>
        <input
          type="submit"
          value="Save Changes"
          className={!(JSON.stringify(initialValues) === JSON.stringify(values)) ? styles.active : ""}
        />
      </section>
    </form>
  )
};

const mapStateToProps = state => {
  return {
    user: state.userProfile.user,
  };
};

export default connect(mapStateToProps, {
  updateTypesOfMedia,
  getTypesOfMedia
})(TypesOfMedia)