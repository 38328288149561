import React from "react";
import styles from "./ValidatedListRow.module.css";
import FinalRating from "../components/FinalRating";

const ValidatedListRow = ({
  name,
  upc,
  categories,
  type,
  setLength,
  rating,
  finalRating,
}) => {
  const _renderContentType = text => (
    <div className={styles.contentType}>
      <img
        className={styles.icon}
        src={require("../../../static/svg/imageStack.svg")}
      />
      {text}
    </div>
  );

  return (
    <div className={styles.validatedListRow}>
      <div className={styles.item}>{name}</div>
      <div className={styles.item}>{upc}</div>
      <div className={`${styles.item} ${styles.category}`}>
        {categories.map(
          (category, i) =>
            category.name + (i === categories.length - 1 ? "" : ", ")
        )}
      </div>
      <div className={styles.item}>{_renderContentType(type)}</div>
      <div className={styles.item}>
        {setLength} photo{setLength > 1 ? "s" : ""}
      </div>
      <div className={styles.item}>
        <FinalRating score={rating} />
      </div>
      <div className={styles.item}>
        <FinalRating score={finalRating} />
      </div>
    </div>
  );
};

export default ValidatedListRow;
