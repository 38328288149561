import React from "react";
import styles from "./ProductList.module.css";

import { connect } from "react-redux";
import {
  toggleExpandedProduct,
  selectProduct,
  selectImageSet,
  toggleIsMultiSelect,
  multiSelectProduct,
} from "../../../redux/actions/productsActions";
import { getProductsOrdered } from "../../../selectors";

import ImageSetState from "./ImageSetState";

import arrowRight from "../../../static/svg/arrow-right.svg";
import arrowDown from "../../../static/svg/arrow_down.svg";

import Checkbox from "../../../components/generic/Checkbox";

const ProductList = (props) => {
  const {
    toggleExpandedProduct,
    expandedProducts,
    latestProductList,
    imageSets,
    selectProduct,
    selectedProduct,
    selectImageSet,
    selectedImageSet,
    imageSetStates,
    isMultiSelect,

    selectedProductList,
    multiSelectProduct,
  } = props;

  return (
    <>
      {latestProductList.map((product) => {
        const isProductExpanded = expandedProducts[product.id];
        return (
          <div className={styles.product} key={product.id}>
            {isMultiSelect && (
              <>
                <div className={styles.checkbox}>
                  <Checkbox
                    checked={selectedProductList.includes(product.id)}
                    handleChange={() => {
                      multiSelectProduct(product.id);
                    }}
                  />
                </div>
              </>
            )}
            <div
              className={`${isProductExpanded ? styles.expanded : ""} ${
                styles.container
              }`}
            >
              <div className={styles.productNameContainer}>
                <img
                  onClick={() => toggleExpandedProduct(product.id)}
                  className={styles.arrowIcon}
                  src={isProductExpanded ? arrowDown : arrowRight}
                  alt=""
                />

                <p
                  onClick={() => selectProduct(product.id)}
                  className={`${
                    selectedProduct === product.id && styles.bold
                  } ${styles.productName}`}
                >
                  {product.name}
                </p>
              </div>

              <div
                onClick={() => selectProduct(product.id)}
                className={`${selectProduct === product.id && styles.bold} ${
                  styles.upcContainer
                }`}
              >
                {product.upc && (
                  <p className={styles.upcText}>{`UPC:${product.upc}`}</p>
                )}
              </div>
            </div>
            {isProductExpanded && (
              <div className={styles.imageSetsContainer}>
                {product.imageSets.map((set) => {
                  return (
                    <div key={set} className={styles.imageSetContainer}>
                      {imageSets && (
                        <p
                          onClick={() => selectImageSet(set, product.id)}
                          className={`${
                            selectedImageSet === set && styles.bold
                          } ${styles.imageSetName}`}
                        >
                          {imageSets[set].name}
                        </p>
                      )}
                      <ImageSetState
                        icon={`${imageSets[set].state}`}
                        state={imageSetStates[imageSets[set].state]}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
      <div className={styles.multiSelectActions}></div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    latestProductList: getProductsOrdered(state),
    selectedProduct: state.products.selectedProduct,
    expandedProducts: state.products.expandedProducts,
    imageSets: state.products.imageSets,
    selectedImageSet: state.products.selectedImageSet,
    imageSetStates: state.products.imageSetStates,
    isMultiSelect: state.products.isMultiSelect,
    selectedProductList: state.products.selectedProductList,
  };
};

export default connect(mapStateToProps, {
  toggleExpandedProduct,
  selectProduct,
  selectImageSet,
  toggleIsMultiSelect,
  multiSelectProduct,
})(ProductList);
