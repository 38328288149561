import React, { useEffect } from "react";
import styles from "./index.module.css";
import { connect } from "react-redux";
import {
  addNewSnippet,
  deleteSnippet,
  selectSnippet,
  deselectSnippet,
  getSnippetList,
} from "../../redux/actions/retailersActions";
import { useFormik } from "formik";
import Page from "../../layouts/Page";
import Loader from "../../components/generic/Loader";
import Input from "../../components/generic/Input";
import Error from "../../components/generic/Error";

import IconWithText from "../../components/IconWithText";

import snippetIcon from "../../static/svg/snippet_icon.svg";
import deleteIcon from "../../static/svg/deleteSmall.svg";
import addIcon from "../../static/svg/add.svg";

const Retailers = props => {
  const {
    snippets,
    selectedSnippet,
    addNewSnippet,
    deleteSnippet,
    selectSnippet,
    deselectSnippet,
    getSnippetList,
    loading,
  } = props;

  useEffect(() => {
    getSnippetList();
  }, []);

  const formik = useFormik({
    initialValues: {
      website: "",
    },

    validate: values => {
      const errors = {};
      if (!values.website) {
        errors.website = "This field is required";
      }
      return errors;
    },

    onSubmit: (values, { setErrors }) => {
      addNewSnippet({ website: values.website });
    },
  });

  const snippetCodeId = "snippet-code";

  const handleChange = e => {
    const { setValues, setSubmitting } = formik;
    setValues({ website: e.target.value });
    if (e.target.value === selectedSnippet.website) {
      setSubmitting(true);
    } else {
      setSubmitting(false);
    }
  };

  const handleDeselectSnippet = () => {
    const { resetForm } = formik;
    deselectSnippet();
    resetForm();
  };

  const handleSelectSnippet = id => {
    const { setValues, setSubmitting } = formik;
    setValues({ website: snippets[id].website });
    setSubmitting(true);
    selectSnippet(id);
  };

  const handleDeleteSnippet = id => {
    const { resetForm } = formik;
    if (window.confirm("Are you sure?")) {
      deleteSnippet(id);
      resetForm();
    }
  };

  const handleCopyCode = () => {
    const range = window.document.createRange();
    range.selectNodeContents(window.document.getElementById(snippetCodeId));

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand("copy");
  };

  const { values, isSubmitting, handleSubmit, errors, touched } = formik;
  return (
    <Page>
      {loading.length > 0 && <Loader />}
      <div className={styles.hero}>
        <h2>Retailers</h2>
      </div>
      <div className={styles.containerBackground}>
        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.headerLeft}>
              <h3 className={styles.title}>Authorized websites</h3>
              <p className={styles.subtitle}>
                This are the websites you have authorized Bleexy content for.
              </p>
            </div>
            <div className={styles.list}>
              {Object.values(snippets).map(snippet => {
                return (
                  <div
                    key={snippet.id}
                    className={
                      snippet.id === selectedSnippet
                        ? styles.activewebsiteContainer
                        : styles.websiteContainer
                    }
                  >
                    <p
                      onClick={() => handleSelectSnippet(snippet.id)}
                      className={styles.website}
                    >
                      {snippet.website}
                    </p>
                    <div onClick={() => handleDeleteSnippet(snippet.id)}>
                      <img
                        style={{ cursor: "pointer" }}
                        src={deleteIcon}
                        alt=""
                      />
                    </div>
                  </div>
                );
              })}

              <div
                className={
                  !selectedSnippet
                    ? styles.activeAddIconContainer
                    : styles.addIconContainer
                }
              >
                <IconWithText
                  icon={addIcon}
                  handleClick={handleDeselectSnippet}
                  bold
                  text="Authorize new website"
                />
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.headerRight}>
              <h3 className={styles.title}>Authorize new website</h3>
              <p className={styles.subtitle}>
                We will generate a new code snippet for every new website you
                authorize.
              </p>
            </div>
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.inputWrap}>
                <Input
                  name="website"
                  type="text"
                  value={values.website}
                  placeholder="Enter Website"
                  handleChange={handleChange}
                />
                {errors.website && touched.website && (
                  <Error message={errors.website} />
                )}
              </div>
              <Input
                isDisabled={isSubmitting}
                round
                isSubmitBtn
                type="submit"
                value="Authorize"
              />
            </form>
            <div className={styles.subHeader}>
              <h3 className={styles.title}>Add code to website</h3>
              <p className={styles.subtitle}>
                Add the UPC in the code and copy this snippet to your website.
              </p>
            </div>
            <div className={styles.snippetBackground}>
              {selectedSnippet ? (
                <div className={styles.snippetContainer}>
                  <p id={snippetCodeId} className={styles.generatedCode}>
                    {snippets[selectedSnippet].code}
                  </p>
                </div>
              ) : (
                <div className={styles.snippetContainer}>
                  <div className={styles.snippetIcon}>
                    <img src={snippetIcon} alt="" />
                  </div>
                  <p className={styles.snippetText}>
                    Authorize a website above to generate code.
                  </p>
                </div>
              )}
            </div>
            {selectedSnippet && (
              <button onClick={handleCopyCode} className={styles.copyCodeBtn}>
                Copy code
              </button>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    snippets: state.retailers.snippets,
    selectedSnippet: state.retailers.selectedSnippet,
    loading: state.loading.loading,
  };
};

export default connect(mapStateToProps, {
  addNewSnippet,
  deleteSnippet,
  deselectSnippet,
  selectSnippet,
  getSnippetList,
})(Retailers);
