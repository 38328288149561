import React from "react";
import styles from "./ChangePasswordForm.module.css";
import Input from "./generic/Input";
import Error from "./generic/Error";
import { Link } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import { getAuthHeader } from "../auth";

const ChangePasswordForm = props => {
  const { toggleChangePasswordForm } = props;
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
    },

    validate: values => {
      const errors = {};
      if (!values.currentPassword) {
        errors.currentPassword = "This field is required";
      }
      if (!values.newPassword) {
        errors.newPassword = "This field is required";
      }
      if (!values.reNewPassword) {
        errors.reNewPassword = "This field is required";
      } else if (
        values.newPassword !== values.reNewPassword &&
        values.newPassword !== ""
      ) {
        errors.equalPasswords = "Passwords do not match";
      }
      return errors;
    },

    onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
      axios
        .put(
          "backend/users/me",
          {
            oldPassword: values.currentPassword,
            newPassword: values.newPassword,
          },
          getAuthHeader()
        )
        .then(() => {
          toggleChangePasswordForm();
        })
        .catch(err => {
          setErrors({ backendError: err.message });
        })
        .finally(() => {
          setSubmitting(false);
          resetForm();
        });
    },
  });

  const {
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    errors,
    touched,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.oldPasswordContainer}>
        <h3 className={styles.title}>Change Password</h3>
        <div>
          <div className={styles.inputWrapper}>
            <Input
              name="currentPassword"
              id="currentPassword"
              type="password"
              value={values.currentPassword}
              handleChange={handleChange}
              placeholder="Enter old password"
            />
            {errors.currentPassword && touched.currentPassword && (
              <Error message={errors.currentPassword} />
            )}
          </div>
        </div>
        <div className={styles.forgotPasswordContainer}>
          {/* <Link to="/reset-password/request" className={styles.forgotPassLink}>
            Forgot your password?
          </Link> */}
        </div>
      </div>
      <div className={styles.dots} />
      <div className={styles.newPaswordContainer}>
        <div className={styles.inputWrapper}>
          <Input
            name="newPassword"
            id="newPassword"
            type="password"
            value={values.newPassword}
            handleChange={handleChange}
            placeholder="Enter new password"
          />
          {errors.newPassword && touched.newPassword && (
            <Error message={errors.newPassword} />
          )}
        </div>
        <div className={styles.inputWrapper}>
          <Input
            name="reNewPassword"
            id="reNewPassword"
            type="password"
            value={values.reNewPassword}
            handleChange={handleChange}
            placeholder="Retype new password"
          />
          {errors.reNewPassword && touched.reNewPassword && (
            <Error message={errors.reNewPassword} />
          )}
          {errors.equalPasswords && touched.reNewPassword && (
            <Error message={errors.equalPasswords} />
          )}
          {errors.backendError && <Error message={errors.backendError} />}
        </div>
      </div>
      <div className={styles.submitContainer}>
        <input
          className={`${isSubmitting ? styles.loading : styles.saveBtn}`}
          type="submit"
          value="Save password"
        />
        <button onClick={toggleChangePasswordForm} className={styles.cancelBtn}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ChangePasswordForm;
