import React from "react";
import { connect } from "react-redux";
import { removeNotification } from "../../redux/actions/notificationsActions";
import styles from "./index.module.css";
import Notification from "./Notification";

const Notifications = ({ removeNotification, list }) => {
  return (
    <div className={styles.notifications}>
      {list.map((notification) => (
        <Notification
          key={notification.id}
          data={notification}
          onRemove={removeNotification}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.notifications.list,
  };
};

export default connect(mapStateToProps, {
  removeNotification,
})(Notifications);
