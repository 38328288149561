import React from "react";
import styles from "./Products.module.css";
import { connect } from "react-redux";
import {
  toggleExpandedProduct,
  displayImageSetForm,
  displayEditProductForm,
} from "../redux/actions/productsActions";
import arrowRight from "../static/svg/arrow-right.svg";
import arrowDown from "../static/svg/arrow_down.svg";
import IconWithText from "./IconWithText";
import NewImageSetForm from "./NewImageSetForm";
import EditProductForm from "./EditProductForm";
import ImageSets from "./ImageSets";
import addIcon from "../static/svg/add.svg";
import editIcon from "../static/svg/editIcon.svg";
import { getProductsOrdered } from "../selectors";

const Products = props => {
  const {
    expandedProducts,
    latestProductList,
    isProductFormVisible,
    toggleExpandedProduct,
    displayImageSetForm,
    displayEditProductForm,
    imageSetForm,
    editProductForm,
    isGrayedOut,
  } = props;

  const shouldNotBeFaded = id => {
    return imageSetForm === id || editProductForm === id;
  };

  return (
    <React.Fragment>
      {latestProductList.map(product => {
        const isProductExpanded = expandedProducts[product.id];

        return (
          <div key={product.id}>
            <div
              className={`${
                isGrayedOut && !shouldNotBeFaded(product.id) && styles.faded
              } ${styles.productContainer} ${
                isGrayedOut && styles.clickDisabled
              }`}
            >
              <IconWithText
                icon={isProductExpanded ? arrowDown : arrowRight}
                handleClick={() =>
                  !isGrayedOut && toggleExpandedProduct(product.id)
                }
                regularGrey
                clickDisabled={isGrayedOut}
                text={product.name}
              />
              {!isProductFormVisible && editProductForm !== product.id && (
                <div className={styles.upcContainer}>
                  {!product.upc ? (
                    <button
                      onClick={() =>
                        !isGrayedOut && displayEditProductForm(product.id)
                      }
                      className={`${isGrayedOut && styles.clickDisabled} ${
                        styles.addUpc
                      }`}
                    >
                      +Add UPC
                    </button>
                  ) : (
                    <p className={styles.upcText}>{`UPC:${product.upc}`}</p>
                  )}
                  <div
                    onClick={() =>
                      !isGrayedOut && displayEditProductForm(product.id)
                    }
                    className={styles.editIcon}
                  >
                    <img src={editIcon} alt="" />
                  </div>
                </div>
              )}
            </div>
            <div className={styles.imageSetContainer}>
              {isProductExpanded && !imageSetForm && (
                <div
                  className={styles.addSetBtn}
                  onClick={() => displayImageSetForm(product.id)}
                >
                  <IconWithText
                    clickDisabled={isGrayedOut}
                    icon={addIcon}
                    bold
                    text="Create New Set"
                  />
                </div>
              )}

              {isProductExpanded && imageSetForm && (
                <div className={styles.imageSetFormContainer}>
                  <NewImageSetForm productId={product.id} />
                </div>
              )}

              {isProductExpanded && (
                <div
                  className={`${isGrayedOut && styles.faded} ${
                    styles.imageSetsContainer
                  }`}
                >
                  <ImageSets clickDisabled={isGrayedOut} product={product} />
                </div>
              )}
            </div>
            {editProductForm === product.id && (
              <div className={styles.editProductContainer}>
                <EditProductForm product={product} />
              </div>
            )}
          </div>
        );
      })}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    isProductFormVisible: state.products.isProductFormVisible,
    latestProductList: getProductsOrdered(state),
    imageSetForm: state.products.imageSetForm,
    editProductForm: state.products.editProductForm,
    expandedProducts: state.products.expandedProducts,
  };
};

export default connect(mapStateToProps, {
  toggleExpandedProduct,
  displayImageSetForm,
  displayEditProductForm,
})(Products);
