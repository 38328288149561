import React from "react";
import styles from "./NewProductForm.module.css";
import { connect } from "react-redux";
import {
  toggleNewProductForm,
  addNewProduct,
} from "../redux/actions/productsActions";

import { useFormik } from "formik";
import Input from "./generic/Input";
import Select from "react-select";
import Error from "./generic/Error";

const NewProductForm = props => {
  const {
    addNewProduct,
    toggleNewProductForm,
    selectedImages,
    categories,
  } = props;

  const formik = useFormik({
    initialValues: {
      name: "",
      upc: "",
      imageSets: [
        {
          name: "",
          imageIds: [],
        },
      ],
      categories: [],
    },

    validate: values => {
      const errors = {};
      if (!values.name) {
        errors.name = "Product name is required";
      }
      if (!values.imageSets[0].name) {
        errors.imageSet[0].name = "Image set name is required";
      }

      if (!values.categories || values.categories.length === 0) {
        errors.categories = "This field is required";
      }

      return errors;
    },

    onSubmit: values => {
      values.categories = values.categories.map(category => category.value);
      values.imageSets[0].imageIds = selectedImages;
      addNewProduct(values);
    },
  });
  const {
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.inputsContainer}>
        <div className={styles.productContainer}>
          <p className={styles.label}>Create New Product</p>
          <div className={styles.inputWrap}>
            <Input
              handleChange={handleChange}
              name="name"
              type="text"
              grey
              value={values.name}
              placeholder="New Product name"
            />
            {errors.name && touched.name && (
              <p className={styles.error}>{errors.name}</p>
            )}
          </div>

          <Input
            handleChange={handleChange}
            name="upc"
            type="text"
            value={values.upc}
            grey
            placeholder="UPC (you can add later)"
          />

          <Select
            isMulti
            name="categories"
            options={categories}
            className="assets-products-categories"
            classNamePrefix="assets-products-categories-select"
            autoBlur={false}
            closeMenuOnSelect={false}
            placeholder={"Select category"}
            onChange={selectedOption => {
              setFieldValue("categories", selectedOption);
            }}
          />
          {errors.categories && touched.categories && (
            <p className={styles.error}>{errors.categories}</p>
          )}
        </div>
        <div className={styles.setContainer}>
          <p className={styles.label}>Create New Set</p>
          <Input
            name="imageSets[0].name"
            type="text"
            handleChange={handleChange}
            value={values.imageSets[0].name}
            grey
            placeholder="Set name"
          />
          {errors.imageSetName && touched.imageSetName && (
            <p className={styles.error}>{errors.imageSetName}</p>
          )}
          {errors.failedSubmit && (
            <p className={styles.error}>{errors.failedSubmit}</p>
          )}
        </div>
      </div>
      <div>
        <input
          type="submit"
          disabled={isSubmitting}
          className={isSubmitting ? styles.loadingBtn : styles.saveBtn}
          value="Create"
        />
        <button onClick={toggleNewProductForm} className={styles.cancelBtn}>
          Cancel
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  return {
    selectedImages: state.assetsManagement.selectedImagesOrdered,
  };
};

export default connect(mapStateToProps, {
  toggleNewProductForm,
  addNewProduct,
})(NewProductForm);
