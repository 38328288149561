import {
  TRIGGER_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from "../actions/actionTypes";

export default (
  state = {
    list: [],
    id: 0,
  },
  { payload, type }
) => {
  switch (type) {
    case TRIGGER_NOTIFICATION:
      const id = state.id + 1;
      const notification = { ...payload, id };
      return {
        ...state,
        id: id,
        list: [...state.list, notification],
      };

    case REMOVE_NOTIFICATION:
      const newList = state.list.map((item) => Object.assign({}, item));
      const index = newList.findIndex((item) => item.id === payload);
      newList.splice(index, 1);

      return {
        ...state,
        list: newList,
      };

    default:
      return state;
  }
};
