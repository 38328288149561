import React, { useState, useEffect } from "react";
import styles from "./ImageSetDetails.module.css";

import { getImageSetImages } from "../../../selectors";

import { connect } from "react-redux";

import {
  returnToAllSetsView,
  deleteImageSet,
  removeImageFromImageSet,
  submitForValidation,
  togglePersonalUse,
  updateImageSet,
} from "../../../redux/actions/productsActions";

import ImageSetState from "./ImageSetState";
import Image from "./Image";
import Upload from "./Upload";
import Checkbox from "../../../components/generic/Checkbox";
import Button from "../../../components/generic/Button";

import ImageSetForm from "./ImageSetForm";

import backArrow from "../../../static/svg/orange_arrow.svg";
import deleteIcon from "../../../static/svg/deleteSmall.svg";
import editIcon from "../../../static/svg/editPencil.svg";

const ImageSetDetails = props => {
  const {
    selectedImageSet,
    selectedProduct,
    selectedImages,
    imageSets,
    imageSetImages,
    returnToAllSetsView,
    imageSetStates,
    submitForValidation,
    removeImageFromImageSet,
    deleteImageSet,
    togglePersonalUse,
    updateImageSet,
    loading,
  } = props;

  const [isFormVisible, setIsFormVisible] = useState(false);

  const selectedImagesCount = Object.values(selectedImages).filter(
    value => value
  ).length;

  const handleDeleteImages = () => {
    if (window.confirm("Are you sure?")) {
      removeImageFromImageSet(
        selectedProduct,
        selectedImageSet,
        selectedImages
      );
    }
  };

  const handleDeleteSet = () => {
    if (window.confirm("Are you sure?")) {
      deleteImageSet(selectedImageSet, selectedProduct);
    }
  };

  return (
    <>
      <div style={{ position: "relative", height: 0 }}>
        <button
          onClick={() => returnToAllSetsView()}
          className={styles.backToAllSetsBtn}
        >
          <img className={styles.backIcon} src={backArrow} alt="" />
          All sets
        </button>
      </div>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.imageSetDetails}>
            {!isFormVisible && (
              <div className={styles.status}>
                <ImageSetState
                  icon={`${imageSets[selectedImageSet].state}`}
                  state={imageSetStates[imageSets[selectedImageSet].state]}
                />
              </div>
            )}

            {!isFormVisible ? (
              <div className={styles.imageSetName}>
                <h2>{imageSets[selectedImageSet].name}</h2>
                {imageSets[selectedImageSet].state === "draft" && (
                  <img
                    onClick={() => setIsFormVisible(true)}
                    className={styles.editIcon}
                    src={editIcon}
                    alt=""
                  />
                )}
              </div>
            ) : (
              <ImageSetForm
                updateImageSet={updateImageSet}
                selectedProduct={selectedProduct}
                selectedImageSet={selectedImageSet}
                setIsFormVisible={setIsFormVisible}
                update
              />
            )}
          </div>
          {!isFormVisible && (
            <div className={styles.actionBtns}>
              {selectedImagesCount > 0 ? (
                <>
                  <div className={styles.selectedImagesInfo}>
                    <p className={styles.selectedImagesCountText}>
                      <span className={styles.selectedImagesCount}>
                        {selectedImagesCount}
                      </span>{" "}
                      {`image${selectedImagesCount > 1 ? "s" : ""} selected`}
                    </p>
                  </div>
                  <Button
                    handleClick={handleDeleteImages}
                    icon={deleteIcon}
                    round
                    text={`Delete Photo${selectedImagesCount > 1 ? "s" : ""}`}
                  />
                </>
              ) : (
                <>
                  <div
                    className={`${styles.actionBtn} ${styles.readyForMyOwnUse}`}
                  >
                    <Checkbox
                      id="readyForMyOwnUse"
                      label={"Ready for my own use"}
                      checked={imageSets[selectedImageSet].personalUse}
                      handleChange={() =>
                        togglePersonalUse({
                          selectedProduct,
                          selectedImageSet,
                          personalUse: imageSets[selectedImageSet].personalUse,
                        })
                      }
                    />
                  </div>

                  {imageSets[selectedImageSet].state === "draft" && (
                    <div className={styles.actionBtn}>
                      <Button
                        round
                        disabled={
                          imageSets[selectedImageSet].imageIds.length === 0
                        }
                        loading={loading.includes("submit_for_validation")}
                        backgroundYellow
                        text="Submit for validation"
                        handleClick={() =>
                          submitForValidation({
                            selectedProduct,
                            selectedImageSet,
                          })
                        }
                      />
                    </div>
                  )}
                  {imageSets[selectedImageSet].state === "draft" && (
                    <Button
                      handleClick={() => handleDeleteSet()}
                      icon={deleteIcon}
                      round
                      text="Delete Set"
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <div className={styles.content}>
          {imageSets[selectedImageSet].state === "draft" && (
            <div className={styles.uploadContainer}>
              <Upload />
            </div>
          )}
          <div className={styles.imagesWrapper}>
            <div className={styles.images}>
              {imageSetImages.map(image => {
                return (
                  <div key={image.id} className={styles.imageThumbail}>
                    <Image
                      data={image}
                      isDraft={imageSets[selectedImageSet].state === "draft"}
                      selectedProduct={selectedProduct}
                      imageSet={imageSets[selectedImageSet]}
                      selectedImages={selectedImages}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    products: state.products.products,
    imageSetImages: getImageSetImages(state),
    selectedImages: state.products.imageSetSelectedImages,
    imageSetStates: state.products.imageSetStates,
    loading: state.loading.loading,
  };
};

export default connect(mapStateToProps, {
  returnToAllSetsView,
  submitForValidation,
  removeImageFromImageSet,
  deleteImageSet,
  togglePersonalUse,
  updateImageSet,
})(ImageSetDetails);
