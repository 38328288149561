import {
  SET_VALIDATION_ITEM_SUCCESS,
  SET_RATING,
  RATING_SUBMITTED_SUCCESS,
  GET_VALIDATED_TASK_LIST,
} from "./actionTypes";
import axios from "axios";
import { getAuthHeader } from "../../auth";
import { startLoading, stopLoading } from "./loadingActions";

export const validatorsTabs = {
  ongoingTaks: "ONGOING_TASKS",
  completed: "COMPLETED",
};

export const getValidatedTaskList = () => (dispatch, getState, config) => {
  axios.get(`${config.apiUrl}/validations`, getAuthHeader()).then(res => {
    dispatch({
      type: GET_VALIDATED_TASK_LIST,
      payload: res.data,
    });
  });
};

export const getValidationTask = shouldSkip => (dispatch, getState, config) => {
  dispatch(startLoading("get_validation_task"));

  axios
    .get(
      `${config.apiUrl}/validations/next${shouldSkip ? "?skip=true" : ""}`,
      getAuthHeader()
    )
    .then(res => {
      dispatch({
        type: SET_VALIDATION_ITEM_SUCCESS,
        payload: res.data,
      });
      dispatch(stopLoading("get_validation_task"));
    })
    .catch(err => console.log(err));
};

export const setRating = rating => dispatch => {
  dispatch({
    type: SET_RATING,
    payload: rating,
  });
};

export const submitRating = (taskId, rating, comment) => (
  dispatch,
  getState,
  config
) => {
  dispatch(startLoading("submit_rating"));

  axios
    .post(
      `${config.apiUrl}/validations/${taskId}/rate`,
      { rating, comment },
      getAuthHeader()
    )
    .then(res => {
      dispatch({
        type: RATING_SUBMITTED_SUCCESS,
        payload: JSON.parse(res.config.data),
      });
      dispatch(stopLoading("submit_rating"));
    })
    .catch(err => {
      console.log(err);
      dispatch(stopLoading("submit_rating"));
    });
};
