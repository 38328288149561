import React from "react";
import styles from "./AssetsActions.module.css";
import Icon from "./Icon";
import Search from "./Search";
import { connect } from "react-redux";
import {
  toggleRightPanelClick,
  deleteImagesClick,
  searchImages,
} from "../redux/actions/assetsManagementActions";

import deleteIcon from "../static/svg/delete.svg";
import infoIcon from "../static/svg/info.svg";

const AssetsActions = props => {
  const {
    images,
    selectedImages,
    searchImages,
    toggleRightPanelClick,
    deleteImagesClick,
  } = props;

  const handleClick = () => {
    if (window.confirm("Are you sure?")) {
      deleteImagesClick(selectedImages);
    }
  };

  const search = e => {
    if (Object.keys(images).length === 0) {
      return;
    }
    const input = e.target.value.toLowerCase();
    searchImages({ input });
  };

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <div className={styles.searchWrapper}>
          <Search
            search={search}
            placeholder={
              "Search by keyword or select a label bellow  to filter images"
            }
          />
        </div>
        <div className={styles.icons}>
          <Icon handleClick={handleClick} icon={deleteIcon} />
          <Icon handleClick={toggleRightPanelClick} icon={infoIcon} />
        </div>
      </div>
      <div className={styles.tags}></div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedImages: state.assetsManagement.selectedImagesOrdered,
    images: state.assetsManagement.images,
  };
};

export default connect(mapStateToProps, {
  toggleRightPanelClick,
  deleteImagesClick,
  searchImages,
})(AssetsActions);
