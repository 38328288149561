import React, { useState } from "react";
import styles from "./ResetPasswordForm.module.css";
import axios from "axios";
import Input from "./generic/Input";
import Error from "./generic/Error";
import { useFormik } from "formik";

const ResetPasswordForm = () => {
  const formik = useFormik({
    initialValues: {
      email: ""
    },

    validate: values => {
      const errors = {};
      if (!values.email) {
        errors.email = "This field is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      return errors;
    },

    onSubmit: (values, { setErrors, setSubmitting }) => {
      axios
        .post(`/backend/auth/password-reset`, { email: values.email })
        .then(res => {
          if (res.data && res.data.token) {
            sendEmail(res.data.token);
            setToken(res.data.token);
          }
        })
        .catch(err => {
          setErrors({ invalidResetPassword: err.response.data.error });
          setSubmitting(false);
        });
    }
  });

  const [token, setToken] = useState(null);

  const sendEmail = token => {
    axios
      .post(`/backend/send-password-reset-email`, {
        email: values.email,
        token: token
      })
      .then(() => {})
      .catch(err => {
        console.log(err);
      });
  };

  const {
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    errors,
    touched
  } = formik;

  return (
    <div className={`box-wide`}>
      <div className={styles.container}>
        {!token ? (
          <h1 className={styles.title}>Forgot your password?</h1>
        ) : (
          <h1 className={styles.title}>Awesome!</h1>
        )}

        {token ? (
          <p className={styles.subtitle}>
            Please check your email and click on the link to reset your
            password.
          </p>
        ) : (
          <form className={styles.form} onSubmit={handleSubmit}>
            <p className={styles.subtitle}>
              Please enter the email you provided and we’ll send you a reset
              link!
            </p>

            <div className={styles.inputWrapper}>
              <Input
                name="email"
                id="email"
                type="email"
                value={values.email}
                handleChange={handleChange}
                placeholder="Type your email"
              />
              {errors.email && touched.email && (
                <Error message={errors.email} />
              )}
              {errors.invalidResetPassword && (
                <Error message={errors.invalidResetPassword} />
              )}
            </div>
            <div className={styles.requestContainer}>
              <Input
                type="submit"
                isDisabled={isSubmitting}
                isSubmitBtn={true}
                value="Reset Password"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordForm;
