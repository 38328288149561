import React from "react";
import styles from "./BusinessInfo.module.css";
import CheckboxInput from "../generic/Checkbox";
import Error from "../generic/Error";

const BusinessInfo = props => {
  const {
    typeOfBusinessOptions,
    usageOptions,
    hearAboutOptions,
    other
  } = props.userInput;

  const { handleCheckbox, handleChange, errors, touched } = props;

  return (
    <div className={`box-full`}>
      <div className="column-grid">
        <div className={`box-third ${styles.box}`}>
          <h3 className={styles.title}>Type of business</h3>
          <span className={styles.subtitleWrapper}>
            <p className={styles.subtitle}>
              (Mandatory. Check all that apply!)
            </p>
            {errors.typeOfBusinessOptionsError &&
              touched.typeOfBusinessOptions && (
                <Error
                  checkbox={true}
                  message={errors.typeOfBusinessOptionsError}
                />
              )}
          </span>
          <CheckboxInput
            id="retail"
            name="retail"
            checked={typeOfBusinessOptions.retail}
            label="Retail"
            handleChange={e => handleCheckbox(e, "typeOfBusinessOptions")}
          />
          <CheckboxInput
            id="seller"
            name="seller"
            checked={typeOfBusinessOptions.seller}
            label="3rd Paty Seller"
            handleChange={e => handleCheckbox(e, "typeOfBusinessOptions")}
          />
          <CheckboxInput
            id="wholesale"
            name="wholesale"
            checked={typeOfBusinessOptions.wholesale}
            label="Wholesale"
            handleChange={e => handleCheckbox(e, "typeOfBusinessOptions")}
          />
          <CheckboxInput
            id="manufacturer"
            name="manufacturer"
            checked={typeOfBusinessOptions.manufacturer}
            label="Manufacturer"
            handleChange={e => handleCheckbox(e, "typeOfBusinessOptions")}
          />
          <CheckboxInput
            id="brand"
            name="brand"
            checked={typeOfBusinessOptions.brand}
            label="Brand"
            handleChange={e => handleCheckbox(e, "typeOfBusinessOptions")}
          />
          <CheckboxInput
            id="influencer"
            name="influencer"
            checked={typeOfBusinessOptions.influencer}
            label="Influencer"
            handleChange={e => handleCheckbox(e, "typeOfBusinessOptions")}
          />
          <CheckboxInput
            id="contentCreator"
            name="contentCreator"
            checked={typeOfBusinessOptions.contentCreator}
            label="Content Creator"
            handleChange={e => handleCheckbox(e, "typeOfBusinessOptions")}
          />
        </div>
        <div className={`box-third ${styles.box}`}>
          <div>
            <h3 className={styles.title}>How would you use Bleexy?</h3>
            <span className={styles.subtitleWrapper}>
              <p className={styles.subtitle}>
                (Mandatory. Check all that apply!)
              </p>
              {errors.usageOptionsError && touched.usageOptions && (
                <Error checkbox={true} message={errors.usageOptionsError} />
              )}
            </span>
            <CheckboxInput
              id="buyContent"
              name="buyContent"
              checked={usageOptions.buyContent}
              label="Buy curated rich content"
              handleChange={e => handleCheckbox(e, "usageOptions")}
            />
            <CheckboxInput
              id="sellContent"
              name="sellContent"
              checked={usageOptions.sellContent}
              label="Sell high-quality content"
              handleChange={e => handleCheckbox(e, "usageOptions")}
            />
            <CheckboxInput
              id="distributeContent"
              name="distributeContent"
              checked={usageOptions.distributeContent}
              label="Distribute content"
              handleChange={e => handleCheckbox(e, "usageOptions")}
            />
            <CheckboxInput
              id="bleexyConnect"
              name="bleexyConnect"
              checked={usageOptions.bleexyConnect}
              label="Bleexy Connect (cloud based inventory management solution and marketplace integrator"
              handleChange={e => handleCheckbox(e, "usageOptions")}
            />
            <CheckboxInput
              id="curateContent"
              name="curateContent"
              checked={usageOptions.curateContent}
              label="Curate content for Bleexy"
              handleChange={e => handleCheckbox(e, "usageOptions")}
            />
            <CheckboxInput
              id="becomeBKeeper"
              name="becomeBKeeper"
              checked={usageOptions.becomeBKeeper}
              label="Become a Bleexy B-Keeper (Bleexy node)"
              handleChange={e => handleCheckbox(e, "usageOptions")}
            />
            <CheckboxInput
              id="startBGuild"
              name="startBGuild"
              checked={usageOptions.startBGuild}
              label="Start a Bleexy B-Guild (Bleexy group that creates and validates content for brands/product categories)"
              handleChange={e => handleCheckbox(e, "usageOptions")}
            />
          </div>
        </div>

        <div className={`box-third ${styles.box}`}>
          <h3 className={styles.title}>
            How did you hear about us? (check all that applies)
          </h3>
          <p className={styles.subtitle}>(Optional)</p>
          <CheckboxInput
            id="google"
            name="google"
            checked={hearAboutOptions.google}
            label="Google"
            handleChange={e => handleCheckbox(e, "hearAboutOptions")}
          />
          <CheckboxInput
            id="facebook"
            name="facebook"
            checked={hearAboutOptions.facebook}
            label="Facebook"
            handleChange={e => handleCheckbox(e, "hearAboutOptions")}
          />
          <CheckboxInput
            id="linkedin"
            name="linkedin"
            checked={hearAboutOptions.linkedin}
            label="Linkedin"
            handleChange={e => handleCheckbox(e, "hearAboutOptions")}
          />
          <CheckboxInput
            id="bleexyWebsite"
            name="bleexyWebsite"
            checked={hearAboutOptions.bleexyWebsite}
            label="Bleexy Website"
            handleChange={e => handleCheckbox(e, "hearAboutOptions")}
          />
          <CheckboxInput
            id="other"
            name="other"
            value={other}
            checked={other !== ""}
            label="Other"
            hasText={true}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessInfo;
