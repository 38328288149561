import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import Page from "../../layouts/Page";
import { connect } from "react-redux";

import {
  validatorsTabs,
  getValidationTask,
} from "../../redux/actions/validatorsActions";

import Header from "../../components/Header";
import Tabs from "../../components/Tabs";
import Tab from "../../components/Tab";
import ValidationItem from "./tabs/ValidationItem";
import { ONGOING_TASKS, COMPLETED } from "./components/tabs";
import ValidatedList from "./tabs/ValidatedList";
import InnerContainer from "../../layouts/InnerContainer";

import Spinner from "../../components/generic/Spinner";
import Loader from "../../components/generic/Loader";

const Validators = ({ getValidationTask, validationItem, loading }) => {
  const [activeTab, setActiveTab] = useState("ONGOING_TASKS");

  useEffect(() => {
    getValidationTask();
  }, []);

  const renderTab = activeTab => {
    switch (activeTab) {
      case ONGOING_TASKS: {
        return (
          <ValidationItem
            getValidationTask={getValidationTask}
            data={validationItem}
          />
        );
      }
      case COMPLETED:
        return <ValidatedList />;
      default:
        return (
          <ValidationItem
            getValidationTask={getValidationTask}
            data={validationItem}
          />
        );
    }
  };

  return (
    <Page>
      {!validationItem ? (
        <Spinner size="small" />
      ) : (
        <>
          {loading.length > 0 && <Loader />}
          <Header title="Content validation">
            <Tabs>
              <Tab
                text="Ongoing tasks"
                name={validatorsTabs.ongoingTaks}
                isActive={activeTab === validatorsTabs.ongoingTaks}
                tabClick={e => setActiveTab(e)}
              />
              <Tab
                text="Completed"
                name={validatorsTabs.completed}
                isActive={activeTab === validatorsTabs.completed}
                tabClick={e => setActiveTab(e)}
              />
            </Tabs>
          </Header>
          <div className={styles.content}>
            <InnerContainer>{renderTab(activeTab)}</InnerContainer>
          </div>{" "}
        </>
      )}
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    config: state.application,
    validationItem: state.validators.validationItem,
    loading: state.loading.loading,
  };
};

export default connect(mapStateToProps, {
  getValidationTask,
})(Validators);
