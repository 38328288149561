import React, { useEffect } from "react";
import styles from "./index.module.css";
import { connect } from "react-redux";
import {
  toggleRightPanelClick,
  fetchImages,
  fetchProducts,
} from "../../redux/actions/assetsManagementActions";

import { getImagesGroupedByDate } from "../../selectors";
import Page from "../../layouts/Page";
import Header from "../../components/Header";
import Tabs from "../../components/Tabs";
import AssetsImages from "../../components/AssetsImages";
import RightPanel from "../../components/RightPanel";
import UploadFiles from "../../components/UploadFiles";
import AssetsActions from "../../components/AssetsActions";

import Loader from "../../components/generic/Loader";

const AssetsManagement = ({
  imagesGroupedByDate,

  toggleRightPanelClick,
  isRightPanel,
  activeTab,
  fetchProducts,
  selectedImages,
  loading,
}) => {
  useEffect(() => {
    fetchProducts();
  }, []);
  
  return (

    <Page>
      {loading.length > 0 && <Loader />}
      <Header title={"Assets Management"}>
        <Tabs activeTab={activeTab} />
      </Header>
      <div className={styles.horizontalLine} />
      <div className={styles.rightSide}></div>
      <section className={styles.sectionGrey}>
        {isRightPanel && (
          <div className={styles.rightPanel}>
            <RightPanel handleClosePanel={toggleRightPanelClick} />
          </div>
        )}

        <div className={styles.innerContainer}>
          <div className={styles.assetsActionsContainer}>
            <AssetsActions />
          </div>
          <div className={styles.uploadContainer}>
            <UploadFiles />
          </div>

          <AssetsImages
            imagesGroupedByDate={imagesGroupedByDate}
            selectedImages={selectedImages}
          />
        </div>
      </section>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    imagesGroupedByDate: getImagesGroupedByDate(state),
    isRightPanel: state.assetsManagement.isRightPanel,
    activeTab: state.assetsManagement.activeTab,
    loading: state.loading.loading,
    selectedImages: state.assetsManagement.selectedImages,
  };
};

export default connect(mapStateToProps, {
  toggleRightPanelClick,
  fetchImages,
  fetchProducts,
})(AssetsManagement);
