import React from "react";
import { useFormik } from "formik";
import Search from "../../../components/Search";
import CheckboxInput from "../../../components/generic/Checkbox";
import styles from "./ContentCategories.module.css";
import { getCategories } from "../../../selectors";
import { connect } from "react-redux";
import {
  updateCategories,
  searchCategories,
} from "../../../redux/actions/userProfileActions";
import { isValidator } from "../../../auth";
import InnerPage from "../layout/InnerPage";
import Save from "../../../components/Save";

const ContentCategories = ({
  loading,
  categories,
  updateCategories,
  searchCategories,
}) => {
  const format = data => {
    const formattedCategories = {};
    data.map(category => {
      formattedCategories[category.id] = category.subscribed;
    });
    return formattedCategories;
  };

  const init = format(categories);

  const formik = useFormik({
    initialValues: init,

    onSubmit: values => {
      updateCategories(values);
    },
  });

  const { values, handleChange, handleSubmit } = formik;

  const search = e => {
    const input = e.target.value.toLowerCase();
    searchCategories({ input });
  };

  return (
    <InnerPage>
      <form onSubmit={handleSubmit}>
        {isValidator() && (
          <div className="column-grid">
            <div className={`box-half`}>
              <div className={styles.categoriesContainer}>
                <div className={styles.categoriesInnerWrapper}>
                  <p className={styles.title}>Content validation categories</p>
                  <p className={styles.mandatory}>
                    Select category for products to review
                  </p>
                  <Search
                    search={search}
                    placeholder={"Search by category name"}
                  />
                  {categories.length > 10 ? (
                    <div
                      className={`${styles.categories} ${styles.categoriesFull}`}
                    >
                      <div className={styles.categoriesListLeft}>
                        {categories
                          .slice(0, categories.length / 2)
                          .map(category => {
                            return (
                              <CheckboxInput
                                key={category.id}
                                id={category.id}
                                name={category.id}
                                label={category.name}
                                value={values[category.id]}
                                checked={values[category.id]}
                                handleChange={handleChange}
                              />
                            );
                          })}
                      </div>
                      <div>
                        {categories
                          .slice(categories.length / 2, categories.length)
                          .map(category => {
                            return (
                              <CheckboxInput
                                key={category.id}
                                id={category.id}
                                name={category.id}
                                label={category.name}
                                value={values[category.id]}
                                checked={values[category.id]}
                                handleChange={handleChange}
                              />
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    <div className={`${styles.categories}`}>
                      {categories.map(category => {
                        return (
                          <CheckboxInput
                            id={category.id}
                            name={category.id}
                            label={category.name}
                            value={values[category.id]}
                            checked={values[category.id]}
                            handleChange={handleChange}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.save}>
          <Save
            text={"Save Changes"}
            loading={loading}
            active={
              !(JSON.stringify(format(categories)) === JSON.stringify(values))
            }
          />
        </div>
      </form>
    </InnerPage>
  );
};

const mapStateToProps = state => {
  return {
    categories: getCategories(state),
    searchInput: state.userProfile.searchInput,
  };
};

export default connect(mapStateToProps, {
  updateCategories,
  searchCategories,
})(ContentCategories);
