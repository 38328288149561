import React from "react";
import styles from "./NoTasks.module.css";

import noTasks from "../../../static/svg/no_tasks.svg";

const NoTasks = ({ title, subtitle }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <img className={styles.icon} src={noTasks} alt="" />
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
    </div>
  );
};

export default NoTasks;
