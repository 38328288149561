import React from "react";
import styles from "./Navbar.module.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setActivePage } from "../redux/actions/navbarActions";
import axios from "axios";

import {
  isLoggedIn,
  isVerified,
  hasRoleAssigned,
  isCreator,
  isValidator,
  isConsumer,
} from "../auth";

import Logo from "./Logo";
import NavbarItem from "./NavbarItem";

const Navbar = props => {
  const { setActivePage } = props;

  const handleLogout = () => {
    axios
      .post(`/backend/logout`)
      .then(res => {
        if (res.status === 200) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          document.location = "/";
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      {isLoggedIn() && isVerified() ? (
        <div className={styles.backgroundLoggedIn}>
          <div className={styles.containerLoggedIn}>
            <div className={styles.itemsContainer}>
              <div className={styles.logo}>
                <Logo link="/" />
              </div>

              {hasRoleAssigned() ? (
                <>
                  {isConsumer() && (
                    <NavbarItem
                      text="Retailers"
                      url="/retailers"
                      icon="retailers"
                      setActivePage={setActivePage}
                    />
                  )}
                  {isCreator() && (
                    <>
                      <NavbarItem
                        text="Products"
                        url="/products"
                        icon="products"
                        setActivePage={setActivePage}
                      />
                      <NavbarItem
                        text="Assets Management"
                        url="/assets"
                        icon="assets"
                        setActivePage={setActivePage}
                      />
                    </>
                  )}
                  {isValidator() && (
                    <NavbarItem
                      text="Content Validation"
                      url="/validators"
                      icon="validation"
                      setActivePage={setActivePage}
                    />
                  )}
                </>
              ) : (
                ""
              )}
            </div>

            <div className={styles.userContainer}>
              <NavbarItem
                text="User Profile"
                url="/profile"
                icon="user"
                setActivePage={setActivePage}
              />
              <br />
              <button className={styles.authBtn} onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.backgroundNotLoggedIn}>
          <div className="box-wide">
            <div className={styles.containerNotLoggedIn}>
              <Logo link="/login" />
              <div className={styles.authContainer}>
                <Link to="/register" className={styles.authBtn}>
                  Register
                </Link>
                <span className={styles.spacer} />
                <Link to="/login" className={styles.authBtn}>
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(null, { setActivePage })(Navbar);
