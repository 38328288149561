import React from "react";
import styles from "./Error.module.css";

const Error = props => {
  const { message, checkbox } = props;
  return (
    <div>
      <p className={checkbox ? styles.checkboxError : styles.error}>
        {message}
      </p>
    </div>
  );
};

export default Error;
