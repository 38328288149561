import React from "react";
import styles from "./ProductGeneralFields.module.css";

import Error from "../../../components/generic/Error";
import Select from "react-select";

const ProductGeneralFields = ({
  categories,
  values,
  errors,
  onChange,
  setFieldValue,
  touched,
}) => {
  return (
    <div className={`${styles.container} column-grid`}>
      <div className="box-third">
        <div className={styles.labelBox}>
          <div className={styles.labelContainer}>
            <p className={styles.subtitle}>Product name</p>
            <span className={styles.subtitleWrapper}>
              <p className={styles.mandatory}>
                Add product name as marketed by the manufacturer.
              </p>
            </span>
          </div>
          <div className={styles.labelContainer}>
            <p className={styles.subtitle}>UPC/EAN</p>
            <span className={styles.subtitleWrapper}>
              <p className={styles.mandatory}>
                Add UPC or link to manufacturer website.
              </p>
            </span>
          </div>
          <div className={styles.labelContainer}>
            <p className={styles.subtitle}>Product Category</p>
            <span className={styles.subtitleWrapper}>
              <p className={styles.mandatory}>Select all that apply.</p>
            </span>
          </div>
        </div>
      </div>

      <div className="box-two-thirds">
        <div className={styles.inputBox}>
          <div className={styles.inputContainer}>
            <span className={styles.placeholder}>Product name</span>
            <input
              name="name"
              type="text"
              value={values.name}
              onChange={onChange}
              placeholder=""
            />
            {errors.name && touched.name && <Error message={errors.name} />}
          </div>
          <div className={styles.inputContainer}>
            <span className={styles.placeholder}>Add UPC/EAN</span>
            <input
              name="upc"
              type="text"
              value={values.upc}
              onChange={onChange}
              placeholder=""
            />
            {errors.upc && touched.upc && <Error message={errors.upc} />}
          </div>
        </div>
        <div className={styles.inputContainer}>
          <Select
            isMulti
            closeMenuOnSelect={false}
            placeholder={"Select category"}
            value={values.categories}
            name="categories"
            options={categories}
            className="products-categories"
            classNamePrefix="products-categories-select"
            autoBlur={false}
            onChange={selectedOption => {
              setFieldValue("categories", selectedOption);
            }}
          />
          {errors.categories && touched.categories && (
            <Error message={errors.categories} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductGeneralFields;
