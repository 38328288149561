import React from "react";
import ProtectedRoute from "./ProtectedRoute";
import { isLoggedIn, isVerified, hasRoleAssigned } from "../auth";

import RegisterForm from "../components/RegisterForm/RegisterForm";
import VerifyAccount from "../components/VerifyAccount";
import LoginForm from "../components/LoginForm";
import ResetPasswordForm from "../components/ResetPasswordForm";
import UpdatePasswordForm from "../components/UpdatePasswordForm";
import ActivateAccount from "../components/ActivateAccount";

const PublicRoutes = () => {
  return (
    <>
      <ProtectedRoute
        exact
        path="/"
        fallback={isLoggedIn() ? "/home" : "/login"}
      />
      <ProtectedRoute
        path="/register"
        canActivate={!isLoggedIn() || !isVerified()}
        fallback="/home"
        component={RegisterForm}
      />
      <ProtectedRoute
        path="/verify-account"
        canActivate={isLoggedIn() && !isVerified()}
        fallback="/home"
        component={VerifyAccount}
      />
      <ProtectedRoute
        path="/activate"
        canActivate={true}
        fallback="/login"
        component={ActivateAccount}
      />
      <ProtectedRoute
        path="/login"
        canActivate={!isLoggedIn() || !isVerified()}
        fallback="/home"
        component={LoginForm}
      />
      <ProtectedRoute
        path="/reset-password/request"
        canActivate={!isLoggedIn()}
        fallback="/home"
        component={ResetPasswordForm}
      />
      <ProtectedRoute
        path="/reset-password/confirm"
        query="token"
        canActivate={true}
        fallback="/home"
        component={UpdatePasswordForm}
      />
    </>
  );
};

export default PublicRoutes;
