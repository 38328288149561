import axios from "axios";

import { getAuthHeader } from "../../auth";
import { TAB_CLICKED, TRIGGER_NOTIFICATION } from "./actionTypes";
import { startLoading, stopLoading } from "./loadingActions";

export const userProfileTabs = {
  accountInfo: "ACCOUNT_INFO_TAB",
  businessInfo: "BUSINESS_INFO_TAB",
  contentCreation: "CONTENT_CREATION_TAB",
  typesOfMedia: "TYPES_OF_MEDIA_TAB",
  paymentSettings: "PAYMENT_SETTINGS_TAB"
};

export const getUserProfile = () => dispatch => {
  axios
    .get("/backend/users/me", getAuthHeader())
    .then(res => {
      dispatch({
        type: "USER_PROFILE_LOADED",
        payload: res.data.userDetails,
      });
    })
    .catch(err => console.log(err));
};

export const updateUserProfile = updatedUser => dispatch => {
  dispatch(startLoading("update_user_profile"));

  axios
    .put("/backend/users/me", updatedUser, getAuthHeader())
    .then(res => {
      dispatch({
        type: "USER_PROFILE_UPDATED",
        payload: JSON.parse(res.config.data),
      });

      dispatch(stopLoading("update_user_profile"));

      dispatch({
        type: TRIGGER_NOTIFICATION,
        payload: {
          message: `Profile successfully updated`,
          type: "SUCCESS",
        },
      });
    })
    .catch(err => {
      console.log(err);
      dispatch(stopLoading("update_user_profile"));
    });
};

export const setSuccessfulPaymentNotification = () => dispatch => {
  dispatch({
    type: TRIGGER_NOTIFICATION,
    payload: {
      message: `Payment successful`,
      type: "SUCCESS",
    },
  });
}

export const setSuccessfulStripeConnect = () => dispatch => {
  dispatch({
    type: TRIGGER_NOTIFICATION,
    payload: {
      message: `Successfully connected to Bleexy via Stripe`,
      type: "SUCCESS",
    },
  });
}

export const getTypesOfMedia = () => dispatch => {
  axios
    .get("/backend/users/me/types_of_media", getAuthHeader())
    .then(res => {
      dispatch({
        type: "TYPES_OF_MEDIA_LOADED",
        payload: res.data.type_of_media
          ? JSON.parse(res.data.type_of_media)
          : {},
      });
    })
    .catch(err => console.log(err));
};

export const updateTypesOfMedia = typesOfMedia => dispatch => {
  axios
    .put("/backend/users/me/types_of_media", typesOfMedia, getAuthHeader())
    .then(res => {
      dispatch({
        type: "TYPES_OF_MEDIA_UPDATED",
        payload: res.data.type_of_media
          ? JSON.parse(res.data.type_of_media)
          : {},
      });
    })
    .catch(err => console.log(err));
};

export const getCategories = () => dispatch => {
  axios
    .get("/backend/users/me/categories", getAuthHeader())
    .then(res => {
      dispatch({
        type: "CATEGORIES_LOADED",
        payload: res.data,
      });
    })
    .catch(err => console.log(err));
};

export const updateCategories = categories => dispatch => {
  dispatch(startLoading("update_content_categories"));
  axios
    .post("/backend/users/me/categories", categories, getAuthHeader())
    .then(res => {
      dispatch({
        type: "CATEGORIES_UPDATED",
        payload: res.data,
      });
      dispatch(stopLoading("update_content_categories"));
      dispatch({
        type: TRIGGER_NOTIFICATION,
        payload: {
          message: `Content categories successfully updated`,
          type: "SUCCESS",
        },
      });
    })
    .catch(err => {
      dispatch(startLoading("update_content_categories"));
      console.log(err);
    });
};

export const searchCategories = payload => dispatch => {
  dispatch({
    type: "CATEGORIES_SEARCHED",
    payload,
  });
};

export const toggleChangePasswordForm = () => dispatch => {
  dispatch({
    type: "CHANGE_PASSWORD_FORM_TOGGLED",
  });
};

export const tabClick = tabName => dispatch => {
  dispatch({
    type: TAB_CLICKED,
    payload: tabName,
  });
};
