import React from "react";
import { useFormik } from "formik";
import Error from "../../../components/generic/Error";
import CheckboxInput from "../../../components/generic/Checkbox";
import styles from "./BusinessInfo.module.css";
import { connect } from "react-redux";
import { updateUserProfile } from "../../../redux/actions/userProfileActions";
import InnerPage from "../layout/InnerPage";
import Save from "../../../components/Save";

const BusinessInfo = ({ loading, user, updateUserProfile }) => {
  const format = user => {
    return {
      businessType: JSON.parse(user.businessType),
      howToUse: JSON.parse(user.howToUse),
    };
  };

  const formik = useFormik({
    initialValues: format(user),
    validate: values => {
      const errors = {};
      const { businessType, howToUse } = values;
      const businessTypeError = Object.keys(businessType).map(key => {
        return businessType[key];
      });
      if (!businessTypeError.includes(true)) {
        errors.businessTypeError = "Please check at least one option";
      }

      const howToUseError = Object.keys(howToUse).map(key => {
        return howToUse[key];
      });

      if (!howToUseError.includes(true)) {
        errors.howToUseError = "Please check at least one option";
      }
      return errors;
    },

    onSubmit: values => {
      const payload = {
        businessType: JSON.stringify(values.businessType),
        howToUse: JSON.stringify(values.howToUse),
      };
      updateUserProfile(payload);
    },
  });

  const { values, handleChange, handleSubmit, errors, touched } = formik;

  return (
    <InnerPage>
      <form className={`column-grid ${styles.form}`} onSubmit={handleSubmit}>
        <div className="box-third">
          <p className={styles.subtitle}>Type of business</p>
          <span className={styles.subtitleWrapper}>
            <p className={styles.mandatory}>
              (Mandatory. Check all that apply!)
            </p>
            {errors.businessTypeError && touched.businessType && (
              <Error checkbox={true} message={errors.businessTypeError} />
            )}
          </span>
          <div className={styles.inputContainer}>
            <CheckboxInput
              id="retail"
              name="businessType.retail"
              label="Retail"
              value={values.businessType.retail}
              checked={values.businessType.retail}
              handleChange={handleChange}
            />

            <CheckboxInput
              id="seller"
              name="businessType.seller"
              value={values.businessType.seller}
              checked={values.businessType.seller}
              label="3rd Paty Seller"
              handleChange={handleChange}
            />
            <CheckboxInput
              id="wholesale"
              name="businessType.wholesale"
              checked={values.businessType.wholesale}
              label="Wholesale"
              handleChange={handleChange}
            />
            <CheckboxInput
              id="manufacturer"
              name="businessType.manufacturer"
              checked={values.businessType.manufacturer}
              label="Manufacturer"
              handleChange={handleChange}
            />
            <CheckboxInput
              id="brand"
              name="businessType.brand"
              checked={values.businessType.brand}
              label="Brand"
              handleChange={handleChange}
            />
            <CheckboxInput
              id="influencer"
              name="businessType.influencer"
              checked={values.businessType.influencer}
              label="Influencer"
              handleChange={handleChange}
            />
            <CheckboxInput
              id="contentCreator"
              name="businessType.contentCreator"
              checked={values.businessType.contentCreator}
              label="Content Creator"
              handleChange={handleChange}
            />
          </div>
        </div>
        <div className="box-third">
          <p className={styles.subtitle}>How would you use Bleexy?</p>
          <span className={styles.subtitleWrapper}>
            <p className={styles.mandatory}>
              (Mandatory. Check all that apply!)
            </p>
            {errors.howToUseError && touched.howToUse && (
              <Error checkbox={true} message={errors.howToUseError} />
            )}
          </span>
          <div className={styles.inputContainer}>
            <CheckboxInput
              id="buyContent"
              name="howToUse.buyContent"
              checked={values.howToUse.buyContent}
              label="Buy curated rich content"
              handleChange={handleChange}
            />
            <CheckboxInput
              id="sellContent"
              name="howToUse.sellContent"
              checked={values.howToUse.sellContent}
              label="Sell high-quality content"
              handleChange={handleChange}
            />
            <CheckboxInput
              id="distributeContent"
              name="howToUse.distributeContent"
              checked={values.howToUse.distributeContent}
              label="Distribute content"
              handleChange={handleChange}
            />
            <CheckboxInput
              id="bleexyConnect"
              name="howToUse.bleexyConnect"
              checked={values.howToUse.bleexyConnect}
              label="Bleexy Connect (cloud based inventory management solution and marketplace integrator"
              handleChange={handleChange}
            />
            <CheckboxInput
              id="curateContent"
              name="howToUse.curateContent"
              checked={values.howToUse.curateContent}
              label="Curate content for Bleexy"
              handleChange={handleChange}
            />
            <CheckboxInput
              id="becomeBKeeper"
              name="howToUse.becomeBKeeper"
              checked={values.howToUse.becomeBKeeper}
              label="Become a Bleexy B-Keeper (Bleexy node)"
              handleChange={handleChange}
            />
            <CheckboxInput
              id="startBGuild"
              name="howToUse.startBGuild"
              checked={values.howToUse.startBGuild}
              label="Start a Bleexy B-Guild (Bleexy group that creates and validates content for brands/product categories)"
              handleChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.save}>
          <Save
            text={"Save Changes"}
            loading={loading}
            active={!(JSON.stringify(format(user)) === JSON.stringify(values))}
          />
        </div>
      </form>
    </InnerPage>
  );
};

const mapStateToProps = ({ userProfile: { user } }) => {
  return { user };
};

export default connect(mapStateToProps, {
  updateUserProfile,
})(BusinessInfo);
