import React from "react";
import styles from "./ProductImageSets.module.css";
import ImageSetPreviews from "../components/ImageSetPreviews";
import ImageSetDetails from "../components/ImageSetDetails";

const ProductImageSets = props => {
  const {
    products,
    selectedProduct,
    imageSets,
    selectedImageSet,
    imageSetImages,
  } = props;
  return (
    <>
      {selectedImageSet ? (
        <div className={styles.container}>
          <ImageSetDetails
            selectedProduct={selectedProduct}
            products={products}
            imageSets={imageSets}
            imageSetImages={imageSetImages}
            selectedImageSet={selectedImageSet}
          />
        </div>
      ) : (
        <div className={`${styles.container} ${styles.imageSetPreviews}`}>
          <ImageSetPreviews
            products={products}
            selectedProduct={selectedProduct}
            imageSets={imageSets}
          />
        </div>
      )}
    </>
  );
};

export default ProductImageSets;
