import React from "react";
import { connect } from "react-redux";
import {
  isLoggedIn,
  isVerified,
  hasRoleAssigned,
  isCreator,
  isConsumer,
  isValidator,
} from "../auth";
import { setActivePage } from "../redux/actions/navbarActions";
import ProtectedRoute from "./ProtectedRoute";

import Dashboard from "../pages/dashboard";
import UserProfile from "../pages/userprofile/index";
import AssetsManagement from "../pages/assetsManagement/index";
import Retailers from "../pages/retailers/index";
import Validators from "../pages/validators/index";
import Products from "../pages/products/index";

const UserRoutes = props => {
  const { setActivePage } = props;

  const defaultPathForAssignedRole = () => {
    let defaultPath;

    if (isCreator()) {
      defaultPath = "/assets";
    } else if (isConsumer()) {
      defaultPath = "/retailers";
    } else if (isValidator()) {
      defaultPath = "/validators";
    } else {
      defaultPath = "/not-found";
    }

    setActivePage(defaultPath);
    return defaultPath;
  };
  return (
    <>
      <ProtectedRoute
        path="/home"
        canActivate={!hasRoleAssigned()}
        fallback={defaultPathForAssignedRole()}
        component={Dashboard}
      />
      <ProtectedRoute
        path="/profile"
        canActivate={isLoggedIn() && isVerified()}
        fallback="/login"
        component={UserProfile}
      />

      <ProtectedRoute
        path="/assets"
        canActivate={
          isLoggedIn() && isVerified() && hasRoleAssigned() && isCreator()
        }
        fallback={isLoggedIn() && isVerified() ? "/not-found" : "/login"}
        component={AssetsManagement}
      />

      <ProtectedRoute
        path="/products"
        canActivate={
          isLoggedIn() && isVerified() && hasRoleAssigned() && isCreator()
        }
        fallback={isLoggedIn() && isVerified() ? "/not-found" : "/login"}
        component={Products}
      />

      <ProtectedRoute
        path="/retailers"
        canActivate={
          isLoggedIn() && isVerified() && hasRoleAssigned() && isConsumer()
        }
        fallback={isLoggedIn() && isVerified() ? "/not-found" : "/login"}
        component={Retailers}
      />

      <ProtectedRoute
        path="/validators"
        canActivate={
          isLoggedIn() && isVerified() && hasRoleAssigned() && isValidator()
        }
        fallback={isLoggedIn() && isVerified() ? "/not-found" : "/login"}
        component={Validators}
      />
    </>
  );
};

export default connect(null, { setActivePage })(UserRoutes);
