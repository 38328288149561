import React from "react";
import styles from "./Input.module.css";
import classNames from "classnames/bind";

let cx = classNames.bind(styles);

const Input = props => {
  const {
    name,
    id,
    value,
    type,
    grey,
    placeholder,
    round,
    isDisabled,
    isSubmitBtn,
    handleChange,
    handleSubmit,
    handleBlur
  } = props;

  let className = cx({
    input: true,
    roundSubmitBtn: round,
    submitBtn: isSubmitBtn,
    loadingBtn: isDisabled,
    greyInput: grey
  });

  return (
    <input
      className={className}
      disabled={isDisabled}
      name={name}
      id={id}
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onBlur={handleBlur}
    />
  );
};
export default Input;
