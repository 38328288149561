import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./redux/reducers/rootReducer";

export default function configureStore(initialState = {}) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const appUrl = process.env.REACT_APP_URL;
  const config = {
    apiUrl, appUrl
  }
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk.withExtraArgument(config)))
  );
}
