import { TAB_CLICKED } from "../actions/actionTypes";

export default (
  state = {
    user: {},
    activeTab: "ACCOUNT_INFO_TAB",
    typeOfMedia: null,
    categories: null,
    searchInput: "",
    isFetching: true,
    isChangePasswordFormVisible: false,
  },
  { payload, type }
) => {
  switch (type) {
    case TAB_CLICKED:
      return {
        ...state,
        activeTab: payload,
      };

    case "USER_PROFILE_LOADED":
      return {
        ...state,
        user: { ...payload },
        isFetching: false,
      };

    case "USER_PROFILE_UPDATED":
      return {
        ...state,
        user: { ...state.user, ...payload },
      };

    case "TYPES_OF_MEDIA_LOADED":
      return {
        ...state,
        typesOfMedia: payload,
      };

    case "TYPES_OF_MEDIA_UPDATED":
      return {
        ...state,
        typesOfMedia: payload,
      };

    case "CATEGORIES_LOADED":
      return {
        ...state,
        categories: payload,
      };

    case "CATEGORIES_UPDATED":
      return {
        ...state,
        categories: payload,
      };

    case "CATEGORIES_SEARCHED":
      const { input } = payload;

      return {
        ...state,
        searchInput: input,
      };
    case "CHANGE_PASSWORD_FORM_TOGGLED":
      return {
        ...state,
        isChangePasswordFormVisible: !state["isChangePasswordFormVisible"],
      };
    default:
      return state;
  }
};
