import React, { useState } from "react";
import styles from "./Search.module.css";
import Icon from "./Icon";
import classNames from "classnames/bind";
import searchIcon from "../static/svg/search.svg";

const cx = classNames.bind(styles);

const Search = (props) => {
  const { search, placeholder } = props;
  const [focus, setFocus] = useState(false);

  const containerClassName = cx({
    focus,
    searchContainer: styles.searchContainer
  });

  const inputClassName = cx({
    focus,
    search: styles.search
  });


  return(
    <div className={containerClassName} onFocus={() => {setFocus('focused')}} onBlur={() => {setFocus(false)}}>
      <Icon icon={searchIcon} />
      <input onChange={search} className={inputClassName} type="text" placeholder={placeholder} />
    </div>
  )
}

export default Search;