import {
  TOGGLE_PRODUCTS,
  TOGGLE_PRODUCT_FORM,
  NEW_PRODUCT_ADDED,
  TOGGLE_EXPANDED_PRODUCT,
  IMAGE_SET_FORM_DISPLAYED,
  IMAGE_SET_FORM_REMOVED,
  NEW_IMAGE_SET_ADDED,
  EDIT_PRODUCT_FORM_DISPLAYED,
  EDIT_PRODUCT_FORM_REMOVED,
  PRODUCT_UPDATED,
  IMAGE_SET_CHECKED,
  PRODUCT_SELECTED,
  IMAGE_SET_SELECTED,
  IMAGE_SETS_DELETED,
  TAB_CLICKED,
  IMAGE_SET_UPDATED,
  IMAGES_REMOVED_FROM_IMAGE_SET,
  IMAGE_SET_IMAGE_SELECTED,
  RETURNED_TO_ALL_SETS_VIEW,
  IMAGE_SET_DELETED,
  UPLOAD_FROM_ASSETS_MODAL_DISPLAYED,
  UPLOAD_FROM_ASSETS_MODAL_REMOVED,
  TOGGLE_OVERLAY_VISIBILITY,
  TOGGLE_ADD_PRODUCT,
  TRIGGER_NOTIFICATION,
  PRODUCT_DELETED,
  TOGGLE_IS_MULTISELECT,
  MULTI_SELECT_PRODUCT,
} from "./actionTypes";
import axios from "axios";
import { getAuthHeader } from "../../auth";
import { startLoading, stopLoading } from "./loadingActions";

export const productTabs = {
  productInfo: "PRODUCT_INFORMATION_TAB",
  productDescription: "PRODUCT_DESCRIPTION_TAB",
  productImageSets: "PRODUCT_IMAGE_SETS_TAB",
};

export const addProductTabs = {
  productInfo: "PRODUCT_INFORMATION_TAB",
};

export const tabClick = tabName => dispatch => {
  dispatch({
    type: TAB_CLICKED,
    payload: tabName,
  });
};

export const toggleProductList = () => dispatch => {
  dispatch({
    type: TOGGLE_PRODUCTS,
  });
};

export const toggleNewProductForm = () => dispatch => {
  dispatch({
    type: TOGGLE_PRODUCT_FORM,
  });
};

export const addNewProduct = product => (dispatch, getState, config) => {
  dispatch(startLoading("add_new_product"));
  axios
    .post(`${config.apiUrl}/products`, product, getAuthHeader())
    .then(res => {
      dispatch({
        type: NEW_PRODUCT_ADDED,
        payload: res.data,
      });
      dispatch(stopLoading("add_new_product"));
      dispatch({
        type: TRIGGER_NOTIFICATION,
        payload: {
          message: `${product.name} successfully created`,
          type: "SUCCESS",
        },
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const updateImageSetAndUncheck = data => async (
  dispatch,
  getState,
  config
) => {
  dispatch(startLoading("update_image_set"));
  const { key, value } = data;

  const { selectedProduct: productId, selectedImageSets } = getState().products;

  try {
    const responses = Object.keys(selectedImageSets).map(async imageSetId => {
      return await axios.put(
        `${config.apiUrl}/products/${productId}/image-sets/${imageSetId}`,
        {
          [key]: value,
        },
        getAuthHeader()
      );
    });

    const response = await Promise.all(responses);

    Object.keys(selectedImageSets).map(imageSetId =>
      dispatch(checkImageSet(imageSetId))
    );

    dispatch({
      type: IMAGE_SET_UPDATED,
      payload: response[0].data,
    });

    dispatch(stopLoading("update_image_set"));
    dispatch({
      type: TRIGGER_NOTIFICATION,
      payload: {
        message: `Image set successfully updated`,
        type: "SUCCESS",
      },
    });
  } catch (err) {
    console.log(err);
    dispatch(stopLoading("update_image_set"));
  }
};

export const updateImageSet = (data, callback) => async (
  dispatch,
  getState,
  config
) => {
  dispatch(startLoading("update_image_set"));

  const { key, value, notificationMessage } = data;

  const {
    selectedProduct: productId,
    selectedImageSet: imageSetId,
  } = getState().products;

  try {
    const response = await axios.put(
      `${config.apiUrl}/products/${productId}/image-sets/${imageSetId}`,
      {
        [key]: value,
      },
      getAuthHeader()
    );

    dispatch({
      type: IMAGE_SET_UPDATED,
      payload: response.data,
    });

    // @TO-DO: refactor this line of code. callback should not allways be called with (false)
    callback && callback(false);
    dispatch(stopLoading("update_image_set"));
    dispatch({
      type: TRIGGER_NOTIFICATION,
      payload: {
        message: notificationMessage,
        type: "SUCCESS",
      },
    });
  } catch (err) {
    console.log(err);
    dispatch(stopLoading("update_image_set"));
  }
};

export const toggleExpandedProduct = productId => dispatch => {
  dispatch({
    type: TOGGLE_EXPANDED_PRODUCT,
    payload: productId,
  });
};

export const displayImageSetForm = productId => dispatch => {
  dispatch({
    type: IMAGE_SET_FORM_DISPLAYED,
    payload: productId,
  });
};

export const removeImageSetForm = () => dispatch => {
  dispatch({
    type: IMAGE_SET_FORM_REMOVED,
  });
};

export const addNewImageSet = data => (dispatch, getState, config) => {
  dispatch(startLoading("add_new_image_set"));

  axios
    .post(
      `${config.apiUrl}/products/${data.productId}/image-sets`,
      {
        name: data.name,
        imageIds: data.selectedImages,
      },
      getAuthHeader()
    )
    .then(res => {
      const { name, id, imageIds } = res.data;
      dispatch({
        type: NEW_IMAGE_SET_ADDED,
        payload: {
          imageSet: {
            name: name,
            id,
            imageIds,
          },
          productId: data.productId,
        },
      });
      dispatch(stopLoading("add_new_image_set"));
      dispatch({
        type: TRIGGER_NOTIFICATION,
        payload: {
          message: `Image set successfully created`,
          type: "SUCCESS",
        },
      });
    })
    .catch(err => {
      // setErrors({ failedSubmit: err.response.data.error.name[0] });
      console.log(err);
    });
};

export const displayEditProductForm = productId => dispatch => {
  dispatch({
    type: EDIT_PRODUCT_FORM_DISPLAYED,
    payload: productId,
  });
};

export const removeEditProductForm = () => dispatch => {
  dispatch({
    type: EDIT_PRODUCT_FORM_REMOVED,
  });
};

export const updateProduct = product => (dispatch, getState, config) => {
  dispatch(startLoading("update_product"));
  axios
    .put(`${config.apiUrl}/products/${product.id}`, product, getAuthHeader())
    .then(res => {
      dispatch({
        type: PRODUCT_UPDATED,
        payload: res.data,
      });
      dispatch(removeEditProductForm());
      dispatch(stopLoading("update_product"));
      dispatch({
        type: TRIGGER_NOTIFICATION,
        payload: {
          message: `${product.name} successfully updated`,
          type: "SUCCESS",
        },
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const checkImageSet = imageSetId => dispatch => {
  dispatch({
    type: IMAGE_SET_CHECKED,
    payload: imageSetId,
  });
};

export const selectProduct = id => dispatch => {
  dispatch({
    type: PRODUCT_SELECTED,
    payload: id,
  });
};

export const deleteProduct = productIdList => (dispatch, getState, config) => {
  dispatch(startLoading("delete_product"));

  productIdList.forEach(id => {
    dispatch({
      type: PRODUCT_DELETED,
      payload: id,
    });

    axios
      .delete(`${config.apiUrl}/products/${id}`, getAuthHeader())
      .then(res => {
        dispatch({
          type: PRODUCT_DELETED,
          payload: id,
        });
        dispatch(stopLoading("delete_product"));
        dispatch({
          type: TRIGGER_NOTIFICATION,
          payload: {
            message: `Product successfully deleted`,
            type: "SUCCESS",
          },
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(stopLoading("delete_product"));
      });
  });
};

export const selectImageSet = (imageSetId, productId) => dispatch => {
  dispatch({
    type: IMAGE_SET_SELECTED,
    payload: { imageSetId, productId },
  });
};

export const deleteImageSets = ids => (dispatch, getState, config) => {
  const { selectedImageSets, selectedProduct } = ids;

  const deletedImageSets = [];

  const toBackendRequests = imageSetId => {
    return axios
      .delete(
        `${config.apiUrl}/products/${selectedProduct}/image-sets/${imageSetId}`,
        getAuthHeader()
      )
      .then(() => {
        deletedImageSets.push(parseInt(imageSetId));
      })
      .catch(err => {
        console.log(err);
      });
  };

  Promise.all(
    Object.keys(selectedImageSets).map(imageSetId => {
      return toBackendRequests(imageSetId);
    })
  ).then(() => {
    dispatch({
      type: IMAGE_SETS_DELETED,
      payload: { selectedProduct, deletedImageSets },
    });
  });
};

export const deleteImageSet = (selectedImageSet, selectedProduct) => (
  dispatch,
  getState,
  config
) => {
  axios
    .delete(
      `${config.apiUrl}/products/${selectedProduct}/image-sets/${selectedImageSet}`,
      getAuthHeader()
    )
    .then(() => {
      dispatch({
        type: IMAGE_SET_DELETED,
        payload: { selectedImageSet, selectedProduct },
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const removeImageFromImageSet = (
  productId,
  imageSetId,
  selectedImages
) => (dispatch, getState, config) => {
  dispatch(startLoading("remove_image_from_image_set"));
  const selectedImageList = Object.keys(selectedImages).filter(
    i => selectedImages[i] === true
  );
  const deletedImages = [];

  const toBackendRequests = imageId => {
    return axios
      .delete(
        `${config.apiUrl}/products/${productId}/image-sets/${imageSetId}/images/${imageId}`,
        {
          ...getAuthHeader(),
        }
      )
      .then(() => {
        deletedImages.push(JSON.parse(imageId));
      })
      .catch(err => console.log(err.message));
  };

  axios
    .get(`${config.apiUrl}/products/${productId}`, {
      ...getAuthHeader(),
    })
    .then(res => {
      dispatch({
        type: PRODUCT_UPDATED,
        payload: res.data,
      });
    });

  Promise.all(
    selectedImageList.map(imageId => {
      return toBackendRequests(imageId);
    })
  ).then(() => {
    dispatch({
      type: IMAGES_REMOVED_FROM_IMAGE_SET,
      payload: deletedImages,
    });
    dispatch(stopLoading("remove_image_from_image_set"));
    dispatch({
      type: TRIGGER_NOTIFICATION,
      payload: {
        message: `${deletedImages.length} image${
          deletedImages.length > 1 ? "s" : ""
        } successfully deleted`,
        type: "SUCCESS",
      },
    });
  });
};

export const selectImageSetImage = imageId => dispatch => {
  dispatch({ type: IMAGE_SET_IMAGE_SELECTED, payload: imageId });
};

export const returnToAllSetsView = () => dispatch => {
  dispatch({
    type: RETURNED_TO_ALL_SETS_VIEW,
  });
};

export const submitForValidation = ids => (dispatch, getState, config) => {
  const { selectedImageSet, selectedProduct } = ids;
  dispatch(startLoading("submit_for_validation"));
  axios
    .put(
      `${config.apiUrl}/products/${selectedProduct}/image-sets/${selectedImageSet}`,
      { state: "submitted" },
      getAuthHeader()
    )
    .then(res => {
      dispatch({
        type: IMAGE_SET_UPDATED,
        payload: res.data,
      });
      dispatch(stopLoading("submit_for_validation"));
      dispatch({
        type: TRIGGER_NOTIFICATION,
        payload: {
          message: `Image set successfully submitted for validation`,
          type: "SUCCESS",
        },
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const togglePersonalUse = payload => (dispatch, getState, config) => {
  const { selectedProduct, selectedImageSet, personalUse } = payload;
  console.log(personalUse);
  dispatch(startLoading("toggle_personal_use"));
  axios
    .put(
      `${config.apiUrl}/products/${selectedProduct}/image-sets/${selectedImageSet}`,
      { personalUse: !personalUse },
      getAuthHeader()
    )
    .then(res => {
      dispatch({
        type: IMAGE_SET_UPDATED,
        payload: res.data,
      });
      dispatch(stopLoading("toggle_personal_use"));
    })
    .catch(err => {
      console.log(err);
    });
};

export const showUploadFromAssetsModal = () => dispatch => {
  dispatch({
    type: UPLOAD_FROM_ASSETS_MODAL_DISPLAYED,
  });
};

export const removeUploadFromAssetsModal = () => dispatch => {
  dispatch({
    type: UPLOAD_FROM_ASSETS_MODAL_REMOVED,
  });
};

export const toggleOverlayVisibility = () => dispatch => {
  dispatch({
    type: TOGGLE_OVERLAY_VISIBILITY,
  });
};

export const toggleAddProductForm = () => dispatch => {
  dispatch({
    type: TOGGLE_ADD_PRODUCT,
  });
};

export const toggleIsMultiSelect = () => dispatch => {
  dispatch({
    type: TOGGLE_IS_MULTISELECT,
  });
};

export const multiSelectProduct = id => dispatch => {
  dispatch({
    type: MULTI_SELECT_PRODUCT,
    payload: id,
  });
};
