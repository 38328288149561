import React from "react";
import styles from "./ImageSetCheckbox.module.css";

const ImageSetCheckbox = props => {
  const {
    name,
    id,
    value,
    label,
    checked,
    handleChange,
    clickDisabled
  } = props;

  return (
    <div className={styles.container}>
      <span className={`${checked ? styles.checked : styles.unchecked}`}>
        <input
          className={`${styles.checkboxInput} ${clickDisabled &&
            styles.clickDisabled}`}
          id={id}
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          onChange={handleChange}
        />
      </span>
      <label
        className={`${styles.checkboxLabel} ${clickDisabled &&
          styles.clickDisabled}`}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};
export default ImageSetCheckbox;
