import React, { useState } from "react";
import styles from "./ImageSetInfo.module.css";

import ImageSetHeader from "./ImageSetHeader";
import Image from "./Image";
import Modal from "../../../components/Modal";
import imagesIcon from "../../../static/svg/imagesSmallPlaceholder.svg";

import { Fade } from "react-slideshow-image";

const ImageSetInfo = ({ imageSet }) => {
  const [sliderProperties, setSliderProperties] = useState({
    transitionDuration: 500,
    autoplay: false,
    defaultIndex: 0,
    infinite: true,
    indicators: false,
    isVisible: false,
  });

  const showModal = (index, length) => {
    const infinite = length > 1 ? true : false;

    setSliderProperties({
      ...sliderProperties,
      defaultIndex: index,
      isVisible: true,
      infinite,
    });
  };

  const hideModal = () => {
    setSliderProperties({
      ...sliderProperties,
      defaultIndex: 0,
      isVisible: false,
    });
  };

  return (
    <div className={styles.container}>
      <ImageSetHeader setName={imageSet.name}>
        <div className={styles.imageCountContainer}>
          <p className={`${styles.imageCount} ${styles.withIcon}`}>
            <img className={styles.icon} src={imagesIcon} alt="" />
          </p>
          <p className={styles.infoTitle}>{imageSet.images.length} images </p>
        </div>
      </ImageSetHeader>
      <div className={styles.imagesWrapper}>
        <div className={styles.images}>
          {imageSet.images.map((image, index) => {
            return (
              <div
                key={image.id}
                onClick={() => showModal(index, imageSet.images.length)}
                className={styles.image}
              >
                <Image
                  mainPhoto={imageSet.coverImageId === image.id}
                  data={image}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        closeIcon
        onClose={() => hideModal()}
        open={sliderProperties.isVisible}
      >
        <div className={styles.slideContainer}>
          <Fade {...sliderProperties}>
            {imageSet.images.map((i, index) => {
              return (
                <div key={i.id} className={styles.eachFade}>
                  <div className={styles.imageContainer}>
                    <img src={i.fullImageUrl} />
                  </div>
                  <div className={styles.indexCountContainer}>
                    <p className={styles.indexCount}>{`${index + 1}/${
                      imageSet.images.length
                    }`}</p>
                  </div>
                </div>
              );
            })}
          </Fade>
        </div>
      </Modal>
    </div>
  );
};

export default ImageSetInfo;
