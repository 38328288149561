import React from "react";

import styles from "./Button.module.css";

import classNames from "classnames/bind";

let cx = classNames.bind(styles);

const Button = props => {
  const {
    round,
    backgroundYellow,
    greyText,
    lightText,
    text,
    icon,
    handleClick,
    type,
    loading,
    disabled,
    yellowText,
    cursorInitial,
  } = props;

  let classNameButton = cx({
    round,
    backgroundYellow,
  });

  let classNameButtonState = cx({
    loading,
    disabled,
    cursorInitial,
  });

  let classNameText = cx({
    lightText,
    greyText,
    yellowText,
  });

  return (
    <button
      onClick={handleClick}
      type={type}
      disabled={disabled || loading}
      className={`${classNameButton} ${classNameButtonState} ${styles.btn}`}
    >
      {icon && <img className={styles.icon} src={props.icon} alt="" />}
      <p className={`${classNameText} ${styles.text}`}>{text}</p>
    </button>
  );
};

export default Button;
