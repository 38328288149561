import React, { useEffect } from "react";
import styles from "./RateContentForm.module.css";
import { useFormik } from "formik";
import { connect } from "react-redux";
import {
  getValidationTask,
  setRating,
  submitRating,
} from "../../../redux/actions/validatorsActions";

import Rating from "./Rating";
import Button from "../../../components/generic/Button";
import Error from "../../../components/generic/Error";

const RateContentForm = ({
  data,
  rating,
  setRating,
  submitRating,
  isSubmitted,
  loading,
  getValidationTask,
}) => {
  const formik = useFormik({
    initialValues: {
      comment: "",
    },

    validate: values => {
      const errors = {};
      if (!values.comment) {
        errors.comment = "This field is required";
      }
      return errors;
    },
    onSubmit: ({ comment }) => {
      submitRating(data.id, rating, comment);
    },
    enableReinitialize: true,
  });

  const {
    values: { comment },
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
  } = formik;
  return (
    <form onSubmit={handleSubmit}>
      <p className={styles.title}>Rate content</p>
      <p className={styles.text}>Your rating</p>
      <div className={styles.rating}>
        <Rating
          rating={rating}
          setRating={setRating}
          readOnly={loading.includes("submit_rating") || isSubmitted}
        />
      </div>
      {isSubmitted ? (
        <div className={styles.commentContainer}>
          <p className={styles.text}>Your comment</p>
          <p className={styles.comment}>{data.comment}</p>
        </div>
      ) : (
        <div className={styles.textareaWrapper}>
          <textarea
            className={styles.textarea}
            value={comment}
            name="comment"
            onChange={handleChange}
            placeholder="Add a comment to explain your rating"
          />
          {errors.comment && touched.comment && (
            <Error message={errors.comment} />
          )}
        </div>
      )}

      <div className={styles.buttons}>
        {isSubmitted ? (
          <Button
            icon={require("../../../static/svg/validated.svg")}
            lightText
            greyText
            cursorInitial
            text="Rating Submitted"
          />
        ) : (
          <Button
            type="input"
            round
            loading={
              loading.includes("get_validation_task") ||
              loading.includes("submit_rating")
            }
            backgroundYellow
            text="Submit rating"
          />
        )}
        {isSubmitted ? (
          <Button
            text="Next Task"
            type="button"
            handleClick={() => {
              getValidationTask();
              resetForm();
            }}
            round
            loading={loading.includes("get_validation_task")}
            backgroundYellow
          />
        ) : (
          <button
            onClick={() => {
              getValidationTask("shouldSkip");
              resetForm();
            }}
            disabled={
              loading.includes("get_validation_task") ||
              loading.includes("submit_rating")
            }
            className={styles.skip}
          >
            Skip this task
            <img
              className={styles.icon}
              src={require("../../../static/svg/arrow-right-orange.svg")}
              alt=""
            />
          </button>
        )}
      </div>
    </form>
  );
};

const mapStateToProps = ({
  loading: { loading },
  validators: { rating, isSubmitted },
}) => ({
  loading,
  rating,
  isSubmitted,
});

export default connect(mapStateToProps, {
  getValidationTask,
  setRating,
  submitRating,
})(RateContentForm);
