import React from "react";
import styles from "./Icon.module.css";

const Icon = props => {
  const { icon, handleClick } = props;

  return (
    <button onClick={handleClick} className={styles.btn}>
      <img src={icon} alt="" />
    </button>
  );
};

export default Icon;
