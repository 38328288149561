import React from "react";
import styles from "./UpdatePasswordForm.module.css";
import axios from "axios";
import Input from "./generic/Input";
import Error from "./generic/Error";
import { useFormik } from "formik";

const UpdatePasswordForm = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");

  const formik = useFormik({
    initialValues: {
      password: "",
      rePassword: "",
      email: params.get("email")
    },

    validate: values => {
      const errors = {};
      if (!values.password) {
        errors.password = "This field is required";
      }
      if (!values.rePassword) {
        errors.rePassword = "This field is required";
      } else if (
        values.password !== values.rePassword &&
        values.password !== ""
      ) {
        errors.equalPasswords = "Passwords do not match";
      }
      return errors;
    },

    onSubmit: (values, { setErrors, setSubmitting }) => {
      axios
        .post(`/backend/password-resets/confirm`, {
          token: token,
          email: values.email,
          password: values.password
        })
        .then(() => {
          document.location = "/login";
        })
        .catch(err => {
          console.log(err);
        });
    }
  });

  if (!token) {
    document.location.assign("/login");
    return "";
  }

  const {
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    errors,
    touched
  } = formik;

  return (
    <div className={`box-wide ${styles.container}`}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formTitle}>
          <h3 className={styles.title}>Update Password</h3>
        </div>
        <div className={styles.inputWrapper}>
          <Input
            name="password"
            id="password"
            type="password"
            value={values.password}
            handleChange={handleChange}
            placeholder="Type your new password"
          />
          {errors.password && touched.password && (
            <Error message={errors.password} />
          )}
        </div>
        <div className={styles.inputWrapper}>
          <Input
            name="rePassword"
            id="rePassword"
            type="password"
            value={values.rePassword}
            handleChange={handleChange}
            placeholder="Re-type your new password"
          />
          {errors.rePassword && touched.rePassword && (
            <Error message={errors.rePassword} />
          )}
          {errors.equalPasswords && touched.rePassword && (
            <Error message={errors.equalPasswords} />
          )}
        </div>
        <br />
        <Input
          isDisabled={isSubmitting}
          isSubmitBtn
          type="submit"
          value="Update password"
        />
      </form>
    </div>
  );
};

export default UpdatePasswordForm;
