import React from "react";
import styles from "./Logo.module.css";
import { Link } from "react-router-dom";
import logo from "../static/images/logo.png";

const Logo = props => {
  return (
    <div className={styles.container}>
      <Link to={props.link}>
        <img className="responsive-image" src={logo} alt="logo" />
      </Link>
    </div>
  );
};

export default Logo;
