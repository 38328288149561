import React, { useEffect, useState } from "react";
import Page from "../../layouts/Page";
import { connect } from "react-redux";

import {
  getUserProfile,
  updateUserProfile,
  getTypesOfMedia,
  getCategories,
  userProfileTabs,
  setSuccessfulPaymentNotification,
  setSuccessfulStripeConnect
} from "../../redux/actions/userProfileActions";

import Spinner from "../../components/generic/Spinner";
import Header from "../../components/Header";
import Tabs from "../../components/Tabs";
import Tab from "../../components/Tab";
import {
  ACCOUNT_INFO_TAB,
  BUSINESS_INFO_TAB,
  CONTENT_CREATION_TAB,
  TYPES_OF_MEDIA_TAB,
  PAYMENT_SETTINGS_TAB
} from "./tabs";
import AccountInfo from "./tabs/AccountInfo";
import BusinessInfo from "./tabs/BusinessInfo";
import TypesOfMedia from "./tabs/TypesOfMedia";
import ContentCategories from "./tabs/ContentCategories";
import Loader from "../../components/generic/Loader";

import {getAuthHeader, isValidator, storeLoginInfo} from "../../auth";
import PaymentSettings from "./tabs/PaymentSettings";
import axios from "axios";

const UserProfilePage = props => {
  const {
    loading,
    isFetching,
    getUserProfile,
    getTypesOfMedia,
    getCategories,
    typesOfMedia,
    categories,
    setSuccessfulPaymentNotification,
    setSuccessfulStripeConnect
  } = props;

  const [activeTab, setActiveTab] = useState("ACCOUNT_INFO_TAB");
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('session_id');
  const code = urlParams.get('code');

  useEffect(() => {
    getUserProfile();
    getTypesOfMedia();
    getCategories();
    if (sessionId) {
      setSuccessfulPaymentNotification();
    }
    if (code) {
      setSuccessfulStripeConnect();

      axios
        .put(process.env.REACT_APP_API_URL + `/users/me`, {stripeCode: code}, getAuthHeader())
        .then(async res => {
          console.log(res);
          // storeLoginInfo();
          let user = JSON.parse(localStorage.getItem("user"));
          user.stripeCode = code;
          localStorage.setItem("user", JSON.stringify(user));
          // document.location = "/profile";
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, []);

  const renderTab = activeTab => {

    switch (activeTab) {
      case ACCOUNT_INFO_TAB:
        return (
          <AccountInfo loading={loading.includes("update_user_profile")} />
        );

      case BUSINESS_INFO_TAB:
        return (
          <BusinessInfo loading={loading.includes("update_user_profile")} />
        );

      case CONTENT_CREATION_TAB:
        return (
          categories &&
          isValidator() && (
            <ContentCategories
              categories={categories}
              loading={loading.includes("update_content_categories")}
            />
          )
        );

      case TYPES_OF_MEDIA_TAB:
        return typesOfMedia && <TypesOfMedia typesOfMedia={typesOfMedia} />;

      case PAYMENT_SETTINGS_TAB:
        return <PaymentSettings />;

      default:
        return <AccountInfo />;
    }
  };

  return (
    <Page>
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          {loading.length > 0 && <Loader />}
          <Header title={"Profile and Settings"}>
            <Tabs activeTab={activeTab}>
              <Tab
                text="Account Information"
                name={userProfileTabs.accountInfo}
                isActive={activeTab === userProfileTabs.accountInfo}
                tabClick={setActiveTab}
              />
              <Tab
                text="Business Information"
                name={userProfileTabs.businessInfo}
                isActive={activeTab === userProfileTabs.businessInfo}
                tabClick={setActiveTab}
              />
              {isValidator() && (
                <Tab
                  text="Content categories"
                  name={userProfileTabs.contentCreation}
                  isActive={activeTab === userProfileTabs.contentCreation}
                  tabClick={setActiveTab}
                />
              )}

              <Tab
                text="Payment settings"
                name={userProfileTabs.paymentSettings}
                isActive={activeTab === userProfileTabs.paymentSettings}
                tabClick={setActiveTab}
              />

            </Tabs>
          </Header>
          {renderTab(activeTab)}
        </>
      )}
    </Page>
  );
};

const mapStateToProps = ({
  userProfile: { isFetching, typesOfMedia, categories },
  loading: { loading },
}) => {
  return {
    isFetching,
    typesOfMedia,
    categories,
    loading,
  };
};

export default connect(mapStateToProps, {
  getUserProfile,
  updateUserProfile,
  getTypesOfMedia,
  getCategories,
  setSuccessfulPaymentNotification,
  setSuccessfulStripeConnect
})(UserProfilePage);
