import React from "react";
import styles from "./IconWithText.module.css";

import classNames from "classnames/bind";

let cx = classNames.bind(styles);

const Icon = props => {
  const {
    handleClick,
    icon,
    highlight,
    text,
    bold,
    regularGrey,
    regular,
    clickDisabled
  } = props;
  let className = cx({
    regular,
    highlight,
    regularGrey,
    bold
  });

  return (
    <button
      onClick={handleClick}
      className={`${styles.container} ${clickDisabled && styles.clickDisabled}`}
    >
      <img className={styles.icon} src={icon} alt="" />
      <p className={`${className} `}>{text}</p>
    </button>
  );
};

export default Icon;
