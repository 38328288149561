import React, { useEffect, useState } from "react";
import styles from "./RightPanel.module.css";
import { connect } from "react-redux";
import { getSelectedImagesOrdered } from "../selectors";
import { deleteImagesClick } from "../redux/actions/assetsManagementActions";
import {
  toggleProductList,
  toggleNewProductForm,
} from "../redux/actions/productsActions";
import IconWithText from "./IconWithText";
import closePanel from "../static/svg/close.svg";
import deleteIcon from "../static/svg/deleteSmall.svg";
import plusIcon from "../static/svg/plus.svg";
import addIcon from "../static/svg/add.svg";
import ImageDescription from "./ImageDescription";
import NewProductForm from "../components/NewProductForm";
import AddImageToSetForm from "../components/AddImageToSetForm";
import Products from "./Products";
import { getCategories } from "../redux/actions/userProfileActions";

const RightPanel = (props) => {
  const [showAllImages, setShowAllImages] = useState(false);
  const {
    selectedImagesOrdered,
    deleteImagesClick,
    handleClosePanel,
    isProductListVisible,
    isProductFormVisible,
    imageSetForm,
    editProductForm,
    toggleProductList,
    toggleNewProductForm,
    products,
    selectedImageSets,
    isGrayedOut,
    getCategories,
    categories,
  } = props;

  useEffect(() => getCategories(), []);

  const formatCategories = (categories) => {
    let result = [];

    categories.map((category) => {
      result.push({
        value: category.id,
        label: category.name,
      });
    });

    return result;
  };

  const nrOfImages = selectedImagesOrdered.length;

  const handleDeleteImages = () => {
    const selectedImageIds = selectedImagesOrdered.map((image) => {
      return image.id;
    });
    if (window.confirm("Are you sure?")) {
      deleteImagesClick(selectedImageIds, "delete_images");
    }
  };

  const handleToggleNewProductForm = () => {
    !imageSetForm && !editProductForm && toggleNewProductForm();
  };

  return (
    <div className={styles.container}>
      <div onClick={handleClosePanel} className={styles.closeIcon}>
        <img src={closePanel} alt="closeButton" />
      </div>
      {!nrOfImages > 0 ? (
        <div className={styles.header}>
          <h3 className={styles.title}>No selection</h3>
          <p className={styles.subtitle}>
            Select Images to delete or add to a set or a product.
          </p>
        </div>
      ) : (
        <div>
          <div className={styles.imagesContainer}>
            {nrOfImages > 1 && (
              <p className={styles.nrOfImages}>{nrOfImages} images selected</p>
            )}
            <div
              className={`${nrOfImages > 1 && styles.imagesInnerContainer} ${
                showAllImages && styles.showAllImages
              }`}
            >
              {selectedImagesOrdered.map((image) => {
                return (
                  <div
                    key={image.id}
                    className={`${
                      nrOfImages === 1 ? styles.imageLarge : styles.imageSmall
                    }`}
                  >
                    <img
                      className={styles.thumbnail}
                      src={image.url}
                      alt={image.id}
                    />
                  </div>
                );
              })}
            </div>
            {nrOfImages > 4 && (
              <div className={styles.seeAllBtnContainer}>
                <button
                  onClick={() => setShowAllImages(!showAllImages)}
                  className={styles.seeAllBtn}
                >
                  {showAllImages ? "See less" : "See all"}
                </button>
              </div>
            )}
          </div>
          <div className={styles.deleteBtnContainer}>
            <IconWithText
              handleClick={handleDeleteImages}
              icon={deleteIcon}
              regular
              text={`${nrOfImages > 1 ? "Delete images" : "Delete image"}`}
            />
          </div>
          {nrOfImages === 1 && (
            <div className={styles.imageDescriptionContainer}>
              <ImageDescription
                description={selectedImagesOrdered[0].description}
              />
            </div>
          )}
          {isProductListVisible ? (
            <h3 className={styles.addImagesToSetTitle}>Add images to set</h3>
          ) : (
            <div className={styles.addImagesToSetBtnContainer}>
              <IconWithText
                handleClick={toggleProductList}
                icon={plusIcon}
                regular
                text={`${
                  nrOfImages > 1 ? "Add images to set" : "Add image to set"
                }`}
              />
            </div>
          )}

          <div className={styles.productsContainer}>
            {isProductListVisible && !isProductFormVisible && (
              <div
                className={`${isGrayedOut && styles.faded} ${
                  styles.addNewProductBtnContainer
                }`}
                onClick={handleToggleNewProductForm}
              >
                <IconWithText
                  clickDisabled={isGrayedOut}
                  icon={addIcon}
                  bold
                  text="New Product"
                />
              </div>
            )}
            {isProductFormVisible && (
              <div className={styles.newProductFormContainer}>
                {categories && (
                  <NewProductForm categories={formatCategories(categories)} />
                )}
              </div>
            )}

            {isProductListVisible && <Products isGrayedOut={isGrayedOut} />}
          </div>
        </div>
      )}
      {isProductListVisible &&
        selectedImagesOrdered.length > 0 &&
        !isGrayedOut && (
          <div className={styles.x}>
            <span className={styles.y} />
            <AddImageToSetForm
              products={products}
              selectedImageSets={selectedImageSets}
              hideProductList={toggleProductList}
            />
          </div>
        )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedImagesOrdered: getSelectedImagesOrdered(state),
    isProductListVisible: state.products.isProductListVisible,
    imageSetForm: state.products.imageSetForm,
    editProductForm: state.products.editProductForm,
    isProductFormVisible: state.products.isProductFormVisible,
    products: state.products.products,
    selectedImageSets: state.products.selectedImageSets,
    isGrayedOut: state.products.isGrayedOut,
    categories: state.userProfile.categories,
  };
};

export default connect(mapStateToProps, {
  deleteImagesClick,
  toggleProductList,
  toggleNewProductForm,
  getCategories,
})(RightPanel);
