import React from "react";
import styles from "./ImageSetHeader.module.css";

const ImageSetHeader = props => {
  const { setName, children } = props;
  return (
    <div className={styles.container}>
      <div>
        <p className={styles.title}>Set Name</p>
        <p className={styles.name}>{setName}</p>
      </div>
      <div className={styles.actions}>{children}</div>
    </div>
  );
};

export default ImageSetHeader;
