import React from "react";
import styles from "./Tab.module.css";
import { connect } from "react-redux";

const Tab = props => {
  const { text, count, name, tabClick, isActive } = props;

  const handleClick = e => {
    tabClick(e.target.name);
  };

  return (
    <button
      className={`${isActive ? styles.isActive : styles.notActive} ${
        styles.tabBtn
      }`}
      onClick={handleClick}
      name={name}
    >
      {text}
      {count && <span className={styles.count}>{count}</span>}
    </button>
  );
};

export default Tab;
