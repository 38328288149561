export default (state = { loading: [] }, { payload, type }) => {
  const getIndex = type => {
    return state.loading.findIndex(e => e === type);
  };

  switch (type) {
    case "START_LOADING":
      return {
        ...state,
        loading: [...state.loading, payload],
      };

    case "STOP_LOADING":
      const index = getIndex(payload);
      const loading = [...state.loading];
      loading.splice(index, 1);

      return {
        ...state,
        loading,
      };

    default:
      return state;
  }
};
