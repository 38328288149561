import React from "react";
import styles from "./Header.module.css";
import Button from "./generic/Button";

const Header = ({
  title,
  children,
  withActionButton,
  handleClick,
  isMultiSelect,
}) => {
  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        {withActionButton && !isMultiSelect && (
          <div className={styles.button}>
            <Button
              round
              icon={require("../static/svg/deleteSmall.svg")}
              text="Delete Product"
              handleClick={handleClick}
            />
          </div>
        )}
      </div>
      <div className={styles.children}>{children}</div>
    </>
  );
};

export default Header;
