import {
  NEW_SNIPPET_ADDED,
  SNIPPET_DELETED,
  SNIPPET_SELECTED,
  SNIPPET_DESELECTED,
  SNIPPET_LIST_FETCHED,
} from "../actions/actionTypes";

import * as R from 'ramda';

export default (
  state = { snippets: {}, selectedSnippet: "" },
  { payload, type }
) => {
  switch (type) {
    case SNIPPET_LIST_FETCHED: {
      return {
        snippets: R.indexBy(R.prop('id'), payload),
        selectedSnippet: "",
      };
    }

    case NEW_SNIPPET_ADDED: {
      const newSnippets = {
        ...state["snippets"],
        [payload.id]: payload
      };

      return {
        ...state,
        snippets: newSnippets,
        selectedSnippet: payload.id
      };
    }

    case SNIPPET_DELETED: {
      const newSnippets = {
        ...state["snippets"]
      };

      delete newSnippets[payload];

      return {
        ...state,
        snippets: newSnippets,
        selectedSnippet: ""
      };
    }

    case SNIPPET_SELECTED: {
      return {
        ...state,
        selectedSnippet: payload
      };
    }

    case SNIPPET_DESELECTED: {
      return {
        ...state,
        selectedSnippet: ""
      };
    }

    default:
      return state;
  }
};
