import React from "react";
import styles from "./NewImageSetForm.module.css";
import { connect } from "react-redux";
import axios from "axios";
import { getAuthHeader } from "../auth";
import {
  removeImageSetForm,
  addNewImageSet,
} from "../redux/actions/productsActions";
import { useFormik } from "formik";
import Input from "./generic/Input";

const NewImageSetForm = props => {
  const {
    productId,
    selectedImages,
    removeImageSetForm,
    addNewImageSet,
  } = props;

  const formik = useFormik({
    initialValues: {
      name: "",
    },

    validate: values => {
      const errors = {};

      if (!values.name) {
        errors.name = "Image set name is required";
      }

      return errors;
    },

    onSubmit: ({ name }) => {
      addNewImageSet({ name, selectedImages, productId });
    },
  });
  const {
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    errors,
    touched,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.inputsContainer}>
        <div className={styles.setContainer}>
          <p className={styles.label}>Create New Set</p>
          <Input
            name="name"
            type="text"
            handleChange={handleChange}
            value={values.name}
            grey
            placeholder="Set name"
          />
          {errors.name && touched.name && (
            <p className={styles.error}>{errors.name}</p>
          )}
          {errors.failedSubmit && (
            <p className={styles.error}>{errors.failedSubmit}</p>
          )}
        </div>
      </div>
      <div>
        <input
          type="submit"
          disabled={isSubmitting}
          className={isSubmitting ? styles.loadingBtn : styles.saveBtn}
          value="Create"
        />
        <button
          onClick={() => removeImageSetForm()}
          className={styles.cancelBtn}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  return {
    selectedImages: state.assetsManagement.selectedImagesOrdered,
  };
};
export default connect(mapStateToProps, {
  removeImageSetForm,
  addNewImageSet,
})(NewImageSetForm);
