import React from "react";
import styles from "./InstagramExportHelp.module.css";
import closeIcon from "../static/svg/close.svg";

const InstagramExportHelp = props => {
  const { hideModal } = props;
  return (
    <React.Fragment>
      <div onClick={() => hideModal()} className={styles.background}></div>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div onClick={() => hideModal()} className={styles.closeIcon}>
            <img src={closeIcon} alt="closeButton" />
          </div>
          <p className={styles.title}>How to get your instagram data</p>
          <ul className={styles.list}>
            <li className={styles.text}>
              1. Go to instagram.com (the website, not the app)
            </li>
            <li className={styles.text}>
              2. Click on the gear icon next to your Edit Profile option and
              select Privacy and Security.
            </li>
            <li className={styles.text}>3. Scroll down to Data Download.</li>
            <li className={styles.text}>4. Click Request Download.</li>
            <li className={styles.text}>
              5. Type in your email address if it doesn't automatically pop up,
              then click Next.
            </li>
            <li className={styles.text}>
              6. Enter your password and click Request Download.
            </li>
          </ul>
          <br />
          <br />
          <p className={styles.text}>
            Instagram will create a file with all the photos, videos and Stories
            you've shared and email you a link to the file so you can download
            it. This process can take up to 48 hours, so don't expect anything
            immediately.
          </p>
          <br />
          <br />
          <p className={styles.text}>
            You will receive an email with a download link.{" "}
            <span className={styles.bold}>
              Download data and upload it to Bleexy Assets Management.
            </span>{" "}
            We will automatically import hashtags and descriptions for every
            image.
          </p>
        </div>
      </div>{" "}
    </React.Fragment>
  );
};

export default InstagramExportHelp;
