import React from "react";
import styles from "./Image.module.css";
import checkedIconSmall from "../../../static/svg/checkedCircleSmall.svg";

const Image = props => {
  const { data, mainPhoto } = props;

  return (
    <div className={styles.container}>
      {mainPhoto && (
        <div className={styles.mainPhoto}>
          <img className={styles.checkedIcon} src={checkedIconSmall} alt="" />
          <p>Main photo</p>
        </div>
      )}
      <img className={styles.image} src={data.url} alt="" />
    </div>
  );
};

export default Image;
