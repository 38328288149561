import React, { useEffect, useState } from "react";
import styles from "./ValidatedList.module.css";
import { connect } from "react-redux";
import { getValidatedTaskList } from "../../../redux/actions/validatorsActions";

import ValidatedListRow from "./ValidatedListRow";

import ValidationItem from "./ValidationItem";
import NoTasks from "../components/NoTasks";
import Spinner from "../../../components/generic/Spinner";

const ValidatedList = ({ validatedTaskList, getValidatedTaskList }) => {
  const [validatedTask, setValidatedTask] = useState({});

  useEffect(() => {
    getValidatedTaskList();
  }, []);

  if (!validatedTaskList) return <Spinner size={"small"}></Spinner>;
  if (!validatedTaskList.length)
    return (
      <NoTasks
        title="No completed tasks"
        subtitle="Please go to Ongoing taks and refresh the page obsesively until a task appears or come back
        later!"
      />
    );

  return (
    <>
      {Object.keys(validatedTask).length ? (
        <ValidationItem
          isCompleted
          data={validatedTask}
          goBack={setValidatedTask}
        />
      ) : (
        <>
          <div className={styles.headRow}>
            <div className={styles.headItem}>Product name</div>
            <div className={styles.headItem}>Product UPC/EAN</div>
            <div className={styles.headItem}>Product category</div>
            <div className={styles.headItem}>Content type</div>
            <div className={styles.headItem}>Content details</div>
            <div className={styles.headItem}>My rating</div>
            <div className={styles.headItem}>Final rating</div>
          </div>
          {validatedTaskList.map(task => (
            <div onClick={() => setValidatedTask(task)}>
              <ValidatedListRow
                key={task.product.upc}
                name={task.product.name}
                upc={task.product.upc}
                categories={task.product.categories}
                type={"Image set"}
                setLength={task.imageSet.imageIds.length}
                rating={task.rating}
                finalRating={task.imageSet.rating}
              />
            </div>
          ))}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    validatedTaskList: state.validators.validatedTaskList,
  };
};

export default connect(mapStateToProps, { getValidatedTaskList })(
  ValidatedList
);
