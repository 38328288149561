import React from "react";
import { connect } from "react-redux";
import { checkImageSet } from "../redux/actions/productsActions";

import Checkbox from "../components/generic/ImageSetCheckbox";

const ImageSets = props => {
  const {
    product,
    imageSets,
    checkImageSet,
    clickDisabled,
    selectedImageSets,
    imageSetForm,
  } = props;

  const handleCheckbox = imageSetId => {
    !imageSetForm && checkImageSet(imageSetId);
  };
  return (
    <React.Fragment>
      {product.imageSets.map(set => {
        return (
          <Checkbox
            name={imageSets[set].name}
            id={imageSets[set].id}
            label={imageSets[set].name}
            checked={selectedImageSets[imageSets[set].id]}
            clickDisabled={clickDisabled}
            handleChange={() => handleCheckbox(imageSets[set].id, product.id)}
          />
        );
      })}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    activeProductList: state.products.activeProductList,
    imageSets: state.products.imageSets,
    selectedImageSets: state.products.selectedImageSets,
    imageSetForm: state.products.imageSetForm,
  };
};

export default connect(mapStateToProps, { checkImageSet })(ImageSets);
