import React from "react";
import styles from "./Upload.module.css";

import { connect } from "react-redux";
import { uploadFilesClick } from "../../../redux/actions/assetsManagementActions";
import {
  showUploadFromAssetsModal,
  toggleOverlayVisibility,
} from "../../../redux/actions/productsActions";

import uploadImages from "../../../static/svg/uploadImagesGrey.svg";

const Upload = (props) => {
  const {
    uploadFilesClick,
    showUploadFromAssetsModal,
    toggleOverlayVisibility,
  } = props;

  const handleUploadFromComputer = async (e) => {
    const files = e.target.files;
    const shouldUpdate = true;
    uploadFilesClick(files, shouldUpdate);
  };

  const clearInput = (e) => {
    e.target.value = null;
  };

  const handleUploadFromAssets = () => {
    showUploadFromAssetsModal();
    toggleOverlayVisibility();
  };

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <label className={styles.content}>
          <div className={styles.icon}>
            <img src={uploadImages} alt="" />
          </div>
          <p>Upload Images from your computer</p>
          <input
            type="file"
            multiple
            onClick={clearInput}
            onChange={handleUploadFromComputer}
          />
        </label>
      </div>
      <div className={styles.box}>
        <div onClick={handleUploadFromAssets} className={styles.content}>
          <div className={styles.icon}>
            <img src={uploadImages} alt="" />
          </div>
          <p>Add images from Assets</p>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  uploadFilesClick,
  showUploadFromAssetsModal,
  toggleOverlayVisibility,
})(Upload);
