import { ACTIVE_PAGE_SET } from "../actions/actionTypes";

export default (
  state = { activePage: window.location.pathname },
  { payload, type }
) => {
  switch (type) {
    case ACTIVE_PAGE_SET:
      return {
        ...state,
        activePage: payload,
      };
    default:
      return state;
  }
};
