import React, { useState } from "react";
import styles from "./ImageSetPreviews.module.css";

import { connect } from "react-redux";
import {
  selectImageSet,
  checkImageSet,
  deleteImageSets,
  addNewImageSet,
} from "../../../redux/actions/productsActions";

import ImageSetState from "./ImageSetState";
import ImageSetForm from "./ImageSetForm";

import selectedIcon from "../../../static/svg/checkedCircleMedium.svg";
import noImagesCover from "../../../static/svg/noImagesCover.svg";

const ImageSetPreviews = props => {
  const {
    imageSets,
    selectImageSet,
    selectedImageSets,
    selectedProduct,
    checkImageSet,
    deleteImageSets,
    imageSetStates,
    addNewImageSet,
  } = props;

  const [isFormVisible, setIsFormVisible] = useState(false);

  const selectedSetsCount = Object.keys(selectedImageSets).map(id => id);

  return (
    <div className={styles.container}>
      <div className={styles.actionsContainer}>
        {selectedSetsCount.length > 0 && (
          <div className={styles.x}>
            <p className={styles.selectedSetsCount}>
              {`${selectedSetsCount.length} set${
                selectedSetsCount.length > 1 ? "s" : ""
              } selected`}
            </p>
            <button
              onClick={() => {
                if (window.confirm("Are you sure?")) {
                  deleteImageSets({ selectedImageSets, selectedProduct });
                }
              }}
              className={styles.deleteSetBtn}
            >
              {`Delete set${selectedSetsCount.length > 1 ? "s" : ""}`}
            </button>
          </div>
        )}
      </div>
      {isFormVisible ? (
        <div className={styles.formContainer}>
          <ImageSetForm
            selectedProduct={selectedProduct}
            addNewImageSet={addNewImageSet}
            setIsFormVisible={setIsFormVisible}
          />
        </div>
      ) : (
        <div className={styles.thumbnailsWrapper}>
          <div className={styles.thumbnailsContainer}>
            <div
              onClick={() => setIsFormVisible(true)}
              className={styles.addImageSetThumbnail}
            >
              <h3>+</h3>
              <h3>Create New Set</h3>
            </div>

            {Object.values(imageSets).map(imageSet => {
              return (
                <div key={imageSet.id} className={styles.box}>
                  <div
                    className={styles.thumbnail}
                    style={{
                      backgroundImage: `url(${
                        imageSet.coverImageUrl
                          ? imageSet.coverImageUrl
                          : noImagesCover
                      })`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center-center",
                    }}
                  >
                    <div
                      onClick={() => checkImageSet(imageSet.id)}
                      className={`${
                        selectedImageSets[imageSet.id] && styles.checked
                      } ${styles.selectImageSetContainer}`}
                    >
                      <img
                        className={`${
                          selectedImageSets[imageSet.id] && styles.checked
                        } ${styles.selectedImageSetIcon}`}
                        src={selectedIcon}
                        alt=""
                      />
                    </div>
                    <button
                      onClick={() =>
                        selectImageSet(imageSet.id, selectedProduct)
                      }
                      className={styles.viewSetBtn}
                    >
                      View Set
                    </button>
                  </div>

                  <h3>{imageSet.name}</h3>
                  <div className={styles.imageSetStateContainer}>
                    <ImageSetState
                      icon={`${imageSet.state}`}
                      state={imageSetStates[imageSet.state]}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedImageSets: state.products.selectedImageSets,
    imageSetStates: state.products.imageSetStates,
  };
};

export default connect(mapStateToProps, {
  selectImageSet,
  checkImageSet,
  deleteImageSets,
  addNewImageSet,
})(ImageSetPreviews);
